import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../config/service";
import { Select } from "../../../components";
import { validateFields } from "../../../components/validation";
import { extractPhoneDetails } from "../../../config";

export default function EditCustomerModal({ show, statusList, onHide, data }) {
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");
  const [customerData, setCustomerData] = useState({
    status: null,
    score: null,
  });
  //   const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const [formData, setFormData] = useState({
    image: null,
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    country_code: "",
    instagram: "",
    linkedin: "",
    designation: "",
    work: "",
    website: "",
    bio: "",
  });
  const fileInputRef = useRef(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 5 * 1024 * 1024) {
      toast.error("File size must be less than 5MB");

      // Reset file input field
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      return;
    }

    if (file) {
      setFormData((prevState) => ({
        ...prevState,
        image: file,
      }));

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        // Call the API after the image preview is set
        toast.loading("Uploading Image...");
        uploadImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async (imageFile) => {
    const isURL = (str) => {
      try {
        new URL(str);
        return true;
      } catch {
        return false;
      }
    };

    const body = {
      ...(imageFile && isURL(imageFile) ? {} : { photo: imageFile || "" }),
    };

    fetchData(`api/accounts/${userId}/`, "put", body, "", true).then((res) => {
      setSending(false);
      toast.remove();
      if (res.success) {
        toast.success("Image Upload Successful");
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleRemoveImage = () => {
    setFormData((prevState) => ({
      ...prevState,
      image: null,
    }));
    setImagePreview(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (data) {
      setUserId(data?.uuid);
      setFormData({
        image: data.photo || null,
        first_name: data.first_name || "",
        last_name: data.last_name || "",
        phone: extractPhoneDetails(data.phone)?.phone || "",
        email: data.email || "",
        country_code: extractPhoneDetails(data.phone)?.countryCode || "",
        instagram: data.user_profile?.instagram_url || "",
        linkedin: data.user_profile?.linkedin_url || "",
        designation: data.user_profile?.designation || "",
        work: data.user_work[0]?.name || "",
        website: data.user_work[0]?.work_url || "",
        bio: data.user_profile?.bio || "",
      });
      setImagePreview(data?.photo);
    }
  }, [data]);

  const validationRules = {
    // image: { required: true, type: "image" },
    first_name: { required: true, maxLength: 50 },
    last_name: { required: true, maxLength: 50 },
    phone: { required: false, maxLength: 20 },
    email: { required: true, type: "email", maxLength: 100 },
    // country_code: { required: true, maxLength: 5 },
    // instagram: { required: true, type: "url", maxLength: 100 },
    // linkedin: { required: true, type: "url", maxLength: 100 },
    // designation: { required: true, maxLength: 100 },
    // work: { required: true, maxLength: 100 },
    // website: { type: "url", maxLength: 100 },
    // bio: { required: true, maxLength: 500 },
  };

  const onSubmitUser = async () => {
    const { isValid } = validateFields(formData, validationRules);

    if (!isValid) {
      return;
    } else {
      toast.loading("Updating...");
    }

    setSending(true);

    const isURL = (str) => {
      try {
        new URL(str);
        return true;
      } catch {
        return false;
      }
    };

    const body = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      phone: formData.country_code + formData.phone,
      // country_code: formData.country_code,
      //   ...(formData.designation
      //     ? {
      //         user_work_details: [
      //           {
      //             designation: formData.designation,
      //           },
      //         ],
      //       }
      //     : {}),
      ...(formData?.instagram || formData?.linkedin || formData?.bio
        ? {
            user_profile: {
              instagram_url: formData?.instagram,
              linkedin_url: formData?.linkedin,
              bio: formData?.bio,
              designation: formData?.designation,
            },
          }
        : {}),
      // ...(formData?.image && isURL(formData?.image)
      //   ? {}
      //   : { photo: formData?.image || "" }),
    };

    fetchData(`api/accounts/${userId}/`, "put", body).then((res) => {
      setSending(false);
      toast.remove();
      if (res.success) {
        onHide();
        toast.success("Success");
      } else {
        toast.error(res.message);
      }
    });
  };

  const setCustomerDataValue = (key, value) => {
    const common_data = {
      ...customerData,
      [key]: value,
    };

    setCustomerData(common_data);
  };

  const scoreOptions = [
    { value: 0, name: "0" },
    { value: 1, name: "1" },
    { value: 2, name: "2" },
    { value: 3, name: "3" },
    { value: 3.5, name: "3.5" },
    { value: 4, name: "4" },
    { value: 4.5, name: "4.5" },
    { value: 5, name: "5" },
  ];

  return (
    <Modal show={show} onHide={onHide} size="lg" centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">
            Edit Customer <span className="text-primary"></span>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row mb-6 align-items-center">
          <label className="col-lg-4 col-form-label fw-semibold fs-6">
            Avatar
          </label>

          <div className="col-lg-8">
            <div
              className="image-input image-input-outline"
              data-kt-image-input="true"
              style={{
                backgroundImage: `url(${imagePreview || "/media/blank.png"})`,
              }}
            >
              <div
                className="image-input-wrapper w-125px h-125px"
                style={{
                  backgroundImage: `url(${imagePreview || "/media/blank.png"})`,
                }}
              />

              <label
                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                data-kt-image-input-action="change"
                data-bs-toggle="tooltip"
                title="Change avatar"
              >
                <i className="ki-outline ki-pencil fs-7" />

                <input
                  type="file"
                  name="avatar"
                  ref={fileInputRef}
                  accept=".png, .jpg, .jpeg"
                  onChange={handleImageChange}
                />
                <input type="hidden" name="avatar_remove" />
              </label>

              <span
                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                data-kt-image-input-action="cancel"
                data-bs-toggle="tooltip"
                title="Cancel avatar"
              >
                <i className="ki-outline ki-cross fs-2" />{" "}
              </span>

              <span
                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                data-kt-image-input-action="remove"
                data-bs-toggle="tooltip"
                title="Remove avatar"
                onClick={handleRemoveImage}
              >
                <i className="ki-outline ki-cross fs-2" />{" "}
              </span>
            </div>

            <div className="form-text">Allowed file types: png, jpg, jpeg.</div>
          </div>
        </div>

        <div className="row mb-6">
          <label className="col-lg-4 col-form-label fw-semibold fs-6">
            First Name
          </label>

          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-6 fv-row">
                <input
                  type="text"
                  className="form-control"
                  placeholder="First name"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-6">
          <label className="col-lg-4 col-form-label fw-semibold fs-6">
            Last Name
          </label>

          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-6 fv-row">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last name"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-6">
          <label className="col-lg-4 col-form-label fw-semibold fs-6">
            Phone
          </label>

          <div className="col-lg-8 fv-row">
            <div className="input-group">
              <input
                style={{
                  width: "30%",
                }}
                type="text"
                className="form-control"
                placeholder="+91"
                name="country_code"
                value={formData.country_code}
                onChange={handleInputChange}
              />
              <input
                style={{
                  width: "70%",
                }}
                type="text"
                className="form-control"
                placeholder="Phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        <div className="row mb-6">
          <label className="col-lg-4 col-form-label fw-semibold fs-6">
            Email
          </label>

          <div className="col-lg-8 fv-row">
            <input
              type="text"
              className="form-control"
              placeholder="Email"
              name="email"
              value={formData.email.toLowerCase()}
              onChange={(e) => {
                const emailValue = e.target.value.toLowerCase();
                setFormData((prevState) => ({
                  ...prevState,
                  email: emailValue,
                }));
              }}
            />
          </div>
        </div>

        {/* <div className="row mb-6">
          <label className="col-lg-4 col-form-label fw-semibold fs-6">
            Country Code
          </label>

          <div className="col-lg-8 fv-row">
            <input
              type="text"
              className="form-control"
              placeholder="Country Code"
              name="country_code"
              value={formData.country_code}
              onChange={handleInputChange}
            />
          </div>
        </div> */}

        <div className="row mb-6">
          <label className="col-lg-4 col-form-label fw-semibold fs-6">
            Instagram URL
          </label>

          <div className="col-lg-8 fv-row">
            <input
              type="url"
              className="form-control"
              placeholder="Instagram URL"
              name="instagram"
              value={formData.instagram}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="row mb-6">
          <label className="col-lg-4 col-form-label fw-semibold fs-6">
            LinkedIn URL
          </label>

          <div className="col-lg-8 fv-row">
            <input
              type="url"
              className="form-control"
              placeholder="LinkedIn URL"
              name="linkedin"
              value={formData.linkedin}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="row mb-6">
          <label className="col-lg-4 col-form-label fw-semibold fs-6">
            Designation
          </label>

          <div className="col-lg-8 fv-row">
            <input
              type="text"
              className="form-control"
              placeholder="Designation"
              name="designation"
              value={formData.designation}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/* <div className="row mb-6">
          <label className="col-lg-4 col-form-label fw-semibold fs-6">
            Name of Company or Project
          </label>

          <div className="col-lg-8 fv-row">
            <input
              type="text"
              className="form-control"
              placeholder="Company or Project Name"
              name="work"
              value={formData.work}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="row mb-6">
          <label className="col-lg-4 col-form-label fw-semibold fs-6">
            Company Website or Portfolio Link
          </label>

          <div className="col-lg-8 fv-row">
            <input
              type="text"
              className="form-control"
              placeholder="Add Link here"
              name="website"
              value={formData.website}
              onChange={handleInputChange}
            />
          </div>
        </div> */}

        <div className="row mb-6">
          <label className="col-lg-4 col-form-label fw-semibold fs-6">
            Share a kickass description about yourself that encompasses you,
            your work &amp; your adventures. Be crisp.
          </label>

          <div className="col-lg-8 fv-row">
            <textarea
              className="form-control"
              cols={30}
              rows={3}
              placeholder={`For Ex. ${
                formData?.first_name + " " + formData?.last_name
              } is an experience designer with a strong desire to bring people closer to their passion`}
              name="bio"
              value={formData.bio}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => {
            onSubmitUser();
          }}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
