import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../config/service";
import { Select } from "antd";

export default function StatusModal({ show, statusList, onHide, data }) {
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");
  const [customerData, setCustomerData] = useState({
    status: null,
    score: null,
  });

  useEffect(() => {
    if (data) {
      console.log(data, "user_data");
      setUserId(data?.uuid);
      setCustomerData({
        status: data?.status,
        score: data?.score,
      });
    }
  }, [data]);

  const onSubmitUser = async () => {
    setSending(true);

    const body = {
      ...(customerData.status !== data?.status
        ? { status: customerData.status }
        : {}),
    };

    fetchData(`api/booking/${userId}/`, "PATCH", body).then((res) => {
      setSending(false);
      if (res.success) {
        onHide();
        toast.success("Success");
      } else {
        toast.error(res.message);
      }
    });
  };

  const setCustomerDataValue = (key, value) => {
    const common_data = {
      ...customerData,
      [key]: value,
    };

    setCustomerData(common_data);
  };

  return (
    <Modal show={show} onHide={onHide} size="sm" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">
            Change Status <span className="text-primary"></span>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row g-9 align-items-center">
          <div className="col-12">
            <label>
              <span className="required fw-semibold fs-5">Select Status</span>
            </label>

            <Select
              options={statusList?.map((role) => {
                return {
                  label: role?.display_name,
                  value: role?.value,
                  disabled: role?.value !== 3 && role?.value !== 5, // Disable all values except 3 and 5
                };
              })}
              value={customerData?.status || ""}
              onChange={(e) => setCustomerDataValue("status", e)}
              placeholder="Select Status"
              dropdownStyle={{ zIndex: 1111 }}
              style={{ width: "100%", marginTop: "10px" }}
              size="large"
              // allowClear
              // onClear={() => setCustomerDataValue("status", null)}
            />

            {/* <select
              className="form-select"
              value={customerData?.status || ""}
              onChange={(e) => setCustomerDataValue("status", e.target.value)}
            >
              {statusList?.map((role, i) => (
                <option key={i} value={role?.value}>
                  {role?.display_name}
                </option>
              ))}
            </select> */}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => {
            onSubmitUser();
          }}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
