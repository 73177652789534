import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { LIMITS } from "../../../../../config";
import { fetchData } from "../../../../../config/service";
import { Loader } from "../../../../../components";

const EditionSuccess = ({
  editionData,
  setCurrentCount,
  setAvgPrice,
  setSecondPaymentsCount,
}) => {
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData(editionData?.id);
  }, [editionData?.id]);

  const getData = (id) => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}&edition_id=${id}`;

    setLoading(true);
    fetchData(`api/editions/statistic/edition-success/${param}`, "get").then(
      (res) => {
        setLoading(false);
        if (res.success) {
          setCurrentCount(res.current_count);
          setAvgPrice(res.average_price);
          setSecondPaymentsCount(res.second_payments);

          const transformedData = Object.entries(res.data).map(
            ([key, value]) => {
              // Convert keys to readable descriptions
              let formattedKey = key
                .replace(/_/g, " ") // Replace underscores with spaces
                .split(" ") // Split into words
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
                .join(" "); // Join words back

              return {
                description: formattedKey,
                count: value,
                countClass: value < 0 ? "text-danger" : "text-success",
                // action: "Move them Out",
              };
            }
          );

          setData(transformedData);
          setTotalRecords(res.count ? res.count - 1 : 0);
        } else {
          setData([]);
          toast.error(res.message);
        }
      }
    );
  };

  const tab1Data = [
    {
      description: "Coming for Sure",
      count: 0,
      countClass: "text-success",
      action: "Move them Out",
    },
    {
      description: "Coming / Will Pay",
      count: 0,
      countClass: "text-success",
      action: "Move them Out",
    },
    {
      description: "Coming / Needs Time",
      count: 0,
      countClass: "text-success",
      action: "Move them Out",
    },
    {
      description: "Payment Extension Requested",
      count: 0,
      countClass: "text-success",
      action: "Move them Out",
    },
    {
      description: "Newly Moved from Previous Editions",
      count: 0,
      countClass: "text-success",
      action: "Move them Out",
    },
    {
      description: "Paid / No Response",
      count: 0,
      countClass: "text-warning",
      action: "Move them Out",
    },
    {
      description: "Not Sure",
      count: 0,
      countClass: "text-warning",
      action: "Move them Out",
    },
    {
      description: "No Response",
      count: 0,
      countClass: "text-warning",
      action: "Move them Out",
    },
    {
      description: "Move to other Edition",
      count: 1,
      countClass: "text-gray-800",
      action: "Move them Out",
    },
    {
      description: "Not Coming",
      count: 0,
      countClass: "text-gray-800",
      action: "Move them Out",
    },
  ];

  return (
    <div>
      <table className="table align-middle table-row-dashed table-row-gray-300 gy-4 gs-4">
        <tbody>
          {loading ? (
            <Loader cols={2} />
          ) : (
            <>
              {data?.length > 0 ? (
                <>
                  {data?.length > 0 &&
                    data.map((row, index) => (
                      <tr key={index}>
                        <td>{row.description}</td>
                        <td className="min-w-100px">
                          <span className={`fw-bolder ${row.countClass}`}>
                            {row.count}
                          </span>
                        </td>
                        {/* <td>{`Possible Action: ${row.action}`}</td> */}
                      </tr>
                    ))}
                </>
              ) : (
                <div className="row g-5 mb-0 gx-md-10">
                  <div className="col-md-12 text-center">
                    <div className="mt-0">
                      <img
                        src="/media/no-data-found.png"
                        className="mw-25"
                        alt="No Data Found"
                      />
                    </div>
                    <h1 className="mt-4">No Data Found</h1>
                  </div>
                </div>
              )}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default EditionSuccess;
