import React, { useEffect, useState } from "react";
import { fetchData } from "../../../config/service";
import toast from "react-hot-toast";
import { DatePicker, Select } from "antd";
import { LIMITS } from "../../../config";
import { Loader, Pagination } from "../../../components";
import { act } from "react-dom/test-utils";
import DebouncedSelect from "../../../components/debounced-select";

const getColor = (key) => {
  if (key.includes("approved") || key.includes("paid")) return "success";
  if (key.includes("change") || key.includes("reschedule")) return "danger";
  return "warning"; // Default color
};

const formatKeyToTitle = (key) => {
  return key
    .replace(/_/g, " ") // Replace underscores with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
};

export default function BusinessMetrics() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState({
    openEditionsData_status: null,
    editionDropOffData_status: null,
    secondPaymentsData_status: null,
    editionWiseMoveOutsData_status: null,
    edition: null,
    start_date: null,
    end_date: null,
  });
  const [editionsList, setEditionsList] = useState([]);

  // State object to handle open/close state for each collapsible
  const [collapseState, setCollapseState] = useState({
    openEditions: false,
    editionDropOffs: false,
    secondPayments: false,
    editionWiseMoveOuts: false,
    revenueBreakdown: false,
    onboardingCalls: false,
    curationScores: false,
    fieldOfWork: false,
    age: false,
    monthlySecondPayments: false,
  });
  const [activePage, setActivePage] = useState({
    openEditionsData: 1,
    editionDropOffData: 1,
    curationScoresData: 1,
    fieldOfWorkData: 1,
    ageMetricsData: 1,
    secondPaymentsData: 1,
    monthlySecondPaymentsData: 1,
    editionMoveOutData: 1,
  });
  // const [limit, setLimit] = useState(LIMITS[0]);
  const [limit, setLimit] = useState({
    openEditionsData: LIMITS[0],
    editionDropOffData: LIMITS[0],
    curationScoresData: LIMITS[0],
    fieldOfWorkData: LIMITS[0],
    ageMetricsData: LIMITS[0],
    secondPaymentsData: LIMITS[0],
    monthlySecondPaymentsData: LIMITS[0],
    editionMoveOutData: LIMITS[0],
  });

  const [totalRecords, setTotalRecords] = useState({
    openEditionsData: 0,
    editionDropOffData: 0,
    curationScoresData: 0,
    fieldOfWorkData: 0,
    ageMetricsData: 0,
    secondPaymentsData: 0,
    monthlySecondPaymentsData: 0,
    editionMoveOutData: 0,
  });

  const [openEditionsLoading, setOpenEditionsLoading] = useState(false);
  const [openEditionsData, setOpenEditionsData] = useState([]);

  const [editionDropOffLoading, setEditionDropOffLoading] = useState(false);
  const [editionDropOffData, setEditionDropOffData] = useState([]);

  const [curationScoresLoading, setCurationScoresLoading] = useState(false);
  const [curationScoresData, setCurationScoresData] = useState([]);

  const [fieldOfWorkLoading, setFieldOfWorkLoading] = useState(false);
  const [fieldOfWorkData, setFieldOfWorkData] = useState([]);

  const [ageMetricsLoading, setAgeMetricsLoading] = useState(false);
  const [ageMetricsData, setAgeMetricsData] = useState([]);

  const [secondPaymentsLoading, setSecondPaymentsLoading] = useState(false);
  const [secondPaymentsData, setSecondPaymentsData] = useState([]);

  const [monthlySecondPaymentsLoading, setMonthlySecondPaymentsLoading] =
    useState(false);
  const [monthlySecondPaymentsData, setMonthlySecondPaymentsData] = useState(
    []
  );

  const [editionMoveOutLoading, setEditionMoveOutLoading] = useState(false);
  const [editionMoveOutData, setEditionMoveOutData] = useState([]);

  const [editionsStatusOptions, setEditionsStatusOptions] = useState([]);

  const toggleCollapse = (key) => {
    setCollapseState((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  useEffect(() => {
    // getEditions();
    getEditionsStatus();
  }, []);

  useEffect(() => {
    getData();
  }, [filterData?.edition, filterData?.start_date, filterData?.end_date]);

  useEffect(() => {
    getOpenEditionsData();
  }, [
    limit?.openEditionsData,
    activePage?.openEditionsData,
    filterData?.openEditionsData_status,
  ]);

  useEffect(() => {
    getEditionsDropOffData();
  }, [
    limit?.editionDropOffData,
    activePage?.editionDropOffData,
    filterData?.editionDropOffData_status,
  ]);

  useEffect(() => {
    getSecondPaymentData();
  }, [
    limit?.secondPaymentsData,
    activePage?.secondPaymentsData,
    filterData?.secondPaymentsData_status,
  ]);

  useEffect(() => {
    getCurationScores();
  }, [
    limit?.curationScoresData,
    activePage?.curationScoresData,
    totalRecords?.curationScoresData,
  ]);

  useEffect(() => {
    getFieldOfWorkData();
  }, [
    limit?.fieldOfWorkData,
    activePage?.fieldOfWorkData,
    totalRecords?.fieldOfWorkData,
  ]);

  useEffect(() => {
    getAgeMetrics();
  }, [
    limit?.ageMetricsData,
    activePage?.ageMetricsData,
    totalRecords?.ageMetricsData,
  ]);

  useEffect(() => {
    getMonthlySecondPaymentData();
  }, [
    limit?.monthlySecondPaymentsData,
    activePage?.monthlySecondPaymentsData,
    totalRecords?.monthlySecondPaymentsData,
  ]);

  useEffect(() => {
    getEditionMoveOutData();
  }, [
    limit?.editionMoveOutData,
    activePage?.editionMoveOutData,
    totalRecords?.editionMoveOutData,
    filterData?.editionWiseMoveOutsData_status,
  ]);

  const getEditionsStatus = () => {
    fetchData(`api/editions/editions-choices/`).then((res) => {
      if (res.success) {
        const data = res["edition_status_choices"]?.map((e) => {
          return {
            label: e?.display_name,
            value: e?.value,
          };
        });
        setEditionsStatusOptions(data);
      } else {
        setEditionsStatusOptions([]);
        toast.error(res.message);
      }
    });
  };

  const getEditions = () => {
    fetchData(`api/editions/editions-select/`, "get").then((res) => {
      if (res.success) {
        const data = res.data.map((e) => {
          return { label: e.ip, value: e.id };
        });
        setEditionsList(data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getData = () => {
    setLoading(true);

    const editionId = filterData?.edition;

    fetchData(
      `api/business-metrics/bookings/?edition_id=${
        editionId || ""
      }&start_date=${filterData?.start_date || ""}&end_date=${
        filterData?.end_date || ""
      }
      `,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getOpenEditionsData = () => {
    setOpenEditionsLoading(true);
    fetchData(
      `api/business-metrics/editions/?limit=${limit?.openEditionsData}&offset=${
        (activePage.openEditionsData - 1) * limit?.openEditionsData
      }&status=${filterData?.openEditionsData_status || ""}`,
      "get"
    ).then((res) => {
      setOpenEditionsLoading(false);
      if (res.success) {
        setTotalRecords((prev) => {
          return { ...prev, openEditionsData: res.count };
        });
        setOpenEditionsData(res.data);
      } else {
        setOpenEditionsData([]);
        toast.error(res.message);
      }
    });
  };

  const getEditionsDropOffData = () => {
    setEditionDropOffLoading(true);
    fetchData(
      `api/business-metrics/editions-drop-offs/?limit=${
        limit?.editionDropOffData
      }&offset=${
        (activePage.editionDropOffData - 1) * limit?.editionDropOffData
      }&status=${filterData?.editionDropOffData_status || ""}`,
      "get"
    ).then((res) => {
      setEditionDropOffLoading(false);
      if (res.success) {
        setTotalRecords((prev) => {
          return { ...prev, editionDropOffData: res.count };
        });
        setEditionDropOffData(res.data);
      } else {
        setEditionDropOffData([]);
        toast.error(res.message);
      }
    });
  };

  const getSecondPaymentData = () => {
    setSecondPaymentsLoading(true);
    fetchData(
      `api/business-metrics/payments-metrics/?limit=${
        limit?.secondPaymentsData
      }&offset=${
        (activePage.secondPaymentsData - 1) * limit?.secondPaymentsData
      }&status=${filterData?.secondPaymentsData_status || ""}`,
      "get"
    ).then((res) => {
      setSecondPaymentsLoading(false);
      if (res.success) {
        setTotalRecords((prev) => {
          return { ...prev, secondPaymentsData: res.count };
        });
        setSecondPaymentsData(res.data);
      } else {
        setSecondPaymentsData([]);
        toast.error(res.message);
      }
    });
  };

  const getCurationScores = () => {
    setCurationScoresLoading(true);
    fetchData(
      `api/business-metrics/curation-score/?limit=${
        limit?.curationScoresData
      }&offset=${
        (activePage.curationScoresData - 1) * limit?.curationScoresData
      }`,
      "get"
    ).then((res) => {
      setCurationScoresLoading(false);
      if (res.success) {
        const filteredData = Object.keys(res)
          .filter((key) => key !== "success")
          .reduce((obj, key) => {
            obj[key] = res[key];
            return obj;
          }, {});

        const data = Object.values(filteredData).map((item) => item);

        setTotalRecords((prev) => {
          return { ...prev, curationScoresData: filteredData.length };
        });
        setCurationScoresData(data);
      } else {
        setCurationScoresData([]);
        toast.error(res.message);
      }
    });
  };

  const getFieldOfWorkData = () => {
    setFieldOfWorkLoading(true);
    fetchData(
      `api/business-metrics/work-metrics/?limit=${
        limit?.fieldOfWorkData
      }&offset=${(activePage.fieldOfWorkData - 1) * limit?.fieldOfWorkData}`,
      "get"
    ).then((res) => {
      setFieldOfWorkLoading(false);
      if (res.success) {
        setTotalRecords((prev) => {
          return { ...prev, fieldOfWorkData: res.count };
        });
        setFieldOfWorkData(res.data);
      } else {
        setFieldOfWorkData([]);
        toast.error(res.message);
      }
    });
  };

  const getAgeMetrics = () => {
    setAgeMetricsLoading(true);
    fetchData(
      `api/business-metrics/age-metrics/?limit=${
        limit?.ageMetricsData
      }&offset=${(activePage.ageMetricsData - 1) * limit?.ageMetricsData}`,
      "get"
    ).then((res) => {
      setAgeMetricsLoading(false);
      if (res.success) {
        const data = Object.values(res).map((item) => item);
        setTotalRecords((prev) => {
          return { ...prev, ageMetricsData: data.length };
        });
        setAgeMetricsData(data);
      } else {
        setAgeMetricsData([]);
        toast.error(res.message);
      }
    });
  };

  const getMonthlySecondPaymentData = () => {
    setMonthlySecondPaymentsLoading(true);
    fetchData(
      `api/business-metrics/month-wise-payments-metrics/?limit=${
        limit?.monthlySecondPaymentsData
      }&offset=${
        (activePage.monthlySecondPaymentsData - 1) *
        limit?.monthlySecondPaymentsData
      }&year=${2024}`,
      "get"
    ).then((res) => {
      setMonthlySecondPaymentsLoading(false);
      if (res.success) {
        const filteredData = Object.keys(res)
          .filter((key) => key !== "success")
          .reduce((obj, key) => {
            obj[key] = res[key];
            return obj;
          }, {});

        const data = Object.values(filteredData).map((item) => item);

        setTotalRecords((prev) => {
          return { ...prev, monthlySecondPaymentsData: data.length };
        });
        setMonthlySecondPaymentsData(data);
      } else {
        setMonthlySecondPaymentsData([]);
        toast.error(res.message);
      }
    });
  };

  const getEditionMoveOutData = () => {
    setEditionMoveOutLoading(true);
    fetchData(
      `api/business-metrics/editions-moved-outs/?limit=${
        limit?.editionMoveOutData
      }&offset=${
        (activePage.editionMoveOutData - 1) * limit?.editionMoveOutData
      }&status=${filterData?.editionWiseMoveOutsData_status || ""}`,
      "get"
    ).then((res) => {
      setEditionMoveOutLoading(false);
      if (res.success) {
        setEditionMoveOutData(res.data);
        setTotalRecords((prev) => {
          return { ...prev, editionMoveOutData: res.count };
        });
      } else {
        setEditionMoveOutData([]);
        toast.error(res.message);
      }
    });
  };

  const setFilterDataValue = (key, value) => {
    const common_data = {
      ...filterData,
      [key]: value,
    };
    setFilterData(common_data);
  };

  return (
    <div>
      <div className="row mb-7">
        <div className="col-md-12">
          <div className="d-flex flex-wrap gap-4">
            {/* <div class="position-relative ">
                                          <i
                                              class="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5"></i>
                                          <input type="text" data-kt-table-widget-4="search"
                                              class="form-control text-dark w-250px fs-7 ps-12 all-search"
                                              name="all_search" placeholder="Search Hotel here...">
                                      </div> */}
            <div className="position-relative ">
              <DebouncedSelect
                apiEndpoint="api/editions/editions-select/"
                value={filterData?.edition}
                onChange={(value) => setFilterDataValue("edition", value)}
                placeholder="Select Edition"
                labelFormatter={(item) => item.ip}
                size="large"
                style={{ width: "200px" }}
                allowClear
                onClear={() => {
                  setFilterDataValue("edition", null);
                }}
              />

              {/* <Select
                value={filterData?.edition}
                onChange={(value) => setFilterDataValue("edition", value)}
                placeholder="Select Edition"
                style={{ width: 200 }}
                options={editionsList}
                allowClear
                size="large"
              /> */}
            </div>
            <div className="position-relative ">
              <DatePicker.RangePicker
                value={filterData?.joining_date}
                format="DD-MM-YYYY"
                onChange={(date, dateString) => {
                  const utcDateString1 =
                    date && date[0] ? date[0].format("YYYY-MM-DD") : null;

                  const utcDateString2 =
                    date && date[1] ? date[1].format("YYYY-MM-DD") : null;

                  setFilterData((prevData) => ({
                    ...prevData,
                    start_date: utcDateString1,
                    end_date: utcDateString2,
                  }));
                }}
                style={{ width: "100%" }}
                size="large"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Business Content */}
      <div className="row g-6 mb-6 g-xl-9 mb-xl-9">
        <div
          className="col-md-12 col-xxl-12"
          // style={loading ? { filter: "blur(5px)", pointerEvents: "none" } : {}}
        >
          <div className="card">
            <div className="card-header border-0">
              <h3 className="card-title fw-bold">Business Metric</h3>
            </div>
            <div className="card-body  pt-0">
              <div className="row w-100 min-h-300px">
                {loading ? (
                  <div className="d-flex justify-content-center align-items-center w-100 h-300px position-absolute top-0 start-0 z-index-999">
                    <div className="spinner-border text-primary" />
                  </div>
                ) : (
                  <>
                    {Object.entries(data?.["booking_metrics"] || {}).map(
                      ([key, value]) => (
                        <div className="col-md-4" key={key}>
                          <div
                            className={`d-flex align-items-center bg-light-${getColor(
                              key
                            )} rounded p-5 mb-5`}
                          >
                            <i
                              className={`ki-outline ki-ocean text-${getColor(
                                key
                              )} fs-1 me-5`}
                            />
                            <div className="flex-grow-1 me-2">
                              <a
                                href="#"
                                className="fw-bold text-gray-800 text-hover-primary fs-6"
                              >
                                {formatKeyToTitle(key)}
                              </a>
                            </div>
                            <span
                              className={`fw-bold text-${getColor(
                                key
                              )} py-0 fs-5`}
                            >
                              {value || 0}
                            </span>
                          </div>
                        </div>
                      )
                    )}
                  </>
                )}
              </div>
            </div>
            <div
              id="kt_docs_card_collapsible_analytics"
              className="collapse show"
            >
              <div
                className="card-footer p-5"
                style={{
                  backgroundColor: "#f7f7f7",
                  border: "5px solid #fff",
                  borderTop: "none",
                  filter: loading ? "blur(5px)" : "none",
                }}
              >
                <div className="row g-5 gap-0 card-analytics flex-wrap">
                  {Object.entries(data?.["call_metrics"] || {}).map(
                    ([key, value]) => (
                      <div className="col-12 col-md" key={key}>
                        <div className="p-3 main-card">
                          <h3>{value || 0}</h3>
                          <p className="mb-0">{formatKeyToTitle(key)}</p>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edition Metric */}
      <div
        className="row g-6 mb-6 g-xl-9 mb-xl-9"
        // style={
        //   loading
        //     ? {
        //         filter: "blur(5px)",
        //         pointerEvents: "none",
        //       }
        //     : {}
        // }
      >
        {/*begin::Followers*/}
        {/*begin::Col*/}
        <div className="col-md-12 col-xxl-12">
          <div className="card ">
            <div className="card-header cursor-pointer rotate border-0">
              <h3 className="card-title fw-bold">Edition Metric</h3>
            </div>
            <div className="card-body pt-0">
              <div>
                <div className="row w-100 min-h-300px">
                  {loading ? (
                    <div className="d-flex justify-content-center align-items-center w-100 h-300px position-absolute top-0 start-0 z-index-999">
                      <div className="spinner-border text-primary" />
                    </div>
                  ) : (
                    <>
                      {Object.keys(data?.["editions_metrics"] || {}) ? (
                        <>
                          {" "}
                          {Object.entries(data?.["editions_metrics"] || {}).map(
                            ([key, value]) => (
                              <div className="col-sm-6 col-lg-4 mb-9" key={key}>
                                <div className="card border-0 bg-light-primary bg-hover-light-warning transition-all duration-300 cursor-pointer">
                                  <div className="card-body p-3">
                                    <div className="d-flex align-items-center">
                                      {/* Icon based on metric type */}
                                      <div className="symbol symbol-45px me-3">
                                        <span className="symbol-label bg-white shadow-sm rounded-circle">
                                          <i className="ki-duotone ki-abstract-26 fs-1 text-primary">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                          </i>
                                        </span>
                                      </div>

                                      <div className="flex-grow-1">
                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                          <span className="text-dark fw-bolder fs-4">
                                            {Number(value).toFixed(2) || 0}
                                          </span>
                                          <span className="badge badge-light-primary fs-8 fw-bold">
                                            {key.includes("rate") ? "%" : ""}
                                          </span>
                                        </div>
                                        <span className="text-gray-600 fw-semibold d-block fs-7">
                                          {formatKeyToTitle(key)}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Subtle bottom border indicator */}
                                  <div className="card-footer p-0">
                                    <div className="h-2px bg-primary opacity-50 rounded-bottom"></div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          <h1>No data found</h1>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*end::Col*/}
      </div>

      {/* Open Editions Card */}
      <div className="card mb-7">
        <div
          className="card-header cursor-pointer"
          onClick={() => toggleCollapse("openEditions")}
        >
          <div className="card-title flex-column fs-8">
            <h2 className="mb-1 fs-6 fs-md-3">Open Editions</h2>
          </div>
          <div className=" ms-0 ms-sm-auto d-flex align-items-center gap-4">
            {/* <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexCheckDefault1"
              />
              <label
                className="form-check-label fs-6 text-gray-800 fw-bold"
                htmlFor="flexCheckDefault1"
              >
                Show All Time
              </label>
            </div> */}
            <div >
              <Select
                showSearch
                name="status"
                value={filterData?.openEditionsData_status}
                onClick={(e) => e.stopPropagation()}
                onChange={(value) => {
                  setFilterData((prevState) => ({
                    ...prevState,
                    openEditionsData_status: value,
                  }));
                }}
                placeholder="Select Status"
                className="fs-8 me-2 w-150px"
                dropdownStyle={{ zIndex: 1100 }}
                size="large"
                options={editionsStatusOptions}
                allowClear
                onClear={() =>
                  setFilterData((prevState) => ({
                    ...prevState,
                    openEditionsData_status: null,
                  }))
                }
              ></Select>
            </div>
          </div>
          <div
            className="card-toolbar collapsible cursor-pointer rotate"
            // onClick={() => toggleCollapse("openEditions")}
          >
            <div className={collapseState.openEditions ? "rotate-180" : ""}>
              <i className="ki-duotone ki-down fs-1" />
            </div>
          </div>
        </div>
        <div
          id="kt_docs_card_collapsible"
          className={`collapse ${collapseState.openEditions ? "show" : ""}`}
        >
          <div className="card-body py-2 pb-7">
            {/*begin::Table container*/}
            {openEditionsLoading ? (
              <div className="table-responsive">
                <Loader cols={7} width={250} />
              </div>
            ) : (
              <div>
                {openEditionsData?.length > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                        <thead>
                          <tr className="fw-bolder text-gray-900 bg-light-primary">
                            {/* Table Headers */}
                            <th className="min-w-80px">#</th>
                            <th className="min-w-150px">Name of IP</th>
                            <th className="min-w-80px">Status</th>
                            <th className="min-w-100px">Total Apps</th>
                            <th className="min-w-100px">Total Sold</th>
                            <th className="min-w-125px">Funnel Quality</th>
                            <th className="min-w-90px">Conv%</th>
                            <th className="min-w-125px">
                              Non Curated Bookings
                            </th>
                            <th className="min-w-100px">
                              Active Invites Bookings
                            </th>
                            <th className="min-w-100px">
                              Dead Invites Bookings
                            </th>
                            <th className="min-w-100px">Drop-offs</th>
                          </tr>
                        </thead>

                        <tbody>
                          {openEditionsData?.length > 0 &&
                            openEditionsData.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td className="bdr-left-user text-center">
                                    {(activePage?.openEditionsData - 1) *
                                      limit?.openEditionsData +
                                      1 +
                                      i}
                                  </td>
                                  <td className="fw-semibold fs-6">
                                    {item.ip || "-"}
                                  </td>
                                  <td>{item.status_label || "-"}</td>
                                  <td className="fw-semibold fs-6">
                                    {item?.edition_metrics["total_apps"] || "-"}
                                  </td>

                                  <td className="fw-semibold fs-6">
                                    {item?.edition_metrics["total_sold"] || "-"}
                                  </td>

                                  <td>
                                    {" "}
                                    {item?.edition_metrics["funnel_quality"] ||
                                      "-"}
                                  </td>
                                  <td>
                                    {" "}
                                    {item?.edition_metrics["conversion"] || "-"}
                                  </td>

                                  <td>
                                    {" "}
                                    {item?.edition_metrics[
                                      "non_curated_bookings"
                                    ] || "-"}
                                  </td>
                                  <td>
                                    {" "}
                                    {item?.edition_metrics[
                                      "active_invites_bookings"
                                    ] || "-"}
                                  </td>
                                  <td>
                                    {" "}
                                    {item?.edition_metrics[
                                      "dead_invites_booking"
                                    ] || "-"}
                                  </td>
                                  <td>
                                    {" "}
                                    {item?.edition_metrics["drop_offs"] || "-"}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>

                    <div className="separator separator-dashed border-gray-200" />
                    <Pagination
                      setLimit={(e) => {
                        setLimit((prev) => ({
                          ...prev,
                          openEditionsData: e,
                        }));
                      }}
                      limit={limit?.openEditionsData}
                      data={openEditionsData}
                      activePage={activePage?.openEditionsData}
                      totalRecords={totalRecords?.openEditionsData}
                      onChange={(pageNumber) => {
                        setOpenEditionsLoading(true);
                        setActivePage((prev) => ({
                          ...prev,
                          openEditionsData: pageNumber,
                        }));
                      }}
                    />
                  </>
                ) : (
                  <div className="row g-5 mb-0 gx-md-10">
                    <div className="col-md-12 text-center">
                      <div className="mt-0">
                        <img
                          src="/media/no-data-found.png"
                          className="mw-25"
                          alt="No Data Found"
                        />
                      </div>
                      <h1 className="mt-4">No Data Found</h1>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/*end::Pagination*/}
          </div>
        </div>
      </div>

      {/* Edition Drop Offs Card */}
      <div className="card mb-7">
        <div
          className="card-header cursor-pointer"
          onClick={() => toggleCollapse("editionDropOffs")}
        >
          <div className="card-title flex-column ">
            <h2 className="mb-1 fs-6 fs-md-3">Edition Drop Offs</h2>
          </div>
          <div className=" ms-0 ms-sm-auto d-flex align-items-center  gap-4">
            {/* <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexCheckDefault2"
              />
              <label
                className="form-check-label fs-6 text-gray-800 fw-bold"
                htmlFor="flexCheckDefault2"
              >
                Show All Time
              </label>
            </div> */}
            <div >
              <div >
                <Select
                  showSearch
                  name="status"
                  value={filterData?.editionDropOffData_status}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(value) => {
                    setFilterData((prevState) => ({
                      ...prevState,
                      editionDropOffData_status: value,
                    }));
                  }}
                  placeholder="Select Status"
                  className="fs-8 me-2 w-150px"
                  dropdownStyle={{ zIndex: 1100 }}
                  size="large"
                  options={editionsStatusOptions}
                  allowClear
                  onClear={() =>
                    setFilterData((prevState) => ({
                      ...prevState,
                      editionDropOffData_status: null,
                    }))
                  }
                ></Select>
              </div>
            </div>
          </div>
          <div
            className="card-toolbar collapsible cursor-pointer rotate"
            // onClick={() => toggleCollapse("editionDropOffs")}
          >
            <div className={collapseState.editionDropOffs ? "rotate-180" : ""}>
              <i className="ki-duotone ki-down fs-1" />
            </div>
          </div>
        </div>
        <div
          id="kt_docs_card_collapsible"
          className={`collapse ${collapseState.editionDropOffs ? "show" : ""}`}
        >
          <div className="card-body py-2 pb-7">
            {/*begin::Table container*/}
            {editionDropOffLoading ? (
              <div className="table-responsive">
                <Loader cols={7} width={250} />
              </div>
            ) : (
              <div>
                {editionDropOffData?.length > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                        <thead>
                          <tr className="fw-bolder text-gray-900 bg-light-primary">
                            {/* Table Headers */}
                            <th className="min-w-80px">#</th>
                            <th className="min-w-125px">Name of IP</th>
                            <th className="min-w-80px">Name Of IP</th>
                            <th className="min-w-75px">Attended</th>
                            <th className="min-w-50px">Total Sold</th>
                            <th className="min-w-50px">Move To Future</th>
                            <th className="min-w-75px">Avg. Dropoff</th>
                            <th className="min-w-90px">Drop Percentage</th>
                          </tr>
                        </thead>

                        <tbody>
                          {editionDropOffData?.length > 0 &&
                            editionDropOffData.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td className="bdr-left-user text-center">
                                    {(activePage?.editionDropOffData - 1) *
                                      limit?.editionDropOffData +
                                      1 +
                                      i}
                                  </td>
                                  <td className="fw-semibold fs-6">
                                    {item.ip || "-"}
                                  </td>
                                  <td>{item.ip || "-"}</td>
                                  <td className="fw-semibold fs-6">
                                    {item?.edition_drop_off_metrics[
                                      "total_attended"
                                    ] || "0"}
                                  </td>

                                  <td className="fw-semibold fs-6">
                                    {item?.edition_drop_off_metrics[
                                      "total_sold"
                                    ] || "0"}
                                  </td>

                                  <td>
                                    {" "}
                                    {item?.edition_drop_off_metrics[
                                      "move_to_future"
                                    ] || "0"}
                                  </td>
                                  <td>
                                    {" "}
                                    {item?.edition_drop_off_metrics[
                                      "drop_percentage"
                                    ] || "0"}
                                  </td>

                                  <td>
                                    {" "}
                                    {item?.edition_drop_off_metrics[
                                      "avg_drop_offs"
                                    ] || "0"}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>

                    <div className="separator separator-dashed border-gray-200" />
                    <Pagination
                      setLimit={(e) => {
                        setLimit((prev) => ({
                          ...prev,
                          editionDropOffData: e,
                        }));
                      }}
                      limit={limit?.editionDropOffData}
                      data={editionDropOffData}
                      activePage={activePage?.editionDropOffData}
                      totalRecords={totalRecords?.editionDropOffData}
                      onChange={(pageNumber) => {
                        setEditionDropOffLoading(true);
                        setActivePage((prev) => ({
                          ...prev,
                          editionDropOffData: pageNumber,
                        }));
                      }}
                    />
                  </>
                ) : (
                  <div className="row g-5 mb-0 gx-md-10">
                    <div className="col-md-12 text-center">
                      <div className="mt-0">
                        <img
                          src="/media/no-data-found.png"
                          className="mw-25"
                          alt="No Data Found"
                        />
                      </div>
                      <h1 className="mt-4">No Data Found</h1>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/*end::Pagination*/}
          </div>
        </div>
      </div>

      {/* Second Payments */}
      <div className="card mb-7">
        <div
          className="card-header cursor-pointer"
          onClick={() => toggleCollapse("secondPayments")}
        >
          <div className="card-title flex-column ">
            <h2 className="mb-1 fs-6 fs-md-3">Second Payments</h2>
          </div>
          <div className="ms-auto d-flex align-items-center me-5 gap-4">
            {/* <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label
                class="form-check-label fs-6 text-gray-800 fw-bold"
                for="flexCheckDefault"
              >
                Show All Time
              </label>
            </div> */}
            <div className="ms-4">
              {/* <Select
                showSearch
                name="status"
                value={filterData?.secondPaymentsData_status}
                onClick={(e) => e.stopPropagation()}
                onChange={(value) => {
                  setFilterData((prevState) => ({
                    ...prevState,
                    secondPaymentsData_status: value,
                  }));
                }}
                placeholder="Select Status"
                className="fs-8 me-3 w-150px"
                dropdownStyle={{ zIndex: 1100 }}
                size="large"
                options={editionsStatusOptions}
                allowClear
                onClear={() =>
                  setFilterData((prevState) => ({
                    ...prevState,
                    secondPaymentsData_status: null,
                  }))
                }
              ></Select> */}
            </div>
          </div>
          <div
            className="card-toolbar collapsible cursor-pointer rotate"
            // onClick={() => toggleCollapse("secondPayments")}
          >
            <div className={collapseState.secondPayments ? "rotate-180" : ""}>
              <i className="ki-duotone ki-down fs-1" />
            </div>
          </div>
        </div>
        <div
          id="kt_docs_card_collapsible1"
          className={`collapse ${collapseState.secondPayments ? "show" : ""}`}
        >
          <div className="card-body py-2 pb-7">
            {/*begin::Table container*/}
            {secondPaymentsLoading ? (
              <div className="table-responsive">
                <Loader cols={7} width={250} />
              </div>
            ) : (
              <div>
                {secondPaymentsData?.length > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                        <thead>
                          <tr className="fw-bolder text-gray-900 bg-light-primary">
                            <th className="min-w-80px">#</th>
                            <th className="min-w-125px">Name of IP</th>
                            <th className="min-w-80px">Pending</th>
                            <th className="min-w-75px">Full Payments</th>
                            <th className="min-w-50px">Overdue</th>
                            <th className="min-w-75px">Due 0-30 Days</th>
                            <th className="min-w-75px">Due 30+ Days</th>
                          </tr>
                        </thead>

                        <tbody>
                          {secondPaymentsData?.length > 0 &&
                            secondPaymentsData.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td className="bdr-left-user text-center">
                                    {(activePage?.secondPaymentsData - 1) *
                                      limit?.secondPaymentsData +
                                      1 +
                                      i}
                                  </td>

                                  <td className="fw-semibold fs-6">
                                    {item?.["edition_ip"] || "-"}
                                  </td>

                                  <td className="fw-semibold fs-6">
                                    {item?.["pending"] || "0"}
                                  </td>

                                  <td> {item?.["full_payment"] || "0"}</td>
                                  <td> {item?.["overdue"] || "0"}</td>

                                  <td> {item?.["overdue"] || "0"}</td>
                                  <td> {item?.["overdue"] || "0"}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>

                    <div className="separator separator-dashed border-gray-200" />
                    <Pagination
                      setLimit={(e) => {
                        setLimit((prev) => ({
                          ...prev,
                          secondPaymentsData: e,
                        }));
                      }}
                      limit={limit?.secondPaymentsData}
                      data={secondPaymentsData}
                      activePage={activePage?.secondPaymentsData}
                      totalRecords={totalRecords?.secondPaymentsData}
                      onChange={(pageNumber) => {
                        setSecondPaymentsLoading(true);
                        setActivePage((prev) => ({
                          ...prev,
                          secondPaymentsData: pageNumber,
                        }));
                      }}
                    />
                  </>
                ) : (
                  <div className="row g-5 mb-0 gx-md-10">
                    <div className="col-md-12 text-center">
                      <div className="mt-0">
                        <img
                          src="/media/no-data-found.png"
                          className="mw-25"
                          alt="No Data Found"
                        />
                      </div>
                      <h1 className="mt-4">No Data Found</h1>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/*end::Pagination*/}
          </div>
        </div>
      </div>

      {/* Edition Wise Move Outs */}
      <div className="card mb-7">
        <div
          className="card-header cursor-pointer"
          onClick={() => toggleCollapse("editionWiseMoveOuts")}
        >
          <div className="card-title flex-column ">
            <h2 className="mb-1 fs-6 fs-md-3">Edition Wise Move Outs</h2>
          </div>
          <div className="ms-auto d-flex align-items-center me-5 gap-4">
            {/* <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label
                class="form-check-label fs-6 text-gray-800 fw-bold"
                for="flexCheckDefault"
              >
                Show All Time
              </label>
            </div> */}
            <div className="ms-4">
              {/* <Select
                showSearch
                name="status"
                value={filterData?.editionWiseMoveOutsData_status}
                onClick={(e) => e.stopPropagation()}
                onChange={(value) => {
                  setFilterData((prevState) => ({
                    ...prevState,
                    editionWiseMoveOutsData_status: value,
                  }));
                }}
                placeholder="Select Status"
                className="fs-8 me-3 w-150px"
                dropdownStyle={{ zIndex: 1100 }}
                size="large"
                options={editionsStatusOptions}
                allowClear
                onClear={() =>
                  setFilterData((prevState) => ({
                    ...prevState,
                    editionWiseMoveOutsData_status: null,
                  }))
                }
              ></Select> */}
            </div>
          </div>
          <div
            className="card-toolbar collapsible cursor-pointer rotate"
            // onClick={() => toggleCollapse("editionWiseMoveOuts")}
          >
            <div
              className={collapseState.editionWiseMoveOuts ? "rotate-180" : ""}
            >
              <i className="ki-duotone ki-down fs-1" />
            </div>
          </div>
        </div>
        <div
          id="kt_docs_card_collapsible2"
          className={`collapse ${
            collapseState.editionWiseMoveOuts ? "show" : ""
          }`}
        >
          <div className="card-body py-2 pb-7">
            {/*begin::Table container*/}
            {editionMoveOutLoading ? (
              <div className="table-responsive">
                <Loader cols={7} width={250} />
              </div>
            ) : (
              <div>
                {editionMoveOutData?.length > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                        <thead>
                          <tr className="fw-bolder text-gray-900 bg-light-primary">
                            <th class="min-w-75px">Sr. No.</th>
                            <th className="min-w-125px">Name of Edition</th>
                            <th className="min-w-125px">Name of IP</th>
                            <th className="min-w-100px">
                              Paid - Move outs vs Move Ins
                            </th>
                            <th className="min-w-100px">
                              Unpaid - Move outs vs Move Ins
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {editionMoveOutData?.length > 0 &&
                            editionMoveOutData.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td className="bdr-left-user text-center">
                                    {(activePage?.editionMoveOutData - 1) *
                                      limit?.editionMoveOutData +
                                      1 +
                                      i}
                                  </td>

                                  <td className="fw-semibold fs-6">
                                    {item?.name || "-"}
                                  </td>

                                  <td className="fw-semibold fs-6">
                                    {item?.ip || "0"}
                                  </td>

                                  <td>
                                    {" "}
                                    {item?.["moves_in_out_metrics"][
                                      "full_paid_move_in"
                                    ] || "0"}
                                    -
                                    {item?.["moves_in_out_metrics"][
                                      "full_paid_move_out"
                                    ] || "0"}
                                  </td>

                                  <td>
                                    {" "}
                                    {item?.["moves_in_out_metrics"][
                                      "move_in"
                                    ] || "0"}
                                    -
                                    {item?.["moves_in_out_metrics"][
                                      "move_out"
                                    ] || "0"}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>

                    <div className="separator separator-dashed border-gray-200" />
                    <Pagination
                      setLimit={(e) => {
                        setLimit((prev) => ({
                          ...prev,
                          editionMoveOutData: e,
                        }));
                      }}
                      limit={limit?.editionMoveOutData}
                      data={editionMoveOutData}
                      activePage={activePage?.editionMoveOutData}
                      totalRecords={totalRecords?.editionMoveOutData}
                      onChange={(pageNumber) => {
                        setEditionMoveOutLoading(true);
                        setActivePage((prev) => ({
                          ...prev,
                          editionMoveOutData: pageNumber,
                        }));
                      }}
                    />
                  </>
                ) : (
                  <div className="row g-5 mb-0 gx-md-10">
                    <div className="col-md-12 text-center">
                      <div className="mt-0">
                        <img
                          src="/media/no-data-found.png"
                          className="mw-25"
                          alt="No Data Found"
                        />
                      </div>
                      <h1 className="mt-4">No Data Found</h1>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/*end::Pagination*/}
          </div>
        </div>
      </div>

      {/* Revenue Breakdown of Applicants */}
      {/* <div className="card mb-7">
        <div
          className="card-header cursor-pointer"
          onClick={() => toggleCollapse("revenueBreakdown")}
        >
          <div className="card-title flex-column ">
            <h2 className="mb-1">Revenue Breakdown of Applicants</h2>
          </div>
          <div className="ms-auto d-flex align-items-center me-5 gap-4">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label
                class="form-check-label fs-6 text-gray-800 fw-bold"
                for="flexCheckDefault"
              >
                Show All Time
              </label>
            </div>
            <div className="ms-4">
              <select name id className="form-select fs-7 min-w-150px">
                <option value>Open</option>
                <option value>Finished</option>
                <option value>Cancelled</option>
                <option value>Closed</option>
                <option value>Rescheduled</option>
                <option value>Future</option>
              </select>
            </div>
          </div>
          <div
            className="card-toolbar collapsible cursor-pointer rotate collapsed"
            onClick={() => toggleCollapse("revenueBreakdown")}
          >
            <div className={collapseState.revenueBreakdown ? "rotate-180" : ""}>
              <i className="ki-duotone ki-down fs-1" />
            </div>
          </div>
        </div>
        <div
          id="kt_docs_card_collapsible3"
          className={`collapse ${collapseState.revenueBreakdown ? "show" : ""}`}
        >
          <div className="card-body">
            <div className="table-responsive tbl-sales">
              <table
                className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 mb-0"
                id
              >
                <thead>
                  <tr className="fw-bolder text-gray-900 bg-light-primary">
                    <th className="min-w-125px">Name of Edition</th>
                    <th className="min-w-100px">Total</th>
                    <th className="min-w-100px">Business Owners</th>
                    <th className="min-w-100px">Salaried</th>
                    <th className="min-w-100px">Call Done Business</th>
                    <th className="min-w-100px">Call Done Salary</th>
                    <th className="min-w-100px">Score Median (Paid)</th>
                  </tr>
                </thead>
                <tbody className=" fw-semibold fs-7">
                  <tr className>
                    <td>
                      <span className="fw-bolder">0 to 5L</span>
                    </td>
                    <td>
                      <div className>
                        <div>
                          Paid: <b>69</b>
                        </div>
                        <div>
                          Approved: <b>2455</b>
                        </div>
                        <div>
                          Applied: <b>5280</b>
                        </div>
                      </div>
                    </td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>4</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> */}

      {/* Onboarding Calls vs Future Edition */}
      {/* <div className="card mb-7">
        <div
          className="card-header cursor-pointer"
          onClick={() => toggleCollapse("onboardingCalls")}
        >
          <div className="card-title flex-column ">
            <h2 className="mb-1">Onboarding Calls vs Future Edition</h2>
          </div>
          <div className="ms-auto d-flex align-items-center me-5 gap-4">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label
                class="form-check-label fs-6 text-gray-800 fw-bold"
                for="flexCheckDefault"
              >
                Show All Time
              </label>
            </div>
            <div className="ms-4">
              <select name id className="form-select fs-7 min-w-150px">
                <option value>Open</option>
                <option value>Finished</option>
                <option value>Cancelled</option>
                <option value>Closed</option>
                <option value>Rescheduled</option>
                <option value>Future</option>
              </select>
            </div>
          </div>
          <div
            className="card-toolbar collapsible cursor-pointer rotate collapsed"
            onClick={() => toggleCollapse("onboardingCalls")}
          >
            <div className={collapseState.onboardingCalls ? "rotate-180" : ""}>
              <i className="ki-duotone ki-down fs-1" />
            </div>
          </div>
        </div>
        <div
          id="kt_docs_card_collapsible4"
          className={`collapse ${collapseState.onboardingCalls ? "show" : ""}`}
        >
          <div className="card-body">
            <div className="table-responsive tbl-sales">
              <table
                className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 mb-0"
                id
              >
                <thead>
                  <tr className="fw-bolder text-gray-900 bg-light-primary">
                    <th class="min-w-75px">Sr. No.</th>
                    <th className="min-w-125px">Name of Edition</th>
                    <th className="min-w-100px">Call Done On</th>
                    <th className="min-w-100px">Onboarding Questions</th>
                  </tr>
                </thead>
                <tbody className=" fw-semibold fs-7">
                  <tr className>
                    <td>
                      <a
                        href
                        className="text-gray-800 text-hover-primary mb-0 fw-bolder fs-6 "
                      >
                        Shalini
                      </a>
                    </td>
                    <td>May 5, 2022</td>
                    <td>Coming / Not Coming - ...</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> */}

      {/* Curation Scores */}
      <div className="card mb-7">
        <div
          className="card-header cursor-pointer"
          onClick={() => toggleCollapse("curationScores")}
        >
          <div className="card-title flex-column ">
            <h2 className="mb-1 fs-6 fs-md-3">Curation Scores</h2>
          </div>
          {/* <div className="ms-auto d-flex align-items-center me-5 gap-4">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label
                class="form-check-label fs-6 text-gray-800 fw-bold"
                for="flexCheckDefault"
              >
                Show All Time
              </label>
            </div>
            <div className="ms-4">
              <select name id className="form-select fs-7 min-w-150px">
                <option value>Open</option>
                <option value>Finished</option>
                <option value>Cancelled</option>
                <option value>Closed</option>
                <option value>Rescheduled</option>
                <option value>Future</option>
              </select>
            </div>
          </div> */}
          <div
            className="card-toolbar collapsible cursor-pointer rotate collapsed"
            // onClick={() => toggleCollapse("curationScores")}
          >
            <div className={collapseState.curationScores ? "rotate-180" : ""}>
              <i className="ki-duotone ki-down fs-1" />
            </div>
          </div>
        </div>
        <div
          id="kt_docs_card_collapsible5"
          className={`collapse ${collapseState.curationScores ? "show" : ""}`}
        >
          <div className="card-body py-2 pb-7">
            {/*begin::Table container*/}
            {curationScoresLoading ? (
              <div className="table-responsive">
                <Loader cols={7} width={250} />
              </div>
            ) : (
              <div>
                {curationScoresData?.length > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                        <thead>
                          <tr className="fw-bolder text-gray-900 bg-light-primary">
                            <th className="min-w-80px">#</th>
                            <th className="min-w-100px">Scores</th>
                            <th className="min-w-100px">Approved</th>
                            <th className="min-w-100px">Paid</th>
                            <th className="min-w-100px">Sales via Calls</th>
                            <th className="min-w-100px">Total Users</th>
                          </tr>
                        </thead>

                        <tbody>
                          {curationScoresData?.length > 0 &&
                            curationScoresData.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td className="bdr-left-user text-center">
                                    {(activePage?.curationScoresData - 1) *
                                      limit?.curationScoresData +
                                      1 +
                                      i}
                                  </td>

                                  <td className="fw-semibold fs-6">
                                    {item?.["score_range"] || "0"}
                                  </td>

                                  <td className="fw-semibold fs-6">
                                    {item?.["approved_count"] || "0"}
                                  </td>

                                  <td> {item?.["paid_count"] || "0"}</td>
                                  <td> {item?.["sales_via_calls"] || "0"}</td>

                                  <td> {item?.["total_users"] || "0"}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>

                    <div className="separator separator-dashed border-gray-200" />
                    <Pagination
                      setLimit={(e) => {
                        setLimit((prev) => ({
                          ...prev,
                          curationScoresData: e,
                        }));
                      }}
                      limit={limit?.curationScoresData}
                      data={curationScoresData}
                      activePage={activePage?.curationScoresData}
                      totalRecords={totalRecords?.curationScoresData}
                      onChange={(pageNumber) => {
                        setCurationScoresLoading(true);
                        setActivePage((prev) => ({
                          ...prev,
                          curationScoresData: pageNumber,
                        }));
                      }}
                    />
                  </>
                ) : (
                  <div className="row g-5 mb-0 gx-md-10">
                    <div className="col-md-12 text-center">
                      <div className="mt-0">
                        <img
                          src="/media/no-data-found.png"
                          className="mw-25"
                          alt="No Data Found"
                        />
                      </div>
                      <h1 className="mt-4">No Data Found</h1>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/*end::Pagination*/}
          </div>
        </div>
      </div>

      {/* Field of Work */}
      <div className="card mb-7">
        <div
          className="card-header cursor-pointer"
          onClick={() => toggleCollapse("fieldOfWork")}
        >
          <div className="card-title flex-column ">
            <h2 className="mb-1 fs-6 fs-md-3">Field of Work</h2>
          </div>
          {/* <div className="ms-auto d-flex align-items-center me-5 gap-4">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label
                class="form-check-label fs-6 text-gray-800 fw-bold"
                for="flexCheckDefault"
              >
                Show All Time
              </label>
            </div>
            <div className="ms-4">
              <select name id className="form-select fs-7 min-w-150px">
                <option value>Open</option>
                <option value>Finished</option>
                <option value>Cancelled</option>
                <option value>Closed</option>
                <option value>Rescheduled</option>
                <option value>Future</option>
              </select>
            </div>
          </div> */}
          <div
            className="card-toolbar collapsible cursor-pointer rotate collapsed"
            // onClick={() => toggleCollapse("fieldOfWork")}
          >
            <div className={collapseState.fieldOfWork ? "rotate-180" : ""}>
              <i className="ki-duotone ki-down fs-1" />
            </div>
          </div>
        </div>
        <div
          id="kt_docs_card_collapsible6"
          className={`collapse ${collapseState.fieldOfWork ? "show" : ""}`}
        >
          <div className="card-body py-2 pb-7">
            {/*begin::Table container*/}
            {fieldOfWorkLoading ? (
              <div className="table-responsive">
                <Loader cols={7} width={250} />
              </div>
            ) : (
              <div>
                {fieldOfWorkData?.length > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                        <thead>
                          <tr className="fw-bolder text-gray-900 bg-light-primary">
                            <th class="min-w-75px">Sr. No.</th>
                            <th className="min-w-100px">Field of Work</th>
                            <th className="min-w-100px">Scores</th>
                            <th className="min-w-100px">Applied</th>
                            <th className="min-w-100px">Approved</th>
                            <th className="min-w-100px">Paid</th>
                          </tr>
                        </thead>

                        <tbody>
                          {fieldOfWorkData?.length > 0 &&
                            fieldOfWorkData.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td className="bdr-left-user text-center">
                                    {(activePage.fieldOfWorkData - 1) *
                                      limit?.fieldOfWorkData +
                                      1 +
                                      i}
                                  </td>

                                  <td className="fw-semibold fs-6">
                                    {item?.["work_field"]}
                                  </td>

                                  <td className="fw-semibold fs-6">
                                    {item?.["avg_score"] || "0"}
                                  </td>

                                  <td> {item?.["applied"] || "0"}</td>
                                  <td> {item?.["approved"] || "0"}</td>

                                  <td> {item?.["paid"] || "0"}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>

                    <div className="separator separator-dashed border-gray-200" />
                    <Pagination
                      setLimit={(e) => {
                        setLimit((prev) => ({
                          ...prev,
                          fieldOfWorkData: e,
                        }));
                      }}
                      limit={limit?.fieldOfWorkData}
                      data={fieldOfWorkData}
                      activePage={activePage?.fieldOfWorkData}
                      totalRecords={totalRecords?.fieldOfWorkData}
                      onChange={(pageNumber) => {
                        setFieldOfWorkLoading(true);
                        setActivePage((prev) => ({
                          ...prev,
                          fieldOfWorkData: pageNumber,
                        }));
                      }}
                    />
                  </>
                ) : (
                  <div className="row g-5 mb-0 gx-md-10">
                    <div className="col-md-12 text-center">
                      <div className="mt-0">
                        <img
                          src="/media/no-data-found.png"
                          className="mw-25"
                          alt="No Data Found"
                        />
                      </div>
                      <h1 className="mt-4">No Data Found</h1>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/*end::Pagination*/}
          </div>
        </div>
      </div>

      {/*Age */}
      <div className="card mb-7">
        <div
          className="card-header cursor-pointer"
          onClick={() => toggleCollapse("age")}
        >
          <div className="card-title flex-column ">
            <h2 className="mb-1 fs-6 fs-md-3">Age</h2>
          </div>
          {/* <div className="ms-auto d-flex align-items-center me-5 gap-4">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label
                class="form-check-label fs-6 text-gray-800 fw-bold"
                for="flexCheckDefault"
              >
                Show All Time
              </label>
            </div>
            <div className="ms-4">
              <select name id className="form-select fs-7 min-w-150px">
                <option value>Open</option>
                <option value>Finished</option>
                <option value>Cancelled</option>
                <option value>Closed</option>
                <option value>Rescheduled</option>
                <option value>Future</option>
              </select>
            </div>
          </div> */}
          <div
            className="card-toolbar collapsible cursor-pointer rotate collapsed"
            // onClick={() => toggleCollapse("age")}
          >
            <div className={collapseState.age ? "rotate-180" : ""}>
              <i className="ki-duotone ki-down fs-1" />
            </div>
          </div>
        </div>
        <div
          id="kt_docs_card_collapsible7"
          className={`collapse ${collapseState.age ? "show" : ""}`}
        >
          <div className="card-body py-2 pb-7">
            {/*begin::Table container*/}
            {ageMetricsLoading ? (
              <div className="table-responsive">
                <Loader cols={7} width={250} />
              </div>
            ) : (
              <div>
                {ageMetricsData?.length > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                        <thead>
                          <tr className="fw-bolder text-gray-900 bg-light-primary">
                            <th class="min-w-75px">Sr. No.</th>
                            <th class="min-w-100px">Age</th>
                            <th className="min-w-100px">Avg Scores</th>
                            <th className="min-w-100px">Applied</th>
                            <th className="min-w-100px">Approved</th>
                            <th className="min-w-100px">Paid</th>
                          </tr>
                        </thead>

                        <tbody>
                          {ageMetricsData?.length > 0 &&
                            ageMetricsData.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td className="bdr-left-user text-center">
                                    {(activePage?.ageMetricsData - 1) *
                                      limit?.ageMetricsData +
                                      1 +
                                      i}
                                  </td>

                                  <td className="fw-semibold fs-6">
                                    {item?.["age"] || "0"}
                                  </td>

                                  <td className="fw-semibold fs-6">
                                    {item?.["avg_score"] || "0"}
                                  </td>

                                  <td> {item?.["applied"] || "0"}</td>
                                  <td> {item?.["approved"] || "0"}</td>

                                  <td> {item?.["paid"] || "0"}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>

                    <div className="separator separator-dashed border-gray-200" />
                    <Pagination
                      setLimit={(e) => {
                        setLimit((prev) => ({
                          ...prev,
                          ageMetricsData: e,
                        }));
                      }}
                      limit={limit?.ageMetricsData}
                      data={ageMetricsData}
                      activePage={activePage?.ageMetricsData}
                      totalRecords={totalRecords?.ageMetricsData}
                      onChange={(pageNumber) => {
                        setAgeMetricsLoading(true);
                        setActivePage((prev) => ({
                          ...prev,
                          ageMetricsData: pageNumber,
                        }));
                      }}
                    />
                  </>
                ) : (
                  <div className="row g-5 mb-0 gx-md-10">
                    <div className="col-md-12 text-center">
                      <div className="mt-0">
                        <img
                          src="/media/no-data-found.png"
                          className="mw-25"
                          alt="No Data Found"
                        />
                      </div>
                      <h1 className="mt-4">No Data Found</h1>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/*end::Pagination*/}
          </div>
        </div>
      </div>

      {/* Monthly Second Payments */}
      <div className="card mb-7">
        <div
          className="card-header cursor-pointer"
          onClick={() => toggleCollapse("monthlySecondPayments")}
        >
          <div className="card-title flex-column ">
            <h2 className="mb-1 fs-6 fs-md-3">Monthly Payments</h2>
          </div>
          <div className="ms-auto d-flex align-items-center me-5 gap-4">
            {/* <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label
                class="form-check-label fs-6 text-gray-800 fw-bold"
                for="flexCheckDefault"
              >
                Show All Time
              </label>
            </div>
            <div className="ms-4">
              <select name id className="form-select fs-7 min-w-150px">
                <option value>Open</option>
                <option value>Finished</option>
                <option value>Cancelled</option>
                <option value>Closed</option>
                <option value>Rescheduled</option>
                <option value>Future</option>
              </select>
            </div> */}
          </div>
          <div
            className="card-toolbar collapsible cursor-pointer rotate collapsed"
            // onClick={() => toggleCollapse("monthlySecondPayments")}
          >
            <div
              className={
                collapseState.monthlySecondPayments ? "rotate-180" : ""
              }
            >
              <i className="ki-duotone ki-down fs-1" />
            </div>
          </div>
        </div>
        <div
          id="kt_docs_card_collapsible8"
          className={`collapse ${
            collapseState.monthlySecondPayments ? "show" : ""
          }`}
        >
          <div className="card-body py-2 pb-7">
            {/*begin::Table container*/}
            {monthlySecondPaymentsLoading ? (
              <div className="table-responsive">
                <Loader cols={7} width={250} />
              </div>
            ) : (
              <div>
                {monthlySecondPaymentsData?.length > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                        <thead>
                          <tr className="fw-bolder text-gray-900 bg-light-primary">
                            <th className="min-w-100px">Months</th>
                            <th className="min-w-100px">Received</th>
                            <th className="min-w-100px">Success</th>
                            <th className="min-w-100px">Fail</th>
                            <th className="min-w-100px">Overdue</th>
                          </tr>
                        </thead>

                        <tbody>
                          {monthlySecondPaymentsData?.length > 0 &&
                            monthlySecondPaymentsData.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td className="fw-semibold fs-6">
                                    {item?.["month"] || "-"}
                                  </td>

                                  <td className="fw-semibold fs-6">
                                    {item?.["total_received"] || "0"}
                                  </td>

                                  <td> {item?.["success_count"] || "0"}</td>
                                  <td> {item?.["fail_count"] || "0"}</td>

                                  <td> {item?.["overdue_count"] || "0"}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>

                    <div className="separator separator-dashed border-gray-200" />
                    <Pagination
                      setLimit={(e) => {
                        setLimit((prev) => ({
                          ...prev,
                          monthlySecondPaymentsData: e,
                        }));
                      }}
                      limit={limit?.monthlySecondPaymentsData}
                      data={monthlySecondPaymentsData}
                      activePage={activePage?.monthlySecondPaymentsData}
                      totalRecords={totalRecords?.monthlySecondPaymentsData}
                      onChange={(pageNumber) => {
                        setMonthlySecondPaymentsLoading(true);
                        setActivePage((prev) => ({
                          ...prev,
                          monthlySecondPaymentsData: pageNumber,
                        }));
                      }}
                    />
                  </>
                ) : (
                  <div className="row g-5 mb-0 gx-md-10">
                    <div className="col-md-12 text-center">
                      <div className="mt-0">
                        <img
                          src="/media/no-data-found.png"
                          className="mw-25"
                          alt="No Data Found"
                        />
                      </div>
                      <h1 className="mt-4">No Data Found</h1>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/*end::Pagination*/}
          </div>
        </div>
      </div>
    </div>
  );
}
