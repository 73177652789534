// Itinerary.js
import { Typography } from "antd";
import React from "react";

const Itinerary = ({ cardData, onEdit, onDelete }) => {
  return (
    <div className="card-body hover-scroll-y p-0">
      <div className="h-600px">
        {cardData.length > 0 ? (
          cardData.map((data) => (
            <div
              className="bg-light p-10 p-md-5 mb-5 rounded position-relative"
              key={data.id}
            >
              {/* Edit Button */}
              <div className="position-absolute top-0 end-0 p-3">
                <i
                  className="ki-outline ki-pencil fs-2 text-info cursor-pointer mb-2"
                  onClick={() => onEdit(data)}
                />
                {/* Delete Button */}
                <i
                  className="ki-outline ki-trash fs-2 ms-2 text-danger cursor-pointer"
                  onClick={() => onDelete(data.id)}
                />
              </div>

              <div className="row h-100">
                <div
                  className={`${
                    data?.default_image ? "col-md-6" : "col-md-6"
                  } mb-10 mb-sm-0`}
                >
                  {data?.default_image ? (
                    <img
                      src={data?.default_image}
                      alt="Default"
                      className="card-rounded min-h-300px min-h-sm-100 h-100"
                      style={{
                        objectFit: "cover",
                        width: "100%",
                      }}
                    />
                  ) : (
                    <img
                      src={`https://placehold.co/300x300/f8f5ff/7843e6/png/?text=${data.day_num}`}
                      alt="Fallback"
                      className="card-rounded min-h-300px min-h-sm-100 h-100"
                      style={{
                        objectFit: "cover",
                        width: "100%",
                      }}
                    />
                  )}
                </div>

                {/* <div
                  className={`${
                    data?.default_image ? "col-md-6" : "col-md-0"
                  } mb-10 mb-sm-0`}
                >
                  {data?.default_image ? (
                    <div
                      className="bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-300px min-h-sm-100 h-100"
                      style={{
                        backgroundSize: "cover",
                        backgroundImage: `url(${
                          data.default_image
                            ? data.default_image
                            : "https://placehold.co/300x300/png"
                        })`,
                      }}
                    ></div>
                  ) : (
                    <div
                      className="bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-300px min-h-sm-100 h-100"
                      style={{
                        backgroundSize: "cover",
                        backgroundImage: `url(${
                          data.default_image
                            ? data.default_image
                            : `https://placehold.co/300x300/f8f5ff/7843e6/png/?text=${data.day_num}`
                        })`,
                      }}
                    ></div>
                  )}
                </div> */}

                <div
                  className={`${
                    data?.default_image ? "col-md-6" : "col-md-6"
                  } `}
                >
                  <div className="d-flex flex-column h-100">
                    <div className="mb-0">
                      <div className="d-flex flex-stack mb-3">
                        <div className="flex-shrink-0 me-5">
                          <span className="text-gray-700 fs-7 fw-bold me-2 d-block lh-1 pb-1">
                            <span className="text-gray-900">
                              {data.day_num}
                            </span>{" "}
                            Day
                          </span>
                          <span
                            className={`text-gray-800 fs-7 fs-md-5 fs-lg-3 fw-bold mw-150px mw-md-200px mw-lg-250px ${
                              data?.default_image ? "w-200px" : "w-400px"
                            } `}
                            style={{
                             
                              whiteSpace: "normal", // Allows text to wrap
                              overflow: "visible", // Removes any clipping
                              wordWrap: "break-word", // Ensures long words are broken to fit
                              display: "block", // Makes the span behave like a block element
                            }}
                          >
                            {data.title}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="mb-0">
                      <div className="d-flex align-items-center mb-2">
                        <div className="symbol symbol-30px symbol-circle mb-1 me-3">
                          <span className="symbol-label bg-primary">
                            <i className="ki-outline ki-delivery-door fs-5 text-white" />
                          </span>
                        </div>
                        <div className="m-0">
                          <span className="fw-semibold text-gray-800 fs-7">
                            <span className="fw-bolder">Hotel:</span>{" "}
                            {data.hotel_name}
                          </span>
                        </div>
                      </div>

                      <div className="d-flex align-items-center mb-2">
                        <div className="symbol symbol-30px symbol-circle  mb-1 me-3">
                          <span className="symbol-label bg-primary">
                            <i className="ki-outline ki-geolocation-home fs-5 text-white" />
                          </span>
                        </div>
                        <div className="m-0">
                          <span className="fw-semibold text-gray-800 fs-7">
                            <span className="fw-bolder">Destination:</span>{" "}
                            {data.destination_name}
                          </span>
                        </div>
                      </div>

                      <div className="">
                        {/* Display Multiple Agendas */}
                        {Array.isArray(data.agenda) &&
                        data.agenda.length > 0 ? (
                          <div className="">
                            <span className="fw-semibold text-gray-800 fs-7 mb-1">
                              <span className="fw-bolder">Agenda:</span>
                            </span>
                            <ul className="">
                              {data.agenda.map((agenda, index) => (
                                <li key={index}>
                                  <Typography.Paragraph
                                    ellipsis={{
                                      rows: 2,
                                      expandable: false,
                                    }}
                                  >
                                    <span className="fw-semibold text-gray-800 fs-7">
                                      {agenda}
                                    </span>
                                  </Typography.Paragraph>
                                  {/* <span className="fw-semibold text-gray-800 fs-7">
                                    {agenda}
                                  </span> */}
                                </li>
                              ))}
                            </ul>
                          </div>
                        ) : (
                          <span className="fw-semibold text-gray-600 fs-6 d-block fs-7">
                            {data.agenda}
                          </span>
                        )}
                      </div>

                      <div className="separator separator-dashed border-gray-300 my-3" />

                      <div className="mb-3">
                        <span className="fw-semibold text-gray-800 fs-7">
                          <span className="fw-bolder">
                            Experience to Itinerary:
                          </span>{" "}
                          {data.experience}
                        </span>
                      </div>

                      <div className="m-0">
                        <span className="fw-semibold text-gray-800 fs-7">
                          <span className="fw-bolder">About this day:</span>{" "}
                          {data.about_this_day}
                        </span>
                      </div>
                    </div>
                    {data.trip_highlight && (
                      <div className="badge badge-light-primary mt-3">
                        Trip Highlight
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="row g-5 mb-0 gx-md-10">
            <div className="col-md-12 text-center">
              <div className="h-600px d-flex flex-column align-items-center justify-content-center">
                <div className="mt-0">
                  <img
                    src="/media/no-data-found.png"
                    className="mw-25"
                    alt="No Data Found"
                  />
                </div>
                <h1 className="mt-4">No Data Found</h1>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Itinerary;
