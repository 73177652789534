import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { Select } from "antd";
import { fetchData } from "../../../../config/service";
import { Loader } from "../../../../components";
import DebouncedSelect from "../../../../components/debounced-select";

export default function T10CallsModal({ show, onHide, data }) {
  const [loading, setLoading] = useState(false);
  const [callData, setCallData] = useState({
    name: "",
    phoneNumber: "",
    edition: "",
    email: "",
    score: "",
    designation: "",
    city: "",
    callPOC: "",
    status: "",
    vibe: "",
    intention: "",
    persona: "",
    questionsAsked: "",
    addOns: "",
    specialComments: "",
  });

  const statusOptions = [
    { value: "Finished", label: "Finished" },
    { value: "Followup Pending", label: "Followup Pending" },
    { value: "Whatsapp Sent", label: "Whatsapp Sent" },
    { value: "Incorrect Number", label: "Incorrect Number" },
  ];

  const vibeOptions = [
    { value: "Detractor", label: "Detractor" },
    { value: "Possible Detractor", label: "Possible Detractor" },
    { value: "Chill/Positive", label: "Chill/Positive" },
    { value: "Supportive/Enthu", label: "Supportive/Enthu" },
    { value: "Tribe", label: "Tribe" },
  ];

  const intentionOptions = [
    { value: "Aligned", label: "Aligned" },
    { value: "Maybe Aligned", label: "Maybe Aligned" },
    { value: "Maybe Not Aligned", label: "Maybe Not Aligned" },
    { value: "Not Aligned", label: "Not Aligned" },
  ];

  useEffect(() => {
    if (data) {
      setCallData({
        name: data.name || "",
        phoneNumber: data.phoneNumber || "",
        edition: data.edition || "",
        email: data.email || "",
        score: data.score,
        designation: data.designation || "",
        city: data.city || "",
        callPOC: data.callPOC || "",
        status: data.status || "",
        vibe: data.vibe || "",
        intention: data.intention || "",
        persona: data.persona || "",
        questionsAsked: data.questionsAsked || "",
        addOns: data.addOns || "",
        specialComments: data.specialComments || "",
      });
    }
  }, [data]);

  const handleInputChange = (field, value) => {
    setCallData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = () => {
    // Save logic here
    console.log("Saved Data:", callData);
    toast.success("Data saved successfully!");
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>T-10 Calls Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loader cols={2} />
        ) : (
          <>
            <div>
              {/* Basic Details Section */}
              <h3 className="text-gray-800 mb-4">Basic Details</h3>
              <div className="row">
                <div className="col-md-4 mb-3">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter name"
                    value={callData.name}
                    onChange={(e) => handleInputChange("name", e.target.value)}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Phone Number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter phone number"
                    value={callData.phoneNumber}
                    onChange={(e) =>
                      handleInputChange("phoneNumber", e.target.value)
                    }
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Edition</label>
                  <DebouncedSelect
                    apiEndpoint="api/editions/editions-select/"
                    value={callData.edition}
                    onChange={(e) => handleInputChange("edition", e)}
                    placeholder="Select Edition"
                    labelFormatter={(item) => item.ip}
                    size="large"
                    style={{ width: "100%" }}
                    dropdownStyle={{ zIndex: 1111 }}
                    allowClear
                    onClear={() => {
                      handleInputChange("edition", null);
                    }}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter email"
                    value={callData.email.toLowerCase()}
                    onChange={(e) =>
                      handleInputChange("email", e.target.value.toLowerCase())
                    }
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label className="form-label">Score</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter score"
                    value={callData.score}
                    onChange={(e) => handleInputChange("score", e.target.value)}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label className="form-label">Designation</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter designation"
                    value={callData.designation}
                    onChange={(e) =>
                      handleInputChange("designation", e.target.value)
                    }
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label className="form-label">City</label>
                  <DebouncedSelect
                    apiEndpoint="api/country/state/city/"
                    value={callData.city}
                    onChange={(e) => handleInputChange("city", e)}
                    placeholder="Select City"
                    labelFormatter={(item) => item.name}
                    size="large"
                    style={{ width: "100%" }}
                    dropdownStyle={{ zIndex: 1111 }}
                    allowClear
                    onClear={() => {
                      handleInputChange("city", null);
                    }}
                    additionalParams={{ is_active: 1 }}
                  />
                </div>
              </div>

              {/* Separator */}
              <div className="separator my-6"></div>

              {/* Call Details Section */}
              <h3 className="text-gray-800 mb-4">Call Details</h3>
              <div className="row">
                <div className="col-md-3 mb-3">
                  <label className="form-label">Call POC</label>
                  <Select
                    className="w-100"
                    value={callData.callPOC}
                    dropdownStyle={{ zIndex: 1111 }}
                    onChange={(value) => handleInputChange("callPOC", value)}
                    options={[
                      { value: "User1", label: "User 1" },
                      { value: "User2", label: "User 2" },
                    ]}
                    size="large"
                    placeholder="Select POC"
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label className="form-label">Status</label>
                  <Select
                    className="w-100"
                    value={callData.status}
                    dropdownStyle={{ zIndex: 1111 }}
                    onChange={(value) => handleInputChange("status", value)}
                    options={statusOptions}
                    size="large"
                    placeholder="Select Status"
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label className="form-label">Vibe</label>
                  <Select
                    className="w-100"
                    value={callData.vibe}
                    dropdownStyle={{ zIndex: 1111 }}
                    onChange={(value) => handleInputChange("vibe", value)}
                    options={vibeOptions}
                    size="large"
                    placeholder="Select Vibe"
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label className="form-label">Intention</label>
                  <Select
                    className="w-100"
                    value={callData.intention}
                    dropdownStyle={{ zIndex: 1111 }}
                    onChange={(value) => handleInputChange("intention", value)}
                    options={intentionOptions}
                    size="large"
                    placeholder="Select Intention"
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Persona</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter persona"
                    value={callData.persona}
                    onChange={(e) =>
                      handleInputChange("persona", e.target.value)
                    }
                  />
                </div>

                <div className="col-md-6">
                  <label className="form-label">Add ons</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter add-ons"
                    value={callData.addOns}
                    onChange={(e) =>
                      handleInputChange("addOns", e.target.value)
                    }
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Questions They Asked</label>
                  <textarea
                    className="form-control"
                    placeholder="Enter questions asked"
                    value={callData.questionsAsked}
                    onChange={(e) =>
                      handleInputChange("questionsAsked", e.target.value)
                    }
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Special Comments</label>
                  <textarea
                    className="form-control"
                    placeholder="Enter special comments"
                    value={callData.specialComments}
                    onChange={(e) =>
                      handleInputChange("specialComments", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={handleSave}
        >
          Save Changes
        </button>
      </Modal.Footer>
    </Modal>
  );
}
