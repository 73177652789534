import dayjs from "dayjs";
import React, { useState } from "react";
import ChangeEditionsModal from "../../modals/change-edition";
import { useEffect } from "react";
import { fetchData } from "../../../../config/service";
import toast from "react-hot-toast";
import { Alert } from "../../../../components";
import moment from "moment";
import { formatNumber, LIMITS } from "../../../../config";
import { Tooltip } from "antd";
import ChangeAmountModal from "../../modals/change-amount";
import RoomAssignModal from "../../../bookings/room-manager/modals/room-assign";
import CreditAmountModal from "../../modals/credit-amount";

const Booking = ({
  editionData,
  getEditionOptions,
  getEditionsLitFromBookingData,
  setIsOpenUserModal,
}) => {
  const [limit, setLimit] = useState(LIMITS[0]);
  const [changeEditionModal, setChangeEditionModal] = useState(false);
  const [changeEditionModalData, setChangeEditionModalData] = useState({});
  const [editionListLoading, setEditionListLoading] = useState(false);
  const [bookingId, setBookingId] = useState(null);
  const [editionsList, setEditionsList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [paymentDetailsLoading, setPaymentDetailsLoading] = useState(false);
  const [paymentsDetailData, setPaymentsDetailsData] = useState([]);

  const [editionActivePage, setEditionActivePage] = useState(1);
  const [editionSearchTerm, setEditionSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] =
    useState(editionSearchTerm);

  const [filterData, setFilterData] = useState({
    // edition: "66e947c95d2d24c2274b73e1",
    edition: null,
  });

  const [changeAmountModal, setChangeAmountModal] = useState(false);
  const [visaFee, setVisaFee] = useState(0);
  const [updateVisaFeeLoading, setUpdateVisaFeeLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isRoomAssignModalOpen, setIsRoomAssignModalOpen] = useState(false);
  const [creditAmountModal, setCreditAmountModal] = useState(false);

  useEffect(() => {
    setBookingId(editionData?.id);
    // getEditions();
    if (editionData?.id) {
      getPaymentDetails(editionData?.id);
    }
  }, [editionData]);

  useEffect(() => {
    getEditions(editionData?.edition_name);
  }, [editionData, editionActivePage, debouncedSearchTerm]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(editionSearchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [editionSearchTerm]);

  const getEditions = (search = "") => {
    const newSearch = debouncedSearchTerm ? debouncedSearchTerm : search;

    const limitNew = newSearch ? 50 : limit;
    const offsetNew = newSearch ? 0 : (editionActivePage - 1) * limit;

    setEditionListLoading(true);
    fetchData(
      `api/editions/editions-select/?offset=${offsetNew}&limit=${limitNew}&search=${encodeURIComponent(
        newSearch || ""
      )}`,
      "get"
    ).then((res) => {
      setEditionListLoading(false);
      if (res.success) {
        const data = res.data.map((e) => {
          return { label: e.ip, value: e.id };
        });
        setEditionsList(data);
      } else {
        toast.error(res.message);
      }
    });
  };

  // const getEditions = () => {
  //   fetchData(`api/editions/editions-select/`, "get").then((res) => {
  //     if (res.success) {
  //       const data = res.data.map((e) => {
  //         return { label: e.name, value: e.id };
  //       });
  //       setEditionsList(data);
  //     } else {
  //       toast.error(res.message);
  //     }
  //   });
  // };

  const deleteItem = () => {
    // console.log(bookingId, "bookingId");
    fetchData(`api/booking/${bookingId}`, "delete").then((res) => {
      toast.dismiss();
      if (res.success) {
        toast.success("Record deleted successfully");
        getEditionsLitFromBookingData();
        getEditionOptions();
        getPaymentDetails(bookingId);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getPaymentDetails = (bookingId) => {
    const body = {
      booking_id: `${bookingId}`,
    };

    setPaymentDetailsLoading(true);
    fetchData(`api/booking/payments-details/`, "post", body).then((res) => {
      setPaymentDetailsLoading(false);
      if (res.success) {
        setPaymentsDetailsData(res);
        setVisaFee(res?.visa_fee);
      } else {
        setPaymentsDetailsData({});
        toast.error(res.message);
      }
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // Call the API here
      setUpdateVisaFeeLoading(true);
      toast.loading("Updating Visa Fee...");
      updateVisaFee();
    }
  };

  const updateVisaFee = () => {
    const body = {
      visa_fee: visaFee,
    };

    fetchData(
      `customer/api/visa/visa-information-admin-details/${paymentsDetailData?.visa_id}/`,
      "PATCH",
      body
    ).then((res) => {
      toast.dismiss();
      setUpdateVisaFeeLoading(false);
      if (res.success) {
        toast.success("Visa Fee Updated Successfully");
        setIsEditable(false);
        getPaymentDetails(bookingId);
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <>
      <div className="card card-flush mb-6 mb-xl-9">
        <div className="card-header">
          <div className="card-title flex-column">
            <h2 className="mb-1">Booking</h2>
          </div>

          <div className="card-toolbar"></div>
        </div>

        <div className="card-body p-9 pt-2">
          <div className=" row g-5 mb-5 gx-md-10">
            <div className="col-md-6">
              <div className="mt-0">
                <div className>
                  <div className="mb-4 fs-6">
                    <span className="fw-bold">Booking ID:</span>{" "}
                    {editionData?.id}
                  </div>
                  <div className="mb-4 fs-6 d-flex align-items-center justify-content-start">
                    <span className="fw-bold">Booking Status:</span>
                    <div
                      className="d-flex align-items-center cursor-pointer ms-2"
                      // onClick={() => setIsOpenUserModal(true)}
                      title="Click To Change Status"
                    >
                      <span
                        className={`badge badge-light-${
                          editionData?.status_value == 1
                            ? "warning"
                            : editionData?.status_value == 2
                            ? "danger"
                            : editionData?.status_value == 3
                            ? "success"
                            : "dark"
                        }`}
                      >
                        {editionData?.status_labels || "-"}
                      </span>

                      {/* <i className="ki-outline ki-down fs-2 text-primary ms-1"></i> */}
                    </div>
                  </div>

                  <div className="mb-4 fs-6">
                    <span className="fw-bold">Start Date:</span>{" "}
                    {editionData?.edition_details?.start_date
                      ? dayjs(editionData?.edition_details?.start_date).format(
                          "DD-MM-YYYY h:mm A"
                        )
                      : "-"}
                  </div>
                  <div className="mb-4 fs-6">
                    <span className="fw-bold">End Date:</span>{" "}
                    {editionData?.edition_details?.start_date
                      ? dayjs(editionData?.edition_details?.end_date).format(
                          "DD-MM-YYYY h:mm A"
                        )
                      : "-"}
                  </div>
                  <div className="mb-2 fs-6">
                    <span className="fw-bold">Room Type:</span>{" "}
                    <span className="fw-semibold text-gray-700">
                      {" "}
                      {editionData?.is_single_room
                        ? "Single Room"
                        : "Twin Share Room"}
                    </span>
                    <Tooltip title="Click to assign Room">
                      <i
                        className="ki-outline ki-user fs-4 text-primary cursor-pointer mb-5 ms-5"
                        onClick={() => {
                          setIsRoomAssignModalOpen(!isRoomAssignModalOpen);
                        }}
                      ></i>
                    </Tooltip>
                  </div>
                  <div className="d-flex align-items-center gap-3">
                    {" "}
                    <div className="my-4 fs-6">
                      <button
                        className="btn btn-sm btn-primary fw-bold fs-7 btn-sm"
                        onClick={() => {
                          setChangeAmountModal(!changeAmountModal);
                          // setChangeAmountModalData(editionData);
                        }}
                      >
                        Add Amount
                      </button>
                    </div>
                    <div className="my-4 fs-6">
                      <button
                        className="btn btn-sm btn-light-primary fw-bold fs-7 btn-sm"
                        onClick={() => {
                          setCreditAmountModal(!creditAmountModal);
                        }}
                      >
                        Credit Amount
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10">
                {/* <div className="mb-3">
                  <a href className="fs-6 fw-semibold">
                    Change Edition
                  </a>
                </div> */}
                <div className="mb-3">
                  <div
                    className="fs-6 fw-semibold text-primary cursor-pointer"
                    onClick={() => {
                      setChangeEditionModal(!changeEditionModal);
                      setChangeEditionModalData(editionData);
                    }}
                  >
                    Reschedule Edition
                  </div>
                </div>
                {/* <div className="mb-3">
              <a href className="fs-6 fw-semibold">
                Link Booking to Application #
              </a>
            </div> */}
                {/* <div className="mb-3">
              <a href className="fs-6 fw-semibold">
                Reset Booking Dates
              </a>
            </div> */}
                {/* <div className="mb-3">
              <a href className="fs-6 fw-semibold">
                Change Payment Status
              </a>
            </div> */}
                <div className="mb-3">
                  <div
                    className="fs-6 fw-semibold text-danger cursor-pointer"
                    onClick={() => {
                      setShowDeleteModal(true);
                    }}
                  >
                    Delete Booking
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <h4 className="mb-8">Status Markers</h4>
              <div className="d-flex flex-stack">
                <div className="fs-6">Call request sent on:</div>
                <div className="fs-6 fw-bolder">-</div>
              </div>
              <div className="separator separator-dashed my-4 " />
              <div className="d-flex flex-stack">
                <div className="fs-6">Call at:</div>
                <div className="fs-6 fw-bolder">-</div>
              </div>
              <div className="separator separator-dashed my-4 " />
              <div className="d-flex flex-stack">
                <div className="fs-6">Followup on:</div>
                <div className="fs-6 fw-bolder">-</div>
              </div>
              <div className="separator separator-dashed my-4 " />
              <div className="d-flex flex-stack">
                <div className="fs-6">Followuped on:</div>
                <div className="fs-6 fw-bolder">-</div>
              </div>
              <div className="separator separator-dashed my-4 " />
              <div className="d-flex flex-stack">
                <div className="fs-6">Payment link sent on:</div>
                <div className="fs-6 fw-bolder">-</div>
              </div>
              <div className="separator separator-dashed my-4 " />
              <div className="d-flex flex-stack">
                <div className="fs-6">Paid on:</div>
                <div className="fs-6 fw-bolder">-</div>
              </div>
            </div>
          </div>

          {/* <div className="separator separator-dashed my-10"></div> */}

          {/* <div className=" row g-5 mb-0 gx-md-10">
            <div className="col-md-6">
              <div className="bg-light-primary p-4 px-5 rounded mb-5 bdr-left-user">
                <div className="d-flex align-items-center flex-stack">
                  <div className="fs-5 fw-bold">Total Fee:</div>
                  <div className="fs-4 fw-bolder text-primary">
                    {" "}
                    {formatNumber(paymentsDetailData?.total_fee) || 0}
                  </div>
                </div>
                <div className="separator separator-dashed my-3 " />
                <div className=" mt-1">
                  <small>
                    <small className="fs-8">
                      Visible to User = Fee for Edition + Dynamic Pricing (if
                      any) - Grant + Reschedule Fees (if any) + Visa Fee
                    </small>
                  </small>
                </div>
              </div>
              <div className="bg-light-primary p-4 px-5 rounded bdr-left-user">
                <div className="d-flex align-items-center flex-stack">
                  <div className="fs-5 fw-bold">Pending Fee:</div>
                  <div className="fs-4 fw-bolder text-primary">
                    {formatNumber(paymentsDetailData?.pending_fee) || 0}
                  </div>
                </div>
                <div className="separator separator-dashed my-3 " />
                <div className=" mt-1">
                  <small>
                    Due Date:{" "}
                    {paymentsDetailData?.due_date
                      ? dayjs(
                          paymentsDetailData?.edition_details?.start_date
                        ).format("DD-MM-YYYY h:mm A")
                      : "-"}
                  </small>
                </div>
              </div>
              <div className="mt-10"></div>
            </div>
            <div className="col-md-6">
              <h4 className="mb-8">Breakdown</h4>
              <div className="d-flex flex-stack">
                <div className="fs-6">Edition Fee:</div>
                <div className="fs-6 fw-bolder">
                  {formatNumber(paymentsDetailData?.edition_cost) || 0}
                </div>
              </div>
              <div className="separator separator-dashed my-4 " />
              <div className="d-flex flex-stack">
                <div className="fs-6">Grant:</div>
                <div className="fs-6 fw-bolder">
                  {formatNumber(paymentsDetailData?.grant) || 0}
                </div>
              </div>
              <div className="separator separator-dashed my-4 " />
              <div className="d-flex flex-stack">
                <div className="fs-6">Dynamic Pricing:</div>
                <div className="fs-6 fw-bolder">
                  {formatNumber(paymentsDetailData?.dynamic_pricing) || 0}
                </div>
              </div>
              <div className="separator separator-dashed my-4 " />
              <div className="d-flex flex-stack">
                <div className="fs-6">Reschedule Fee:</div>
                <div className="fs-6 fw-bolder">
                  {formatNumber(paymentsDetailData?.reschedule_fee) || 0}
                </div>
              </div>
              <div className="separator separator-dashed my-4 " />
              <div className="d-flex flex-stack">
                <div className="fs-6">Fee for User:</div>
                <div className="fs-6 fw-bolder">
                  {formatNumber(paymentsDetailData?.fee_for_user) || 0}
                </div>
              </div>
              <div className="separator separator-dashed my-4 " />
              <div className="d-flex flex-stack">
                <div className="fs-6">Amount Pay By User:</div>
                <div className="fs-6 fw-bolder">
                  {formatNumber(paymentsDetailData?.amount_pay_by_user) || 0}
                </div>
              </div>

              <div className="separator separator-dashed my-4 " />
              <div className="d-flex flex-stack align-items-start">
                <div className="fs-6">
                  <div className="mt-2"> Visa Fee:</div>
                  <div className="mt-2">
                    <small className="text-danger fs-9">
                      Visa Fee has not been added to User Fee yet. <br />
                      This payment could be pending. Please recheck.
                    </small>
                  </div>
                </div>
                <div className="fs-6 fw-bolder">
                  <div className="position-relative d-flex align-items-center">
                    <span className="d-flex flex-stack">
                      {isEditable ? (
                        <input
                          value={visaFee}
                          type="text"
                          className="border form-control-sm w-150px fs-6"
                          placeholder="Enter Visa Fee"
                          onChange={(e) => setVisaFee(e.target.value)}
                          onKeyDown={handleKeyDown}
                          disabled={
                            updateVisaFeeLoading || paymentDetailsLoading
                          }
                          title="Press Enter to save"
                        />
                      ) : (
                        <div className="text-primary fw-bold">
                          {formatNumber(paymentsDetailData?.visa_fee) || 0}
                        </div>
                      )}

                      {Number(paymentsDetailData?.visa_fee) > 0 && (
                        <>
                          {isEditable ? (
                            <Tooltip title="Save">
                              <span
                                className="cursor-pointer d-flex align-items-center"
                                onClick={() => updateVisaFee()}
                                style={{ marginLeft: "8px" }}
                                // title="Save"
                              >
                                <i className="ki-outline ki-check fs-2 text-dark"></i>
                              </span>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Edit">
                              {" "}
                              <span
                                className="cursor-pointer d-flex align-items-center"
                                onClick={() => setIsEditable(!isEditable)}
                                style={{ marginLeft: "8px" }}
                                // title="Edit"
                              >
                                <i className="fa-solid fa-edit fs-5 text-dark ps-2"></i>
                              </span>
                            </Tooltip>
                          )}
                        </>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-10">
                {paymentsDetailData?.transactions &&
                  paymentsDetailData?.transactions.length > 0 && (
                    <h4 className="mb-8 fs-4 fw-bold">Transaction Status</h4>
                  )}

                {paymentsDetailData?.transactions &&
                paymentsDetailData?.transactions.length > 0 ? (
                  <div className="card">
                    <div className="card-body p-0">
                      <div
                        style={{ maxHeight: "400px", overflowY: "auto" }}
                        className="px-5"
                      >
                        {paymentsDetailData.transactions.map((item, i) => (
                          <div key={item.id}>
                            <div className="d-flex flex-row justify-content-between align-items-center py-4">
                              <div className="d-flex flex-column gap-1">
                                <div className="fs-7 text-gray-800">
                                  <span className="fw-bold me-1">Made on:</span>
                                  <span className="text-gray-600">
                                    {item?.created_at
                                      ? moment(item.created_at).format(
                                          "DD MMM YYYY"
                                        )
                                      : "-"}
                                  </span>
                                </div>
                                <div className="fs-7 text-gray-800">
                                  <span className="fw-bold me-1">Amount:</span>
                                  <span className="text-gray-700">
                                    {item?.amount
                                      ? formatNumber(item.amount)
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                              <div>
                                <span
                                  className={`badge ${
                                    item?.status?.toLowerCase() === "success"
                                      ? "badge-light-success"
                                      : item?.status?.toLowerCase() === "failed"
                                      ? "badge-light-danger"
                                      : "badge-light-dark"
                                  }`}
                                >
                                  {item?.status || "-"}
                                </span>
                              </div>
                            </div>

                            {i < paymentsDetailData.transactions.length - 1 && (
                              <div className="separator separator-dashed"></div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <ChangeEditionsModal
        data={editionData}
        onHide={() => {
          setChangeEditionModal(false);
          getEditionOptions();
        }}
        show={changeEditionModal}
        editionsList={editionsList}
        editionListLoading={editionListLoading}
        setEditionSearchTerm={setEditionSearchTerm}
        filterData={filterData}
        setFilterData={setFilterData}
        setEditionListLoading={setEditionListLoading}
        setEditionActivePage={setEditionActivePage}
      />

      <Alert
        show={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        // onSuccess={() => {
        //   getData();
        // }}
        onAction={() => {
          toast.loading("Please wait...");
          deleteItem();
        }}
      />

      <ChangeAmountModal
        show={changeAmountModal}
        data={editionData}
        onHide={() => {
          setChangeAmountModal(false);
          // getAmountOptions();
        }}
        onSuccess={() => {
          getEditionsLitFromBookingData();
        }}
      />

      <RoomAssignModal
        show={isRoomAssignModalOpen}
        onHide={() => {
          setIsRoomAssignModalOpen(false);
        }}
        data={editionData}
        forUserPage={true}
      />

      <CreditAmountModal
        show={creditAmountModal}
        data={editionData}
        onHide={() => {
          setCreditAmountModal(false);
          // getAmountOptions();
        }}
        onSuccess={() => {
          getEditionsLitFromBookingData();
        }}
      />
    </>
  );
};

export default Booking;
