import React, { useState } from "react";
import GamePlanModal from "./gameplanModal";
import { Loader } from "../../../../../components";
import { fetchData } from "../../../../../config/service";
import toast from "react-hot-toast";
import moment from "moment";
import GamePlanDayModal from "./dayModal";
import dayjs from "dayjs";

const TIME_SLOTS = {
  1: "Early Morning",
  2: "Morning",
  3: "Noon",
  4: "Afternoon",
  5: "Late Afternoon",
  6: "Evening",
  7: "Late Night",
  8: "Night",
};

const GamePlan = ({
  gamePlanLoading,
  gamePlanData,
  setGamePlanLoading,
  getGamePlanData,
}) => {
  const events2 = [
    {
      id: "672d9db7ff17612dce019703",
      related_edition: "671505bef848cc1981cf9471",
      day_number: 1,
      date: "2025-03-23T00:00:00Z",
      game_segments: [
        {
          id: "672da05b2a9cd8db2fd99108",
          game_plan: "672d9db7ff17612dce019703",
          poc_name: "Dhaval",
          poc_phone_number: "+1234567890",
          city: "Sola",
          title: "Morning Yoga Session",
          meal_inclusion: "Breakfast",
          special_notes: "Bring your own mat",
          feedback: "Excellent session",
          start_time: "2023-12-01T08:00:00Z",
          end_time: "2023-12-01T09:00:00Z",
          time_slot: 2,
          facilitator_todos: ["Introduction", "breakfast", "Prepare mats"],
          budgets_and_finance: ["Introduction", "breakfast", "Prepare mats"],
          recommendations: ["Introduction", "breakfast", "Prepare mats"],
          google_maps: "https://maps.google.com/?q=Central+Park",
          tickets: "https://example.com/tickets/yoga-session",
          room_distrubution: "https://example.com/rooms",
          team_stay_and_flights: "https://example.com/flights",
        },
        {
          id: "672da1b7cb46470212b1031d",
          game_plan: "672d9db7ff17612dce019703",
          poc_name: "Archan",
          poc_phone_number: "+917069903020",
          city: "Naranpura",
          title: "Lunch",
          meal_inclusion: "dal rice",
          special_notes: "Bring your own mat",
          feedback: "Excellent session",
          start_time: "2023-12-01T08:00:00Z",
          end_time: "2023-12-01T09:00:00Z",
          time_slot: 2,
          facilitator_todos: ["Introduction", "breakfast", "Prepare mats"],
          budgets_and_finance: ["Introduction", "breakfast", "Prepare mats"],
          recommendations: ["Introduction", "breakfast", "Prepare mats"],
          google_maps: "https://maps.google.com/?q=Central+Park",
          tickets: "https://example.com/tickets/yoga-session",
          room_distrubution: "https://example.com/rooms",
          team_stay_and_flights: "https://example.com/flights",
        },
      ],
    },
  ];
  const [gamePlanModal, setGamePlanModal] = useState(false);
  const [gamePlanDayModal, setGamePlanDayModal] = useState(false);

  const [gamePlanId, setGamePlanId] = useState(null);
  const [gamePlanModalData, setGamePlanModalData] = useState({});
  const [eventDate, setEventDate] = useState(null);

  // State to keep track of open time slots for each event
  const [openTimeSlots, setOpenTimeSlots] = useState({});

  // Toggle function to handle accordion state for each event's time slot
  const toggleTimeSlot = (eventId, slotIndex) => {
    setOpenTimeSlots((prevState) => ({
      ...prevState,
      [eventId]: prevState[eventId] === slotIndex ? null : slotIndex,
    }));
  };

  const [activeTab, setActiveTab] = useState({});

  const handleTabChange = (segmentId, tabName) => {
    setActiveTab((prev) => ({
      ...prev,
      [segmentId]: tabName,
    }));
  };

  const handleDelete = (id) => {
    toast.loading("Deleting Game Segment ....");

    fetchData(`api/gameplan/gamesegments/${id}/`, "delete").then((res) => {
      if (res.success) {
        toast.remove();
        setGamePlanLoading(true);
        getGamePlanData();
        toast.success("Deleted Successfully");
      } else {
        toast.remove();
      }
    });
  };

  const handleGamePlanDelete = (id) => {
    toast.loading("Deleting Game Plan ....");

    fetchData(`api/gameplan/detail/${id}/`, "delete").then((res) => {
      if (res.success) {
        toast.remove();
        setGamePlanLoading(true);
        getGamePlanData();
        toast.success("Deleted Successfully");
      } else {
        toast.remove();
      }
    });
  };

  // console.log(gamePlanId, "gamePlanId");

  // console.log(gamePlanLoading, "gamePlanLoading");
  // console.log(gamePlanData, "gamePlanData");

  // console.log(gamePlanModalData, "gamePlanModalData");

  return (
    <>
      <div className="card-body hover-scroll-y p-0 p-md-5 p-lg-10">
        <div className="h-600px">
          {gamePlanLoading ? (
            <>
              <Loader cols={1} />
            </>
          ) : (
            <>
              {" "}
              {gamePlanData && gamePlanData.length > 0 ? (
                <>
                  {gamePlanData.map((event) => (
                    <div
                      key={event.id}
                      className="card card-flush shadow-sm mb-3"
                    >
                      <div className="card-header min-h-50px mt-4">
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="d-flex align-items-center">
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label fs-1 bg-light-primary text-primary fw-bold">
                                {event.day_number}
                              </div>
                            </div>
                            <div className="d-flex flex-column">
                              <span className="fs-5 fw-bold text-dark">
                                Day {event.day_number}
                              </span>
                              <span className="fs-6 text-muted fw-semibold">
                                {moment(event.date).format("DD-MM-YYYY")}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex gap-2">
                            <button
                              title="Add Game Segment"
                              className="btn btn-icon btn-sm btn-light-primary"
                              onClick={() => {
                                setEventDate(event.date);
                                setGamePlanId(event.id);
                                setGamePlanModal(!gamePlanModal);
                              }}
                            >
                              <i className="ki-outline ki-plus-square fs-2"></i>
                            </button>
                            <button
                              title="Delete Day"
                              className="btn btn-icon btn-sm btn-light-danger"
                              onClick={() => {
                                handleGamePlanDelete(event.id);
                              }}
                            >
                              <i className="fas fa-trash fs-6"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="card-body py-3 p-0 p-md-5 p-lg-10">
                        <div
                          className="accordion"
                          id={`accordionEventDetails-${event.id}`}
                        >
                          {event.game_segments.map((segment, index) => (
                            <div key={segment.id} className="mb-2">
                              {/* Segment Header */}
                              <h2
                                className="accordion-header"
                                id={`heading-${event.id}-${index}`}
                              >
                                <div className="d-flex align-items-center justify-content-between px-4 px-md-0 px-lg-0">
                                  <button
                                    className={`accordion-button me-2 py-1 ${
                                      openTimeSlots[event.id] === index
                                        ? ""
                                        : "collapsed"
                                    }`}
                                    type="button"
                                    onClick={() =>
                                      toggleTimeSlot(event.id, index)
                                    }
                                  >
                                    <span className="fw-semibold text-gray-700 fs-6">
                                      {segment.title}
                                    </span>
                                  </button>
                                  <div className="d-flex align-items-center">
                                    <i
                                      className="fas fa-edit me-2 text-primary"
                                      title="Edit Segment"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setGamePlanId(event.id);
                                        setEventDate(event.date);

                                        setGamePlanModalData({ ...segment });
                                        setGamePlanModal(!gamePlanModal);
                                      }}
                                    />
                                    <i
                                      className="fas fa-trash text-danger"
                                      title="Delete Segment"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleDelete(segment.id);
                                      }}
                                    />
                                  </div>
                                </div>
                              </h2>
                              {/* Segment Content */}
                              {openTimeSlots[event.id] === index && (
                                <div className=" rounded mt-4 p-2 p-md-5 p-lg-10">
                                  <div className="row g-4">
                                    {/* Left Column - Basic Info */}
                                    <div className="col-md-12">
                                      <div className="d-flex flex-column gap-3">
                                        <div className="d-flex justify-content-between align-items-start">
                                          {/* Time & Location */}
                                          <div className="bg-white rounded p-3">
                                            <h6 className="text-gray-800 mb-3">
                                              <i className="fas fa-clock me-2 text-primary"></i>
                                              Schedule & Location
                                            </h6>
                                            <div className="d-flex flex-column gap-2">
                                              <p className="mb-0 fs-7">
                                                <span className="text-gray-600">
                                                  Time:
                                                </span>
                                                <span className="ms-2">
                                                  {dayjs(segment.start_time)
                                                    .utc()
                                                    .format("HH:mm")}
                                                  {/* {moment(
                                                    segment.start_time
                                                  ).format(" h:mm A")}{" "} */}
                                                  -{" "}
                                                  {dayjs(segment.end_time)
                                                    .utc()
                                                    .format("HH:mm")}
                                                </span>
                                              </p>
                                              <p className="mb-0 fs-7">
                                                <span className="text-gray-600">
                                                  City:
                                                </span>
                                                <span className="ms-2">
                                                  {segment.city}
                                                </span>
                                              </p>
                                              <p className="mb-0 fs-7">
                                                <span className="text-gray-600">
                                                  Time Slot:
                                                </span>
                                                <span className="ms-2">
                                                  {
                                                    TIME_SLOTS[
                                                      segment.time_slot
                                                    ]
                                                  }
                                                </span>
                                              </p>
                                              <div className="d-flex gap-2 mt-2">
                                                {segment.google_maps && (
                                                  <a
                                                    href={segment.google_maps}
                                                    className="btn btn-sm btn-light-primary"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    <i className="fas fa-map-marker-alt me-1"></i>{" "}
                                                    Maps
                                                  </a>
                                                )}
                                                {segment.tickets && (
                                                  <a
                                                    href={segment.tickets}
                                                    className="btn btn-sm btn-light-primary"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    <i className="fas fa-ticket-alt me-1"></i>{" "}
                                                    Tickets
                                                  </a>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          {/* Facilitator Info */}
                                          <div className="bg-white rounded p-3">
                                            <h6 className="text-gray-800 mb-3">
                                              <i className="fas fa-user me-2 text-primary"></i>
                                              Facilitator
                                            </h6>
                                            <p className="mb-0 fs-7">
                                              {segment.poc_name} <br />
                                              <small className="text-gray-600">
                                                <i className="fas fa-phone me-1"></i>
                                                {segment.poc_phone_number}
                                              </small>
                                            </p>
                                          </div>
                                        </div>

                                        {/* Additional Info */}
                                        {(segment.meal_inclusion ||
                                          segment.special_notes ||
                                          segment.feedback) && (
                                          <div className="bg-white rounded p-3">
                                            <h6 className="text-gray-800 mb-3">
                                              <i className="fas fa-info-circle me-2 text-primary"></i>
                                              Additional Information
                                            </h6>
                                            {segment.meal_inclusion && (
                                              <p className="mb-2 fs-7">
                                                <span className="text-gray-600">
                                                  Meal:
                                                </span>
                                                <span className="ms-2">
                                                  {segment.meal_inclusion}
                                                </span>
                                              </p>
                                            )}
                                            {segment.special_notes && (
                                              <p className="mb-2 fs-7">
                                                <span className="text-gray-600">
                                                  Notes:
                                                </span>
                                                <span className="ms-2">
                                                  {segment.special_notes}
                                                </span>
                                              </p>
                                            )}
                                            {segment.feedback && (
                                              <p className="mb-0 fs-7">
                                                <span className="text-gray-600">
                                                  Feedback:
                                                </span>
                                                <span className="ms-2">
                                                  {segment.feedback}
                                                </span>
                                              </p>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>

                                    {/* Right Column - Dynamic Content */}
                                    <div className="col-md-12">
                                      <div className="bg-white rounded p-3">
                                        {/* To-Dos Section */}
                                        {segment.facilitator_todos?.[0] !==
                                          "" && (
                                          <div className="mb-5">
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                              <h6 className="text-gray-800 mb-0">
                                                <i className="fas fa-tasks me-2 text-primary"></i>
                                                Facilitator's To-Dos
                                              </h6>
                                              <span className="badge badge-light-primary">
                                                {
                                                  segment.facilitator_todos
                                                    .length
                                                }{" "}
                                                items
                                              </span>
                                            </div>
                                            <div
                                              className="hover-scroll-overlay-y pe-4"
                                              style={{
                                                maxHeight: "300px",
                                                overflowY: "auto",
                                              }}
                                            >
                                              {segment.facilitator_todos.map(
                                                (todo, idx) => (
                                                  <div
                                                    key={idx}
                                                    className="d-flex align-items-center bg-light-primary rounded p-3 mb-2"
                                                  >
                                                    <div className="d-flex align-items-center">
                                                      <span className="bullet bullet-vertical bg-primary me-3"></span>
                                                      <span className="fs-7">
                                                        {todo}
                                                      </span>
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        )}

                                        {/* Budget Section */}
                                        {segment.budgets_and_finance?.[0] !==
                                          "" && (
                                          <div className="mb-5">
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                              <h6 className="text-gray-800 mb-0">
                                                <i className="fas fa-dollar-sign me-2 text-success"></i>
                                                Budget & Finance
                                              </h6>
                                              <span className="badge badge-light-success">
                                                {
                                                  segment.budgets_and_finance
                                                    .length
                                                }{" "}
                                                items
                                              </span>
                                            </div>
                                            <div
                                              className="hover-scroll-overlay-y pe-4"
                                              style={{
                                                maxHeight: "300px",
                                                overflowY: "auto",
                                              }}
                                            >
                                              {segment.budgets_and_finance.map(
                                                (item, idx) => (
                                                  <div
                                                    key={idx}
                                                    className="d-flex align-items-center bg-light-success rounded p-3 mb-2"
                                                  >
                                                    <div className="d-flex align-items-center">
                                                      <span className="bullet bullet-vertical bg-success me-3"></span>
                                                      <span className="fs-7">
                                                        {item}
                                                      </span>
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        )}

                                        {/* Recommendations Section */}
                                        {segment.recommendations?.[0] !==
                                          "" && (
                                          <div>
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                              <h6 className="text-gray-800 mb-0">
                                                <i className="fas fa-lightbulb me-2 text-warning"></i>
                                                Recommendations
                                              </h6>
                                              <span className="badge badge-light-warning">
                                                {segment.recommendations.length}{" "}
                                                items
                                              </span>
                                            </div>
                                            <div
                                              className="hover-scroll-overlay-y pe-4"
                                              style={{
                                                maxHeight: "300px",
                                                overflowY: "auto",
                                              }}
                                            >
                                              {segment.recommendations.map(
                                                (recommendation, idx) => (
                                                  <div
                                                    key={idx}
                                                    className="d-flex align-items-center bg-light-warning rounded p-3 mb-2"
                                                  >
                                                    <div className="d-flex align-items-center">
                                                      <span className="bullet bullet-vertical bg-warning me-3"></span>
                                                      <span className="fs-7">
                                                        {recommendation}
                                                      </span>
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}{" "}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div class="row g-5 mb-0 gx-md-10">
                  <div class="col-md-12 text-center">
                    <div class="h-600px d-flex flex-column align-items-center justify-content-center">
                      <div class="mt-0">
                        <img
                          src="/media/no-data-found.png"
                          class="mw-25"
                          alt="No Data Found"
                        />
                      </div>
                      <h1 class="mt-4">No Data Found</h1>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <GamePlanModal
        show={gamePlanModal}
        onHide={() => {
          setGamePlanModal(false);
          setGamePlanModalData(null);
          setGamePlanLoading(true);
          getGamePlanData();
        }}
        gamePlanId={gamePlanId}
        data={gamePlanModalData}
        eventDate={eventDate}
      />
    </>
  );
};

export default GamePlan;
