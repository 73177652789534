import React, { useEffect, useState } from "react";
import { Timeline, Button, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import { fetchData } from "../../../../config/service";
import dayjs from "dayjs";
import { NotesModal } from "../../modals";

const VisaJourneyTimeline = ({ userId, notesOptions }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [noteValue, setNoteValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    getTimelineData();
  }, [userId]);

  const getTimelineData = () => {
    setLoading(true);
    fetchData(`api/user-activities-log/?user_id=${userId}`).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res?.data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleOk = () => {
    if (noteValue.trim()) {
      console.log("New note:", noteValue);
      // Here you would typically add the new note to your data
      setNoteValue("");
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setNoteValue("");
  };

  const items =
    data?.length > 0 &&
    data?.map((item) => ({
      color: "green", // Set your desired color
      children: (
        <>
          <p className="font-weight-bold">{item.message}</p>
          <p className="text-muted">
            {/* {dayjs.utc(item?.created_at).format("h:mm A - MMM D, YYYY")} */}
            {dayjs(item.created_at).format("DD-MM-YYYY h:mm a")}
          </p>
          {/* You can uncomment and use the Button if needed */}
          {/* <Button
          type="link"
          onClick={showModal}
          className="p-0 text-primary"
        >
          Add Note
        </Button> */}
        </>
      ),
    }));

  return (
    <div className="card ">
      {/* <div className="card-header">
        <div
          onClick={showModal}
          className="btn btn-primary fw-bold position-sticky top-0 mx-4"
        >
          Add Note
        </div>
      </div> */}
      <div className="card-body position-relative">
        <div className="h-300px w-100 position-relative scroll-y">
          {loading ? (
            <div className="d-flex justify-content-center align-items-center w-100 h-300px">
              <div className="spinner-border text-primary" />
            </div>
          ) : (
            <>
              <div className="p-5 mt-20 mt-md-0 mt-lg-0">
                {data?.length > 0 ? (
                  <div className="row g-5 mb-0 gx-md-10">
                    <div className="col-md-12 ">
                      <div className="mt-0">
                        <Timeline items={items} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row g-5 mb-0 gx-md-10">
                    <div className="col-md-12 text-center">
                      <div className="mt-0">
                        <img
                          src="/media/no-data-found.png"
                          className="mw-25"
                          alt="No Data Found"
                        />
                      </div>
                      <h1 className="mt-4">No Data Found</h1>
                    </div>
                  </div>
                )}
              </div>
              <div
                onClick={showModal}
                className="btn btn-primary fw-bold"
                style={{
                  position: "absolute",
                  top: "1rem",
                  right: "1rem",
                  zIndex: 1000,
                }}
              >
                Add Note
              </div>
            </>
          )}
        </div>

        {isModalVisible && (
          <NotesModal
            show={isModalVisible}
            notesOptions={notesOptions}
            onHide={() => {
              setIsModalVisible(false);
              // setNotesLoading(true);
              // getNotes();
            }}
            data={{
              uuid: userId,
            }}
          />
        )}

        {/* <Modal
          title="Add Note"
          open={isModalVisible}
          footer={
            <>
              <button
                type="button"
                className="btn btn-light-danger fs-7 me-3"
                onClick={handleCancel}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary fs-7"
                onClick={() => {
                  toast.loading("Adding Curation Note");
                  handleOk();
                }}
                // disabled={sending}
              >
                Submit
                {false && (
                  <span className="spinner-border spinner-border-sm align-middle ms-2" />
                )}
              </button>
            </>
          }
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="row mt-5">
            <label
              htmlFor="noteInput"
              className="form-label required fs-6 fw-bold mb-2"
            >
              Note
            </label>
            <textarea
              id="noteInput"
              className="form-control "
              rows={4}
              value={noteValue}
              onChange={(e) => setNoteValue(e.target.value)}
              placeholder="Enter your note here"
            />
          </div>
        </Modal> */}
      </div>
    </div>
  );
};

export default VisaJourneyTimeline;
