import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../../config/service";
import { message, Select, Tooltip } from "antd";
import { validateFields } from "../../../../components/validation";
import ReactQuill from "react-quill";

export default function VisaGuidelineModal({
  show,
  item,
  onHide,
  countries,
  onSuccess,
}) {
  const [sending, setSending] = useState(false);

  const [currentInput, setCurrentInput] = useState("");

  const [formData, setFormData] = useState({
    country: null,
    visaType: "",
    news: "",
    checklistLink: "",
    reasonsForRejection: "",
    visaProcess: "",
    chancesOfApproval: "",
    sources: "",
    visaFee: 0,
    conciergeFee: 0,
    excoFeeFullBoard: 0,
    excoFeeHalfBoard: 0,
    excoFeeDocReview: 0,
    excoFeeFree: 0,
    service_fee: 0,
    travel_insurance: 0,
    sample_visa_application: 0,
    appointmentfee: 0,
    embassyfee: 0,
    externalagencyfee: 0,
    courierfee: 0,
    miscellaneousfee: 0,
    insurancefee: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (item?.id) {
      setFormData({
        country: item.country || "",
        visaType: item.visa_type || "",
        news: item.news || "",
        checklistLink: item.checklist_link || "",
        reasonsForRejection: item.rejections || "",
        visaProcess: item.visa_process || "",
        chancesOfApproval: item.chances_of_approval || "",
        sources: item.sources || "",
        visaFee: item.visa_fee?.amount,
        conciergeFee: item.concierge_fee?.amount,
        excoFeeFullBoard: item.full_board_fee?.amount,
        excoFeeHalfBoard: item.half_board_fee?.amount,
        excoFeeDocReview: item.doc_board_fee?.amount,
        excoFeeFree: item.exco_fee?.amount,
        service_fee: item.visa_service_fee?.amount,
        travel_insurance: item?.travel_insurance?.amount,
        sample_visa_application: item?.sample_visa_application || "",
        appointmentfee: item.appointment_fee?.amount,
        embassyfee: item.embassy_fee?.amount,
        externalagencyfee: item.external_agency_fee?.amount,
        courierfee: item.courier_fee?.amount,
        miscellaneousfee: item.miscellaneous_fee?.amount,
        // insurancefee: item.insurance_fee?.amount,
      });
    } else {
      setFormData({
        country: null,
        visaType: "",
        news: "",
        checklistLink: "",
        reasonsForRejection: "",
        visaProcess: "",
        chancesOfApproval: "",
        sources: "",
        visaFee: 0,
        conciergeFee: 0,
        excoFeeFullBoard: 0,
        excoFeeHalfBoard: 0,
        excoFeeDocReview: 0,
        excoFeeFree: 0,
        service_fee: 0,
        travel_insurance: 0,
        sample_visa_application: 0,
        appointmentfee: 0,
        embassyfee: 0,
        externalagencyfee: 0,
        courierfee: 0,
        miscellaneousfee: 0,
        insurancefee: 0,
      });
    }
  }, [item]);

  const rules = {
    country: {
      required: true,
      message: "Please select a country", // Custom message for the required field
    },
    visaType: {
      required: true,
      message: "Please select a visa type", // Custom message
    },
    news: {
      required: false,
      maxLength: 500, // Allow up to 500 characters for news
    },
    sources: {
      required: false,
      maxLength: 500, // Allow up to 500 characters for sources
      message: "Sources must be at most 500 characters",
    },

    checklistLink: {
      required: true,
      //   type: "url", // URL validation
      message: "Please provide a valid URL for the checklist link",
    },
    sample_visa_application: {
      required: true,
      //   type: "url", // URL validation
      message: "Please provide a valid URL for the sample visa application",
    },
    reasonsForRejection: {
      required: false,
      maxLength: 1000, // Allow up to 1000 characters for reasons for rejection
      message: "Reasons for rejection must be at most 1000 characters",
    },
    // visaProcess: {
    //   required: true,
    //   maxLength: 1000, // Limit the length of visa process explanation
    //   message: "Visa process must be at most 1000 characters",
    // },
    chancesOfApproval: {
      required: true,
      type: "number",
      min: 0,
      max: 100, // Expect a percentage between 0 and 100
      message: "Chances of approval must be a valid number between 0 and 100",
    },
    travel_insurance: {
      required: true,
      type: "number",
    },
    visaFee: {
      required: true,
      type: "number",
      min: 0, // Visa fee must be a non-negative number
      message: "Visa fee must be a valid non-negative number",
    },
    conciergeFee: {
      required: true,
      type: "number",
      min: 0, // Concierge fee must be a non-negative number
      message: "Concierge fee must be a valid non-negative number",
    },
    excoFeeFullBoard: {
      required: true,
      type: "number",
      min: 0, // Exco fee (Full board) must be a non-negative number
      message: "Exco fee (Full board) must be a valid non-negative number",
    },
    excoFeeHalfBoard: {
      required: true,
      type: "number",
      min: 0, // Exco fee (Half board) must be a non-negative number
      message: "Exco fee (Half board) must be a valid non-negative number",
    },
    excoFeeDocReview: {
      required: true,
      type: "number",
      min: 0, // Exco fee (Doc review) must be a non-negative number
      message: "Exco fee (Document review) must be a valid non-negative number",
    },
    excoFeeFree: {
      required: true,
      type: "number",
      min: 0, // Exco fee (Free) must be a non-negative number
      message: "Exco fee (Free) must be a valid non-negative number",
    },
    service_fee: {
      required: false,
      type: "number",
      min: 0, // Service fee must be a non-negative number
      message: "Service fee must be a valid non-negative number",
    },
    appointmentfee: {
      required: false,
      type: "number",
      min: 0, // Appointment fee must be
      message: "Appointment fee must be a valid non-negative number",
    },
    embassyfee: {
      required: false,
      type: "number",
      min: 0, // Convention fee must be a non-negative number
      message: " Embassy fee must be a valid non-negative number",
    },
    externalagencyfee: {
      required: false,
      type: "number",
      min: 0, // Convention fee must be a non
      message: "External Agency Fee must be a valid non-negative number",
    },
    courierfee: {
      required: false,
      type: "number",
      min: 0, // Convention fee must be a non
      message: "Courier Fee must be a valid non-negative number",
    },
    miscellaneousfee: {
      required: false,
      type: "number",
      min: 0, // Convention fee must be a non
      message: "Miscellaneous Fee must be a valid non-negative number",
    },
  };

  const onSubmit = () => {
    const { isValid, errors } = validateFields(formData, rules);

    if (!isValid) {
      return;
    }

    const formattedValue = formData?.reasonsForRejection
      .replace(/<p>/g, "<div>") // Replace opening <p> with <div>
      .replace(/<\/p>/g, "</div>\n"); // Replace closing </p> with </div> and add a newline

    const body = {
      country: formData?.country,
      visa_type: formData?.visaType,
      news: formData?.news,
      rejections: formattedValue,
      chances_of_approval: formData?.chancesOfApproval || 0,
      sources: formData?.sources,
      visa_fee: formData?.visaFee || 0,
      concierge_fee: formData?.conciergeFee || 0,
      full_board_fee: formData?.excoFeeFullBoard || 0,
      half_board_fee: formData?.excoFeeHalfBoard || 0,
      doc_board_fee: formData?.excoFeeDocReview || 0,
      exco_fee: formData?.excoFeeFree || 0,
      checklist_link: formData?.checklistLink || 0,
      travel_insurance: formData?.travel_insuranc || 0,
      sample_visa_application: formData?.sample_visa_application || 0,
      visa_service_fee: formData?.service_fee || 0,
      appointment_fee: formData?.appointmentfee || 0,
      embassy_fee: formData?.embassyfee || 0,
      external_agency_fee: formData?.externalagencyfee || 0,
      courier_fee: formData?.courierfee || 0,
      miscellaneous_fee: formData?.miscellaneousfee || 0,
      // insurance_fee: formData?.insurancefee,

      // visa_process: formData?.visaProcess, // Assuming you want to use one of the visa processes
    };

    // console.log(body, "body");

    // {{local_url}}/customer/api/visa/visa-game-plan-details/{{visaGamePlanId}}/

    let api = item?.id
      ? `customer/api/visa/visa-game-plan-details/${item?.id}/`
      : "customer/api/visa/visa-game-plan-details/create-list/";
    let method = item?.id ? "put" : "post";

    fetchData(api, method, body, "", false).then((res) => {
      setSending(false);
      if (res.success) {
        toast.success("Success");
        onHide();
        onSuccess();
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <Modal centered show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <h3 className="modal-title">
          {item?.id ? "Edit Visa Guideline" : "Add Visa Guideline"}
        </h3>
      </Modal.Header>
      <div className="modal-body pb-7 pt-5 px-lg-10">
        <div
          className="scroll-y pe-7 ps-3"
          id="kt_modal_add_customer_scroll"
          style={{
            height: "430px",
          }}
        >
          <div className="row">
            <div className="col-md-6 mb-3">
              <label htmlFor="country" className=" fs-6 fw-bold mb-2  required">
                Country
              </label>

              <Select
                options={countries?.map((role) => {
                  return {
                    label: role?.name,
                    value: role?.id,
                  };
                })}
                showSearch
                optionFilterProp="label"
                value={formData?.country}
                onChange={(e) =>
                  setFormData((prevData) => ({ ...prevData, country: e }))
                }
                placeholder="Select Country"
                dropdownStyle={{ zIndex: 1111 }}
                style={{ width: "100%" }}
                size="large"
                allowClear
                onClear={() =>
                  setFormData((prevData) => ({
                    ...prevData,
                    country: null,
                  }))
                }
              />
              {/* <select
                className="form-select"
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
              >
                <option value="">Select Country</option>
                <option value="USA">USA</option>
               
              </select> */}
            </div>
            <div className="col-md-6 mb-3 mt-2 mt-md-0">
              <label htmlFor="visaType" className=" fs-6 fw-bold mb-2 required">
                Visa Type
              </label>
              <input
                type="text"
                className="form-control"
                id="visaType"
                name="visaType"
                value={formData.visaType}
                onChange={handleChange}
                placeholder="Enter Visa Type"
              />

              {/* <select
                className="form-select"
                id="visaType"
                name="visaType"
                value={formData.visaType}
                onChange={handleChange}
              >
                <option value="">Select Visa Type</option>
                <option value="Tourist">Tourist</option>
                
              </select> */}
            </div>
          </div>

          <div className="mb-3 mt-2 mt-md-0">
            <label htmlFor="news" className=" fs-6 fw-bold mb-2">
              News
            </label>
            <textarea
              className="form-control"
              id="news"
              name="news"
              rows="3"
              value={formData?.news}
              onChange={handleChange}
              placeholder="Enter news about visa requirements"
            ></textarea>
          </div>

          <div className="mb-3 mt-2 mt-md-0 ">
            <label htmlFor="sources" className=" fs-6 fw-bold mb-2 ">
              Sources
            </label>
            <input
              type="text"
              className="form-control"
              id="sources"
              name="sources"
              value={formData?.sources}
              onChange={handleChange}
              placeholder="Enter sources"
            />
          </div>

          <div className="mb-3 mt-2 mt-md-0">
            <label
              htmlFor="checklistLink"
              className=" fs-6 fw-bold mb-2 required"
            >
              Checklist Link
            </label>
            <input
              type="text"
              className="form-control"
              id="checklistLink"
              name="checklistLink"
              value={formData.checklistLink}
              onChange={handleChange}
              placeholder="Enter checklist link"
            />
          </div>

          <div className="mb-3 mt-2 mt-md-0">
            <label
              htmlFor="sample_visa_application"
              className=" fs-6 fw-bold mb-2 required"
            >
              Sample Visa Application Link
            </label>
            <input
              type="text"
              className="form-control"
              id="sample_visa_application"
              name="sample_visa_application"
              value={formData.sample_visa_application}
              onChange={handleChange}
              placeholder="Enter Sample Visa Application link"
            />
          </div>

          <div className="mb-3 mt-2 mt-md-0">
            <label htmlFor="reasonsForRejection" className=" fs-6 fw-bold mb-2">
              Reasons for Rejection
            </label>
            <ReactQuill
              theme="snow"
              value={formData.reasonsForRejection}
              onChange={(e) => {
                setFormData((prevState) => ({
                  ...prevState,
                  reasonsForRejection: e,
                }));
              }}
            />

            {/* <textarea
              className="form-control"
              id="reasonsForRejection"
              name="reasonsForRejection"
              rows="3"
              value={formData.reasonsForRejection}
              onChange={handleChange}
              placeholder="Enter reasons for rejection"
            ></textarea> */}
          </div>

          <div className="row">
            {/* <div className="col-md-6 mb-3">
              <label
                htmlFor="visaProcess"
                className=" fs-6 fw-bold mb-2 required"
              >
                Visa Process
              </label>
              <input
                type="text"
                className="form-control"
                id="visaProcess"
                name="visaProcess"
                value={formData.visaProcess}
                onChange={handleChange}
                placeholder="Enter visa process details"
              />
            </div> */}

            <div className="col-md-6 mb-3 mt-2 mt-md-0">
              <label
                htmlFor="chancesOfApproval"
                className=" fs-6 fw-bold mb-2 required"
              >
                Chances of Approval
              </label>
              <input
                type="number" // Change to type "number"
                className="form-control"
                id="chancesOfApproval"
                name="chancesOfApproval"
                value={formData.chancesOfApproval}
                onChange={handleChange}
                placeholder="Enter chances of approval"
                min="0"
                max="100"
              />
            </div>

            <div className="col-md-6 mb-3 mt-2 mt-md-0">
              <label
                htmlFor="travel_insurance"
                className=" fs-6 fw-bold mb-2 required"
              >
                Travel Insurance
              </label>
              <input
                type="number" // Change to type "number"
                className="form-control"
                id="travel_insurance"
                name="travel_insurance"
                value={formData.travel_insurance}
                onChange={handleChange}
                placeholder="Enter travel insurance amount"
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-6 mb-3 mt-2 mt-md-0">
              <label htmlFor="visaFee" className=" fs-6 fw-bold mb-2 required">
                Visa Fee
              </label>
              <input
                type="number" // Change to type "number"
                className="form-control"
                id="visaFee"
                name="visaFee"
                value={formData.visaFee}
                onChange={handleChange}
                placeholder="Enter visa fee"
              />
            </div>
            <div className="col-md-6 mb-3 mt-2 mt-md-0">
              <label
                htmlFor="conciergeFee"
                className=" fs-6 fw-bold mb-2 required"
              >
                Concierge Fee
              </label>
              <input
                type="number" // Change to type "number"
                className="form-control"
                id="conciergeFee"
                name="conciergeFee"
                value={formData.conciergeFee}
                onChange={handleChange}
                placeholder="Enter concierge fee"
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-6 mb-3 mt-2 mt-md-0">
              <label
                htmlFor="excoFeeFullBoard"
                className=" fs-6 fw-bold mb-2 required"
              >
                Exco Fee (Full Board)
              </label>
              <input
                type="number" // Change to type "number"
                className="form-control"
                id="excoFeeFullBoard"
                name="excoFeeFullBoard"
                value={formData.excoFeeFullBoard}
                onChange={handleChange}
                placeholder="Enter Exco fee (Full Board)"
              />
            </div>
            <div className="col-md-6 mb-3 mt-2 mt-md-0">
              <label
                htmlFor="excoFeeHalfBoard"
                className=" fs-6 fw-bold mb-2 required"
              >
                Exco Fee (Half Board)
              </label>
              <input
                type="number" // Change to type "number"
                className="form-control"
                id="excoFeeHalfBoard"
                name="excoFeeHalfBoard"
                value={formData.excoFeeHalfBoard}
                onChange={handleChange}
                placeholder="Enter Exco fee (Half Board)"
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-6 mb-3 mt-2 mt-md-0">
              <label
                htmlFor="excoFeeDocReview"
                className=" fs-6 fw-bold mb-2 required"
              >
                Exco Fee (Doc Review)
              </label>
              <input
                type="number" // Change to type "number"
                className="form-control"
                id="excoFeeDocReview"
                name="excoFeeDocReview"
                value={formData.excoFeeDocReview}
                onChange={handleChange}
                placeholder="Enter Exco fee (Doc Review)"
              />
            </div>
            <div className="col-md-6 mb-3 mt-2 mt-md-0">
              <label
                htmlFor="excoFeeFree"
                className=" fs-6 fw-bold mb-2 required"
              >
                Exco Fee (Free)
              </label>
              <input
                type="number" // Change to type "number"
                className="form-control"
                id="excoFeeFree"
                name="excoFeeFree"
                value={formData.excoFeeFree}
                onChange={handleChange}
                placeholder="Enter Exco fee (Free)"
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-6 mb-3 mt-2 mt-md-0">
              <label htmlFor="service_fee" className=" fs-6 fw-bold mb-2 ">
                Service Fee
              </label>
              <input
                type="number" // Change to type "number"
                className="form-control"
                id="service_fee"
                name="service_fee"
                value={formData.service_fee}
                onChange={handleChange}
                placeholder="Service Fee"
              />
            </div>
            <div className="col-md-6 mb-3 mt-2 mt-md-0">
              <label htmlFor="appointmentfee" className=" fs-6 fw-bold mb-2 ">
                Appointment Fee
              </label>
              <input
                type="number" // Change to type "number"
                className="form-control"
                id="appointmentfee"
                name="appointmentfee"
                value={formData.appointmentfee}
                onChange={handleChange}
                placeholder="Appointment Fee"
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-6 mb-3 mt-2 mt-md-0">
              <label htmlFor="embassyfee" className=" fs-6 fw-bold mb-2 ">
                Embassy Fee
              </label>
              <input
                type="number" // Change to type "number"
                className="form-control"
                id="embassyfee"
                name="embassyfee"
                value={formData.embassyfee}
                onChange={handleChange}
                placeholder="Embassy Fee"
              />
            </div>
            <div className="col-md-6 mb-3 mt-2 mt-md-0">
              <label
                htmlFor="externalagencyfee"
                className=" fs-6 fw-bold mb-2 "
              >
                External Agency Fee
              </label>
              <input
                type="number" // Change to type "number"
                className="form-control"
                id="externalagencyfee"
                name="externalagencyfee"
                value={formData.externalagencyfee}
                onChange={handleChange}
                placeholder="External Agency Fee"
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-6 mb-3 mt-2 mt-md-0">
              <label htmlFor="courierfee" className=" fs-6 fw-bold mb-2 ">
                Courier Fee
              </label>
              <input
                type="number" // Change to type "number"
                className="form-control"
                id="courierfee"
                name="courierfee"
                value={formData.courierfee}
                onChange={handleChange}
                placeholder="Courier Fee"
              />
            </div>
            <div className="col-md-6 mb-3 mt-2 mt-md-0">
              <label htmlFor="miscellaneousfee" className=" fs-6 fw-bold mb-2 ">
                Miscellaneous Fee
              </label>
              <input
                type="number" // Change to type "number"
                className="form-control"
                id="miscellaneousfee"
                name="miscellaneousfee"
                value={formData.miscellaneousfee}
                onChange={handleChange}
                placeholder="Miscellaneous Fee"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-light fs-7" onClick={onHide}>
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={onSubmit}
          disabled={sending}
        >
          {sending ? (
            <span>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </Modal>
  );
}
