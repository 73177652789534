import React, { useEffect, useState } from "react";
import { fetchData } from "../../../config/service";
import toast from "react-hot-toast";
import {
  Button,
  message,
  Popover,
  Select,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { LIMITS, routesList } from "../../../config";
import { Loader } from "../../../components";

import NotesDrawer from "./drawer/notes";
import { Link } from "react-router-dom";
import UserDetailModal from "./modals/user-detail";
import dayjs from "dayjs";
import WhatsappTemplateModal from "../../customers/modals/whatsapp-template";
import DebouncedSelect from "../../../components/debounced-select";
import MakeCallModal from "../../../components/make-call-modal";
import PaymentDrawer from "../bookings-page/drawer/payment";
import OnboardingDetail from "./modals/onboarding-detail";

const cardData = [
  {
    id: 1,
    rating: "4.5",
    name: "Patric Watson",
    status: "Paid on",
    edition: "BHX Bali 8",
    dueDate: "May 17, 2024",
  },
  {
    id: 2,
    rating: "4.5",
    name: "Patric Watson",
    status: "Paid on",
    edition: "BHX Bali 8",
    dueDate: "May 17, 2024",
  },
  // Add more card objects as needed
];

export default function OnboardingCalls() {
  const [activeTab, setActiveTab] = useState(1);

  const [user_data, set_user_data] = useState({});
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [data, setData] = useState([]);
  const [editionsList, setEditionsList] = useState([]);
  const [filterData, setFilterData] = useState({
    // edition: "66e947c95d2d24c2274b73e1",
    edition: null,
  });

  const [count, setCount] = useState({ onBoardingCalls: 0, completedCalls: 0 });

  const [showCallsModal, setShowCallsModal] = useState(false);
  const [showNotesDrawer, setShowNotesDrawer] = useState(false);
  const [showUserDetailsModal, setShowUserDetailsModal] = useState(false);
  const [notesOptions, setNotesOptions] = useState([]);
  const [whatsappTemplateModal, setWhatsAppTemplateModal] = useState(false);
  const [isMakeCallModalOpen, setIsMakeCallModalOpen] = useState(false);
  const [paymentDrawer, setPaymentDrawer] = useState(false);
  const [selectedDrawerData, setSelectedDrawerData] = useState(null);
  const [loadeMoredata, setLoadeMoredata] = useState(false);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  useEffect(() => {
    getNotesOptions();
    // getEditions();
  }, []);

  useEffect(() => {
    getData();
  }, [limit, activePage, filterData, activeTab]);

  const getEditions = () => {
    fetchData(`api/editions/editions-select/`, "get").then((res) => {
      if (res.success) {
        const data = res.data.map((e) => {
          return { label: e.name, value: e.id };
        });
        setEditionsList(data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getNotesOptions = () => {
    fetchData(`api/note/notes-enum/`, "get").then((res) => {
      if (res.success) {
        setNotesOptions(res["note_choices"]);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getData = () => {
    if (!data?.length > 0 || activePage == 1) {
      setLoading(true);
    }

    const offsetValue = (activePage - 1) * limit;

    fetchData(
      `api/booking/bookings-onboarding-call-list/?limit=${limit}&offset=${offsetValue}&edition_id=${
        filterData?.edition || ""
      }&is_on_boarding=${activeTab === 1 ? "" : true}`,
      "get"
    ).then((res) => {
      setLoading(false);
      setLoadeMoredata(false);
      if (res.success) {
        const countValue = res.count;

        if (offsetValue >= 10) {
          setData((prevData) => [...prevData, ...res.data]);
        } else {
          setData(res.data);
        }

        // setData(res.data);
        setCount((prevCount) => ({
          ...prevCount,
          onBoardingCalls: activeTab === 1 ? countValue : null,
          completedCalls: activeTab === 1 ? null : countValue,
        }));
        setTotalRecords(res.count);
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleCallStatusUpdate = async (id) => {
    message.loading("Please wait...");

    const body = {
      booking_id: id,
      only_booking: true,
      unsure_coming: false,
      // note_type: 5, // for onboarding
    };

    fetchData(`api/note/`, "post", body).then((res) => {
      toast.dismiss();
      console.log(res, "res");
      if (res.success) {
        setLoading(true);
        getData();
        toast.success("Record updated successfully");
      } else {
        toast.error(res.message);
      }
    });
  };
  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;
      const bottom = Math.abs(scrollHeight - (scrollTop + clientHeight)) < 1.5;
      const middle = scrollTop + clientHeight >= scrollHeight / 2;

      // console.log(scrollTop, clientHeight, scrollHeight, "scrollTop");

      // console.log(bottom, "bottom");

      // console.log(
      //   bottom || middle,
      //   !loadeMoredata && data?.length < totalRecords,
      //   data?.length,
      //   totalRecords,
      //   "bottom"
      // );

      if ((bottom || middle) && !loadeMoredata && data?.length < totalRecords) {
        setLoadeMoredata(true);
        setActivePage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [data]);

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    const bottom = Math.abs(scrollHeight - (scrollTop + clientHeight)) < 1.5;
    const middle = scrollTop + clientHeight >= scrollHeight / 2;
    // console.log(bottom, "bottom");

    console.log(scrollTop, clientHeight, scrollHeight, "scrollTop");

    if (middle && !loadeMoredata && data?.length < totalRecords) {
      setLoadeMoredata(true);
      setActivePage((prevPage) => prevPage + 1);
      // setLimit((prev) => prev + 10);
    }
  };

  const tabs = [
    {
      id: 1,
      title: "Onboarding Calls",
      content: "Content for Onboarding Calls - 3 calls to be done.",
      description: `Total calls to be done - ${count?.onBoardingCalls || 0}`,
    },
    {
      id: 2,
      title: "Calls Done",
      content: "Content for Calls Done - 1733 users onboarded.",
      description: `User onboarded - ${count?.completedCalls || 0}`,
    },
  ];

  return (
    <div>
      {/*begin::Followers toolbar*/}
      <div className="d-flex flex-wrap flex-stack  mb-6 ">
        <ul className="nav nav-tabs nav-pills flex-row  border-0 flex-md-row me-5 mb-3 mb-md-0 fs-6 min-w-lg-200px">
          {tabs.map((tab) => (
            <li key={tab.id} className="nav-item me-0 mb-md-0 me-4">
              <div
                className={`nav-link px-10 btn btn-flex btn-active-primary btn-color-gray-800 btn-active-color-white ${
                  activeTab === tab.id ? "active" : ""
                }`}
                onClick={() => handleTabClick(tab.id)}
              >
                <span className="d-flex flex-column align-items-start">
                  <span className="fs-4 fw-bold">{tab.title}</span>
                  <span className="fs-7">{tab.description}</span>
                </span>
              </div>
            </li>
          ))}
        </ul>

        {/*begin::Controls*/}
        <div className="">
          {/*begin::Select*/}
          {/* <Select
            value={filterData?.edition}
            onChange={(value) => {
              setFilterData((prevData) => ({
                ...prevData,
                edition: value,
              }));
            }}
            placeholder="Select Edition"
            style={{ width: 200 }}
            options={editionsList}
            allowClear
            size="large"
          /> */}

          <DebouncedSelect
            apiEndpoint="api/editions/editions-select/"
            value={filterData?.edition}
            onChange={(value) => {
              setFilterData((prevState) => ({
                ...prevState,
                edition: value,
              }));
              setActivePage(1);
            }}
            placeholder="Select Edition"
            labelFormatter={(item) => item.ip}
            size="large"
            style={{ width: 200 }}
            allowClear
            onClear={() => {
              setFilterData((prevState) => ({
                ...prevState,
                edition: null,
              }));
              setActivePage(1);
            }}
          />
          {/*end::Select*/}
        </div>
        {/*end::Controls*/}
      </div>
      {/*end::Followers toolbar*/}

      <div
        className="tab-content"
        style={{
          maxHeight: "1200px",
          overflow: "hidden",
          // height: "1300px",
          // overflow: "scroll",
          // marginBottom: "16px",
          // marginTop: "16px",
        }}
      >
        {(activeTab === 1 || activeTab === 2) && (
          <div
            style={{
              maxHeight: "1200px",
              overflowY: "scroll",
              marginBottom: "16px",
              marginTop: "16px",
            }}
            onScroll={handleScroll}
          >
            <div className="row g-5 mb-0 gx-md-10">
              {loading ? (
                <div className="my-10">
                  <Loader cols={7} />
                </div>
              ) : (
                <>
                  {data?.length > 0 ? (
                    <>
                      {data?.map((card) => (
                        <div className="col-md-6 col-xxl-4" key={card.id}>
                          {/*begin::Card*/}
                          <div className="card hover-elevate-up shadow-sm parent-hover">
                            <div className="card-body p-5">
                              <div className="d-flex flex-stack flex-row">
                                <div className="d-flex align-items-center">
                                  <div className="symbol symbol-50px me-5">
                                    <div
                                      title="score"
                                      className="symbol-label fs-5 fw-semibold bg-light-primary text-primary"
                                    >
                                      {card.user?.score === null
                                        ? "-"
                                        : card.user?.score}
                                    </div>
                                  </div>
                                  <div>
                                    <Link
                                      to={`${routesList[
                                        "customer_details"
                                      ].path.replace(":id", card.user.uuid)}`}
                                      className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0"
                                    >
                                      {card.user.first_name +
                                        " " +
                                        card?.user.last_name}
                                    </Link>
                                    <div
                                      className="fw-semibold text-primary mb-0 cursor-pointer"
                                      onClick={() => {
                                        console.log(card, "card");
                                        setSelectedDrawerData({
                                          edition: card?.edition,
                                          user: card?.user,
                                          // ...item,
                                        });
                                        setPaymentDrawer(!paymentDrawer);
                                      }}
                                    >
                                      {card.status_labels || "-"}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="separator separator-dashed border-gray-300 my-4" />
                              <div>
                                <div className="row g-1">
                                  <div className="col-md-6 d-flex align-items-center">
                                    <span className="fw-semibold text-gray-800 fs-7 me-2">
                                      Edition:
                                    </span>
                                    <Tooltip title={card.edition_name || "-"}>
                                      <Typography.Text
                                        ellipsis={true}
                                        className="fw-bolder d-inline-block text-truncate"
                                        style={{ maxWidth: "150px" }} // Adjust maxWidth as needed
                                      >
                                        {card.edition_name || "-"}
                                      </Typography.Text>
                                    </Tooltip>
                                  </div>

                                  <div className="col-md-6">
                                    <span className="fw-semibold text-gray-800 fs-7">
                                      Due on:{" "}
                                      <span className="fw-bolder">
                                        {card.latest_due_on
                                          ? dayjs(card.latest_due_on).format(
                                              "DD/MM/YYYY"
                                            )
                                          : "-"}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="separator separator-dashed border-gray-300 my-4" />
                              <div className="d-flex flex-stack">
                                <div className="d-flex flex-shrink-0 justify-content-end">
                                  {/* <div
                                  className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                  title="Flight Ticket Details"
                                >
                              
                                  <i
                                    className={` ki-airplane fs-2 ${
                                      card?.is_flight_ticket
                                        ? "ki-solid text-success"
                                        : "ki-outline text-gray-600"
                                    }`}
                                  />
                                </div> */}

                                  <div
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_2"
                                    title="Call User"
                                    className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                    onClick={() => {
                                      set_user_data(card);
                                      setShowCallsModal(!showCallsModal);
                                    }}
                                  >
                                    <i class="ki-outline ki-book fs-2 text-gray-600"></i>
                                  </div>

                                  <div className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3">
                                    <i
                                      className="bi bi-telephone fs-4 text-info"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        set_user_data(card);
                                        setIsMakeCallModalOpen(true);
                                      }}
                                    />
                                  </div>

                                  <div
                                    id="kt_drawer_example_toggle"
                                    title="View Notes"
                                    className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                    onClick={() => {
                                      set_user_data(card);

                                      setShowNotesDrawer(!showNotesDrawer);
                                    }}
                                  >
                                    {/* card.is_read_love_note */}
                                    <i className="ki-outline ki-notepad fs-2 text-gray-800" />
                                  </div>

                                  <a
                                    className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                    onClick={() => {
                                      handleCallStatusUpdate(card.id);
                                    }}
                                    style={{
                                      cursor:
                                        card.call_status == 2
                                          ? "not-allowed"
                                          : "pointer",
                                      display:
                                        activeTab == 2 ? "none" : "block",
                                    }}
                                  >
                                    {card.call_status == 2 ? (
                                      <Tooltip title="Call Done">
                                        <i className="ki-solid ki-check-circle fs-2 text-success mt-2" />
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="Click to Mark Call Done">
                                        <i className="ki-outline ki-check-circle fs-2 text-success mt-2" />
                                      </Tooltip>
                                    )}
                                  </a>

                                  <div
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_3"
                                    className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                    onClick={() => {
                                      set_user_data(card);
                                      setShowUserDetailsModal(!showCallsModal);
                                    }}
                                    title="View User Details"
                                  >
                                    <i className="ki-outline ki-user fs-2 text-warning" />
                                  </div>

                                  {/* <a
                              data-bs-toggle="modal"
                              data-bs-target="#kt_modal_3"
                              href="#"
                              className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                             
                            >
                              <i className="fa-solid fa-venus-mars fs-4 text-warning" />
                            </a> */}

                                  <div>
                                    {card?.user.phone && card?.edition ? (
                                      <Popover
                                        content={
                                          <>
                                            <div>
                                              <Button
                                                type="link"
                                                className="text-dark fw-bold"
                                                onClick={() => {
                                                  setWhatsAppTemplateModal(
                                                    true
                                                  );
                                                  set_user_data(card);
                                                }}
                                              >
                                                WhatsApp Template
                                              </Button>
                                              <br />
                                              <Button
                                                type="link"
                                                className="text-dark fw-bold"
                                                href={`https://wa.me/${card?.user?.phone}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                Open in WhatsApp
                                              </Button>
                                            </div>
                                          </>
                                        }
                                        trigger="hover"
                                      >
                                        <div className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3">
                                          <i
                                            className="bi bi-whatsapp fs-4 text-success"
                                            style={{ cursor: "pointer" }}
                                          />
                                        </div>
                                      </Popover>
                                    ) : (
                                      <div
                                        className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                        disabled
                                        style={{
                                          cursor: "not-allowed",
                                          opacity: "0.5",
                                        }}
                                      >
                                        <i className="bi bi-whatsapp fs-2 text-success" />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*begin::Card body*/}
                          </div>
                          {/*end::Card*/}
                        </div>
                      ))}
                    </>
                  ) : (
                    <div
                      className="row g-5 mb-0 gx-md-10 "
                      style={{ minHeight: "600px" }}
                    >
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className=" col-md-12 text-center">
                          <div className="mt-0">
                            <img
                              src="/media/no-data-found.png"
                              className="mw-25"
                              alt="No Data Found"
                            />
                          </div>
                          <h1 className="mt-4">No Data Found</h1>
                        </div>
                      </div>
                    </div>
                  )}

                  {loadeMoredata && (
                    <div style={{ textAlign: "center", padding: "20px" }}>
                      <Spin />
                    </div>
                  )}

                  {data?.length > 0 && (
                    <>
                      {data?.length >= totalRecords && !loadeMoredata && (
                        <div className="fs-1 text-center text-muted mt-">
                          You've reached the end of the list
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>

      {showCallsModal && (
        <OnboardingDetail
          show={showCallsModal}
          onHide={() => {
            set_user_data({});
            setShowCallsModal(false);
          }}
          onSuccess={() => {
            setLoading(true);
            getData();
          }}
          data={user_data}
        />
      )}

      {showUserDetailsModal && (
        <UserDetailModal
          show={showUserDetailsModal}
          onHide={() => {
            // set_user_data({});
            setShowUserDetailsModal(false);
            // setLoading(true);
            // getData();
          }}
          data={user_data}
        />
      )}

      {showNotesDrawer && (
        <NotesDrawer
          show={showNotesDrawer}
          onHide={() => {
            // set_user_data({});
            setShowNotesDrawer(false);
            // setLoading(true);
            // getData();
          }}
          data={user_data}
          notesOptions={notesOptions}
        />
      )}

      {whatsappTemplateModal && (
        <WhatsappTemplateModal
          show={whatsappTemplateModal}
          onHide={() => {
            setWhatsAppTemplateModal(false);
          }}
          data={{
            user_data: user_data?.user,
            edition: user_data?.edition,
          }}
        />
      )}

      {isMakeCallModalOpen && (
        <MakeCallModal
          show={isMakeCallModalOpen}
          onHide={() => {
            setIsMakeCallModalOpen(false);
          }}
          data={{
            to_number: user_data?.user?.phone,
          }}
        />
      )}

      {paymentDrawer && (
        <PaymentDrawer
          show={paymentDrawer}
          onHide={() => {
            setPaymentDrawer(false);
            // setLoading(true);
            // getData();
          }}
          data={selectedDrawerData}
        />
      )}
    </div>
  );
}
