import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { DatePicker, Select, Checkbox, Input } from "antd";

import { fetchData } from "../../../../config/service";
import dayjs from "dayjs";

const { Option } = Select;
export default function OnboardingDetail({
  show,
  statusList,
  onHide,
  onSuccess,
  data,
}) {
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");

  const [formData, setFormData] = useState({
    coming: null,
    is_read_love_note: false,
    love_note_reason: "",
    is_join_whatsapp_group: false,
    latest_payment_due_date: null,
    payment_status: null,
    is_passport_valid: false,
    visa_status: null,
    is_booked_flight: false,
    travelling_with_partner: false,
    is_onboarding: false,
    sharing_room: false,
    single_room: false,
    booking_flight: null,
  });

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    if (show) {
      getOnboardingData();
    }
  }, [show]);

  const getOnboardingData = () => {
    setLoading(true);
    fetchData(
      `api/booking/add-onboarding-call-details/?booking_id=${data?.id}`
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        if (res?.data.length > 0) {
          setFormData(res.data[0]);
        } else {
          setFormData({
            coming: null,
            is_read_love_note: false,
            love_note_reason: "",
            is_join_whatsapp_group: false,
            latest_payment_due_date: null,
            payment_status: null,
            is_passport_valid: false,
            visa_status: null,
            is_booked_flight: false,
            travelling_with_partner: false,
            is_onboarding: false,
            booking_flight: null,
          });
        }
      } else {
        toast.error(res.message);
      }
    });
  };

  const onSubmitUser = async () => {
    const body = {
      ...formData,
      booking: data?.id,
      coming: formData.coming,
      latest_payment_due_date: formData.latest_payment_due_date
        ? dayjs(formData.latest_payment_due_date).format("YYYY-MM-DD HH:mm:ss")
        : "",
      travelling_with_partner: formData.travelling_with_partner ? 1 : 0,
      edition: data?.edition,
    };

    setSending(true);

    fetchData(
      `customer/api/booking/add-onboarding-call-details/`,
      "post",
      body
    ).then((res) => {
      toast.remove();
      setSending(false);
      if (res.success) {
        onHide();
        onSuccess();
        toast.success("Success");
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleChange = (field, value) => {
    if (field === "single_room") {
      setFormData({
        ...formData,
        [field]: value,
        sharing_room: false,
      });
    } else if (field === "sharing_room") {
      setFormData({
        ...formData,
        [field]: value,
        single_room: false,
      });
    } else {
      setFormData({ ...formData, [field]: value });
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3 className="modal-title">
            {data?.user?.first_name + " " + data?.user?.last_name}
          </h3>
          <div className="fs-7">
            Phone:{" "}
            <a href="" className="fw-bold">
              {data?.user?.phone || "-"}
            </a>
          </div>
          <div className="fs-7">
            City:{" "}
            <a href="" className="fw-bold">
              {data?.user?.user_profile?.city_name || "-"}
            </a>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row g-5 mb-5 align-items-center">
          <div className="col-md-12  mt-5">
            <label className="form-label fw-semibold">Onboarding Coming</label>
            <div className="me-3">
              <Select
                value={formData.coming}
                onChange={(value) => handleChange("coming", value)}
                style={{ width: "100%" }}
                dropdownStyle={{ zIndex: 1111 }}
                size="large"
                placeholder="Enter Onboarding Coming"
                options={[
                  { value: 1, label: "Coming" },
                  { value: 2, label: "Need Time" },
                  { value: 3, label: "Not sure" },
                  { value: 4, label: "Not Coming" },
                ]}
              ></Select>
            </div>
          </div>

          <div className="col-md-12 fv-row">
            <div>
              <label htmlFor="" className="fs-6 fw-semibold mb-2">
                Second Payment Due Date
              </label>
              <DatePicker
                value={
                  formData.latest_payment_due_date
                    ? dayjs(formData.latest_payment_due_date)
                    : null
                }
                onChange={(date) =>
                  handleChange("latest_payment_due_date", date)
                }
                style={{ width: "100%" }}
                size="large"
              />
            </div>
          </div>

          <div className="col-md-6 fv-row">
            <div>
              <label className="form-label fw-semibold"> Payment Status</label>
              <Select
                value={formData.payment_status}
                onChange={(value) => handleChange("payment_status", value)}
                style={{ width: "100%" }}
                dropdownStyle={{ zIndex: 1111 }}
                size="large"
                placeholder="Payment Status"
                options={[
                  { value: 1, label: "Doing" },
                  { value: 2, label: "Will Do" },
                  { value: 3, label: "Not Doing" },
                  { value: 4, label: "Done" },
                  { value: 5, label: "Payment extension" },
                ]}
              ></Select>
            </div>
          </div>

          <div className="col-md-6 ">
            <div>
              <label className="form-label fw-semibold"> Visa Status</label>
              <Select
                value={formData.visa_status}
                onChange={(value) => handleChange("visa_status", value)}
                style={{ width: "100%" }}
                size="large"
                placeholder="Visa Status"
                dropdownStyle={{ zIndex: 1111 }}
                options={[
                  { value: 1, label: "Applied,yet to get" },
                  { value: 2, label: "Not yet Applied" },
                  { value: 3, label: "It's Complicated" },
                  { value: 4, label: "Have it already" },
                ]}
              ></Select>
            </div>
          </div>
          <div className="col-md-12 fv-row">
            <label htmlFor="" className="fs-6 fw-semibold mb-2">
              Booking Flight
            </label>
            <input
              type="text"
              className="form-control fs-7"
              placeholder="Booking Flight"
              value={formData?.booking_flight}
              onChange={(e) => handleChange("booking_flight", e.target.value)}
            />
          </div>

          <div className="col-md-12 fv-row">
            <label htmlFor="" className="fs-6 fw-semibold mb-2">
              Love Note Reason
            </label>
            <textarea
              className="form-control fs-7"
              placeholder="Love Note Reason"
              cols={30}
              rows={5}
              value={formData?.love_note_reason}
              onChange={(e) => handleChange("love_note_reason", e.target.value)}
            />
          </div>

          <div className="col-md-12 mt-5">
            <div className="me-3">
              <Checkbox
                checked={formData.is_join_whatsapp_group}
                onChange={(e) =>
                  handleChange("is_join_whatsapp_group", e.target.checked)
                }
              >
                Join WhatsApp Group
              </Checkbox>
            </div>
          </div>

          <div className="col-md-12  mt-5">
            <div>
              <Checkbox
                checked={formData.is_read_love_note}
                onChange={(e) =>
                  handleChange("is_read_love_note", e.target.checked)
                }
              >
                Read Love Notes
              </Checkbox>
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <Checkbox
                checked={formData.is_passport_valid}
                onChange={(e) =>
                  handleChange("is_passport_valid", e.target.checked)
                }
              >
                Passport is Valid
              </Checkbox>
            </div>
          </div>

          {/* <div className="col-md-12">
            <div>
              <Checkbox
                checked={formData.is_booked_flight}
                onChange={(e) =>
                  handleChange("is_booked_flight", e.target.checked)
                }
              >
                Booked Flight
              </Checkbox>
            </div>
          </div> */}

          <div className="col-md-12">
            <Checkbox
              checked={formData.travelling_with_partner}
              onChange={(e) =>
                handleChange("travelling_with_partner", e.target.checked)
              }
            >
              Travelling with a Partner
            </Checkbox>
          </div>

          <div className="col-md-12">
            <Checkbox
              checked={formData.is_onboarding}
              onChange={(e) => handleChange("is_onboarding", e.target.checked)}
            >
              onboarding
            </Checkbox>
          </div>

          <div className="col-md-12">
            <Checkbox
              checked={formData.sharing_room}
              onChange={(e) => handleChange("sharing_room", e.target.checked)}
            >
              Sharing Room
            </Checkbox>
          </div>

          <div className="col-md-12">
            <Checkbox
              checked={formData.single_room}
              onChange={(e) => handleChange("single_room", e.target.checked)}
            >
              Single Room
            </Checkbox>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => {
            onSubmitUser();
          }}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
