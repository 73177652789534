import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../config/service";
import { Select } from "antd";

export default function CreditAmountModal({ show, onHide, data, onSuccess }) {
  const [sending, setSending] = useState(false);
  const [customerData, setCustomerData] = useState({
    amount: null,
    comment: "",
  });

  const onSubmitUser = async () => {
    setSending(true);

    const body = {
      user: data?.user?.uuid,
      amount: Number(customerData.amount),
      edition: data?.edition,
      booking: data?.id,
      comment: customerData.comment,
    };

    fetchData(`api/booking/credit-wallet/`, "post", body).then((res) => {
      setSending(false);
      if (res.success) {
        onHide();
        onSuccess();
        setCustomerData({
          amount: null,
          comment: "",
        });
        toast.success("Amount credited successfully!");
      } else {
        toast.error(res.message);
      }
    });
  };

  const setCustomerDataValue = (key, value) => {
    setCustomerData({
      ...customerData,
      [key]: value,
    });
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">
            Credit Amount <span className="text-primary"></span>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row g-9 align-items-center">
          <div className="col-12">
            <label>
              <span className="required fw-semibold fs-5">Amount</span>
            </label>
            <input
              type="number"
              className="form-control"
              placeholder="Enter Amount"
              value={customerData?.amount || ""}
              onChange={(e) => setCustomerDataValue("amount", e.target.value)}
            />
          </div>

          <div className="col-12">
            <label>
              <span className="required fw-semibold fs-5">Comment</span>
            </label>
            <textarea
              className="form-control"
              placeholder="Enter Comment"
              rows="3"
              value={customerData?.comment}
              onChange={(e) => setCustomerDataValue("comment", e.target.value)}
            ></textarea>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={onSubmitUser}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
