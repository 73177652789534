import React, { useEffect, useState } from "react";
import { Select, DatePicker, Typography } from "antd";
import { Loader, Pagination } from "../../../components";
import ReactApexChart from "react-apexcharts";
import { fetchData } from "../../../config/service";
import toast from "react-hot-toast";
import { LIMITS } from "../../../config";
import dayjs from "dayjs";
import { AppChart } from "./charts/app-chart";
import DebouncedSelect from "../../../components/debounced-select";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Title } = Typography;

const AdMetricsCard = ({ mockData }) => {
  return (
    <div className="container-fluid p-6 bg-light ">
      <div className="mb-8">
        <h2 className="text-dark fw-bolder fs-1">Performance Dashboard</h2>
        <div className="d-flex align-items-center">
          <i className="ki-outline ki-chart-line fs-2 text-primary me-2"></i>
          <p className="text-gray-600 fs-5 mb-0">
            Sales funnel and conversion metrics overview
          </p>
        </div>
      </div>

      <div className="row g-5 pb-5 flex-nowrap overflow-auto">
        {mockData.map((item, index) => (
          <div key={index} className="col-12 col-lg-6">
            <div className="card shadow-sm border-0 h-100">
              {/* Card Header with Stats Summary */}
              <div className="card-header border-0 bg-white py-5">
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                  <div className="d-flex align-items-center">
                    <i className="ki-outline ki-building fs-1 text-primary me-4"></i>
                    <div>
                      <h3 className="card-title fw-bolder text-dark fs-2 mb-2">
                        {item.title}
                      </h3>
                      <span className="text-gray-400">
                        Overall Conversion Rate:{" "}
                      </span>
                      <span className="text-primary fw-bold fs-4">
                        {item.stats.convRatio}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Main Metrics Cards */}
              <div className="card-body pt-0">
                <div className="row g-4">
                  {/* Leads Card */}
                  <div className="col-md-6">
                    <div className="card border-0 bg-light-primary h-100">
                      <div className="card-body p-5">
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center mb-4">
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label bg-white">
                                <i className="ki-outline ki-abstract-26 fs-1 text-primary"></i>
                              </div>
                            </div>
                            <div>
                              <div className="fs-5 text-gray-600 fw-semibold">
                                Total Leads
                              </div>
                              <div className="fs-2 fw-bold text-gray-800">
                                {item.lead}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="bullet bullet-vertical h-40px bg-primary me-3"></span>
                            <div className="fs-6">
                              <span className="text-gray-500">First Stage</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Applications Card */}
                  <div className="col-md-6">
                    <div className="card border-0 bg-light-info h-100">
                      <div className="card-body p-5">
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center mb-4">
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label bg-white">
                                <i className="ki-outline ki-document fs-1 text-info"></i>
                              </div>
                            </div>
                            <div>
                              <div className="fs-5 text-gray-600 fw-semibold">
                                Applications
                              </div>
                              <div className="fs-2 fw-bold text-gray-800">
                                {item.applied}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <span className="bullet bullet-vertical h-40px bg-info me-3"></span>
                              <div className="fs-6">
                                <span className="text-gray-500">
                                  Lead to App
                                </span>
                              </div>
                            </div>
                            <span className="badge badge-light-info fs-6">
                              {item.stats.leadToApp}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Approved Card */}
                  <div className="col-md-6">
                    <div className="card border-0 bg-light-success h-100">
                      <div className="card-body p-5">
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center mb-4">
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label bg-white">
                                <i className="ki-outline ki-check fs-1 text-success"></i>
                              </div>
                            </div>
                            <div>
                              <div className="fs-5 text-gray-600 fw-semibold">
                                Approved
                              </div>
                              <div className="fs-2 fw-bold text-gray-800">
                                {item.approved}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <span className="bullet bullet-vertical h-40px bg-success me-3"></span>
                              <div className="fs-6">
                                <span className="text-gray-500">
                                  Approval Rate
                                </span>
                              </div>
                            </div>
                            <span className="badge badge-light-success fs-6">
                              {item.stats.curatedToApproved}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Sales Card */}
                  <div className="col-md-6">
                    <div className="card border-0 bg-light-warning h-100">
                      <div className="card-body p-5">
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center mb-4">
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label bg-white">
                                <i className="ki-outline ki-dollar fs-1 text-warning"></i>
                              </div>
                            </div>
                            <div>
                              <div className="fs-5 text-gray-600 fw-semibold">
                                Tickets Sold
                              </div>
                              <div className="fs-2 fw-bold text-gray-800">
                                {item.ticketsSold}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <span className="bullet bullet-vertical h-40px bg-warning me-3"></span>
                              <div className="fs-6">
                                <span className="text-gray-500">
                                  Close Rate
                                </span>
                              </div>
                            </div>
                            <span className="badge badge-light-warning fs-6">
                              {item.stats.approvedToSale}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Funnel Stats Redesigned with Total Curated */}
                <div className="row mt-8">
                  <div className="col-12">
                    <div className="card ">
                      <div className="card-body p-5">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                          <div className="d-flex align-items-center">
                            <i className="ki-outline ki-funnel fs-2 me-2 text-primary"></i>
                            <h4 className="fw-bold text-dark mb-0">
                              Funnel Analysis
                            </h4>
                          </div>
                          <div className="bg-light-primary rounded mt-3 mt-lg-0 px-4 py-2">
                            <span className="text-gray-600 fs-6 me-2">
                              Total Curated:
                            </span>
                            <span className="text-dark fw-bold fs-5">
                              {item.funnel.approved.total}
                            </span>
                          </div>
                        </div>
                        <div className="row g-5">
                          <div className="col-12">
                            <div className="d-flex align-items-center justify-content-between  rounded py-3 mb-4">
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-40px me-3">
                                  <div className="symbol-label bg-success">
                                    <i className="ki-outline ki-check text-inverse-success fs-1"></i>
                                  </div>
                                </div>
                                <div>
                                  <span className="fs-6 text-gray-600">
                                    Approved Applications
                                  </span>
                                  <div className="d-flex align-items-center">
                                    <div className="fs-4 fw-bold text-dark me-2">
                                      {item.funnel.approved.count}
                                    </div>
                                    <span className="text-muted fs-7">
                                      of {item.funnel.approved.total}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <span className="badge badge-success fs-5">
                                {item.funnel.approved.ratio}
                              </span>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="d-flex align-items-center justify-content-between rounded">
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-40px me-3">
                                  <div className="symbol-label bg-danger">
                                    <i className="ki-outline ki-cross text-inverse-danger fs-1"></i>
                                  </div>
                                </div>
                                <div>
                                  <span className="fs-6 text-gray-600">
                                    Rejected Applications
                                  </span>
                                  <div className="d-flex align-items-center">
                                    <div className="fs-4 fw-bold text-dark me-2">
                                      {item.funnel.rejected.count}
                                    </div>
                                    <span className="text-muted fs-7">
                                      of {item.funnel.rejected.total}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <span className="badge badge-danger fs-5">
                                {item.funnel.rejected.ratio}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const AdMetrics = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [filterData, setFilterData] = useState({
    edition: null,
    start_date: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
    end_date: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  const [tableFilterData, setTableFilterData] = useState({
    status: null,
    project: null,
  });
  const [chartFilterData, setChartFilterData] = useState({ edition: null });
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);

  const [openEditionsLoading, setOpenEditionsLoading] = useState(false);
  const [openEditionsData, setOpenEditionsData] = useState([]);

  const [applicationChartLoading, setApplicationChartLoading] = useState(false);
  const [applicationChartData, setApplicationChartData] = useState([]);

  const [applicationChartLeadLoading, setApplicationChartLeadLoading] =
    useState(false);
  const [applicationChartLeadData, setApplicationChartLeadData] = useState([]);

  const [editionsStatusOptions, setEditionsStatusOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);

  useEffect(() => {
    getEditionsStatus();
    getProjectOptions();
  }, []);

  useEffect(() => {
    getOpenEditionsData();
  }, [
    tableFilterData?.project,
    tableFilterData?.status,
    filterData?.start_date,
    filterData?.end_date,
    activePage,
    limit,
  ]);

  useEffect(() => {
    getApplicationChartData();
    getApplicationChartLeadData();
  }, [chartFilterData?.edition, filterData?.start_date, filterData?.end_date]);

  useEffect(() => {
    getData();
    // getOpenEditionsData();
  }, [filterData?.start_date, filterData?.end_date]);

  const getData = () => {
    setLoading(true);

    const editionId = filterData?.edition;

    fetchData(
      `api/business-metrics/project-metrics/?edition_id=${
        editionId || ""
      }&start_date=${filterData?.start_date || ""}&end_date=${
        filterData?.end_date || ""
      }
      `,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getApplicationChartData = () => {
    console.log("applicationChartData called");
    setApplicationChartLoading(true);

    fetchData(
      `api/business-metrics/application-chart/?start_date=${
        filterData?.start_date || ""
      }&end_date=${filterData?.end_date || ""}&edition=${
        chartFilterData?.edition || ""
      }
      `,
      "get"
    ).then((res) => {
      setApplicationChartLoading(false);
      if (res.success) {
        setApplicationChartData(res.data);
      } else {
        setApplicationChartData([]);
        toast.error(res.message);
      }
    });
  };

  const getApplicationChartLeadData = () => {
    setApplicationChartLeadLoading(true);

    fetchData(
      `api/business-metrics/application-lead-chart/?start_date=${
        filterData?.start_date || ""
      }&end_date=${filterData?.end_date || ""}&edition=${
        chartFilterData?.edition || ""
      }
      `,
      "get"
    ).then((res) => {
      setApplicationChartLeadLoading(false);
      if (res.success) {
        setApplicationChartLeadData(res.data);
      } else {
        setApplicationChartLeadData([]);
        toast.error(res.message);
      }
    });
  };

  const getOpenEditionsData = () => {
    setOpenEditionsLoading(true);
    fetchData(
      `api/business-metrics/editions/?limit=${limit}&offset=${
        (activePage - 1) * limit
      }&status=${tableFilterData?.status || ""}&project=${
        tableFilterData?.project || ""
      }&start_date=${filterData?.start_date || ""}&end_date=${
        filterData?.end_date || ""
      }`,
      "get"
    ).then((res) => {
      setOpenEditionsLoading(false);
      if (res.success) {
        setOpenEditionsData(res.data);
        setTotalRecords(res.count);
      } else {
        setOpenEditionsData([]);
        toast.error(res.message);
      }
    });
  };

  const getEditionsStatus = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}`;

    fetchData(`api/editions/editions-choices/`).then((res) => {
      if (res.success) {
        const data = res["edition_status_choices"]?.map((e) => {
          return {
            label: e?.display_name,
            value: e?.value,
          };
        });
        setEditionsStatusOptions(data);
      } else {
        setEditionsStatusOptions([]);
        toast.error(res.message);
      }
    });
  };

  const getProjectOptions = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${100}&offset=${offset}`;

    fetchData(`api/project/${param}`).then((res) => {
      if (res.success) {
        const data = res?.data.map((e) => {
          return {
            label: e?.name,
            value: e?.id,
          };
        });
        setProjectOptions(data);
      } else {
        setProjectOptions([]);
        toast.error(res.message);
      }
    });
  };

  const transformData = (data) =>
    data.map((item) => {
      const leadToApp =
        item.total_lead > 0
          ? `${((item.application / item.total_lead) * 100).toFixed(1)}%`
          : "0%";
      const curatedToApproved =
        item.total_curated > 0
          ? `${((item.approved / item.total_curated) * 100).toFixed(1)}%`
          : "0%";
      const approvedToSale =
        item.approved > 0
          ? `${((item.ticket_sold / item.approved) * 100).toFixed(1)}%`
          : "0%";

      return {
        title: item.project_name,
        lead: item.total_lead,
        applied: item.application,
        approved: item.approved,
        ticketsSold: item.ticket_sold,
        stats: {
          convRatio: `${item.conversion}%`,
          leadToApp,
          curatedToApproved,
          approvedToSale,
        },
        funnel: {
          approved: {
            count: item.approved,
            total: item.total_curated,
            ratio: curatedToApproved,
          },
          rejected: {
            count: item.rejected,
            total: item.total_curated,
            ratio:
              item.total_curated > 0
                ? `${((item.rejected / item.total_curated) * 100).toFixed(1)}%`
                : "0%",
          },
        },
      };
    });

  const newData = transformData(data);

  const rangePresets = [
    {
      label: "Last 7 Days",
      value: [dayjs().subtract(7, "days"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().subtract(30, "days"), dayjs().endOf("month")],
    },
    {
      label: "Last 90 Days",
      value: [dayjs().subtract(90, "days"), dayjs()],
    },
    {
      label: "Last 1 Year",
      value: [dayjs().subtract(1, "year"), dayjs()],
    },
  ];

  return (
    <>
      <div
        style={{
          padding: "16px",
          backgroundColor: "#f9f9f9",
          borderRadius: "8px",
        }}
      >
        {/* Title */}
        <Title level={4}>Team - Ad Metrics (ADM)</Title>

        <div className="row align-items-center flex-end mt-10 mt-lg-n10 me-5">
          <RangePicker
            value={[
              dayjs(filterData.start_date, "YYYY-MM-DD"),
              dayjs(filterData.end_date, "YYYY-MM-DD"),
            ]}
            size="large"
            presets={rangePresets}
            format="DD-MM-YYYY"
            onChange={(date, dateString) => {
              // console.log(date, dateString, "date");

              const utcDateString1 =
                date && date[0]
                  ? date[0].format("YYYY-MM-DD")
                  : dayjs().subtract(30, "days").format("YYYY-MM-DD");

              const utcDateString2 =
                date && date[1]
                  ? date[1].format("YYYY-MM-DD")
                  : dayjs().endOf("month").format("YYYY-MM-DD");

              setFilterData((prevData) => ({
                ...prevData,
                start_date: utcDateString1,
                end_date: utcDateString2,
              }));
            }}
            style={{ width: "300px" }}
          />
          {/* Time Period Dropdown */}
          {/* <div className="col-md-2">
            <Select
              placeholder="Last Quarter"
              style={{ width: "100%" }}
              allowClear
              size="large"
            >
              <Option value="lastQuarter">Last Quarter</Option>
              <Option value="lastMonth">Last Month</Option>
              <Option value="lastWeek">Last Week</Option>
              <Option value="thisQuarter">This Quarter</Option>
              <Option value="thisMonth">This Month</Option>
              <Option value="thisWeek">This Week</Option>
            </Select>
          </div> */}

          {/* Region/Type Dropdown */}
          {/* <div className="col-md-2">
            <Select defaultValue="BHX" style={{ width: "100%" }} size="large">
              <Option value="BHX">BHX</Option>
              <Option value="ABC">ABC</Option>
              <Option value="XYZ">XYZ</Option>
            </Select>
          </div> */}

          {/* Date Range Picker */}
          {/* <div className="col-md-4">
            <RangePicker style={{ width: "100%" }} size="large" />
          </div> */}

          {/* Interval Selector */}
          {/* <div className="col-md-2">
            <Select defaultValue="7" style={{ width: "100%" }} size="large">
              <Option value="1">1</Option>
              <Option value="7">7</Option>
              <Option value="30">30</Option>
            </Select>
          </div> */}
        </div>
      </div>

      {loading ? (
        <div className="row p-10 my-10">
          <Loader cols={7} width={250} />
        </div>
      ) : (
        <AdMetricsCard mockData={newData} />
      )}

      <div className="row g-5 g-xxl-10">
        <div className="col-xxl-12 ">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header">
              <div className="d-flex flex-stack flex-wrap gap-4">
                <div className="position-relative mt-4">
                  <h2>Open Editions</h2>
                </div>
              </div>
              <div className="card-toolbar gap-4">
                <div className="position-relative ">
                  {/* <DebouncedSelect
                    apiEndpoint="api/project/"
                    value={filterData?.project}
                    onChange={(value) =>
                      setTableFilterData((prev) => ({
                        ...prev,
                       project: value ,
                      }))
                    }
                    placeholder="Select Project"
                    labelFormatter={(item) => item.name}
                    size="large"
                    style={{ width: "200px" }}
                    allowClear
                    onClear={() => {
                      setTableFilterData((prev) => {
                        return { ...prev, project: null };
                      });
                    }}
                  /> */}
                  

                  <Select
                    showSearch
                    name="project"
                    value={filterData?.project}
                    onChange={(value) => {
                      setTableFilterData((prevState) => ({
                        ...prevState,
                        project: value,
                      }));
                    }}
                    placeholder="Select Project"
                    className="fs-7 w-150px "
                    style={{ width: "100%" }}
                    dropdownStyle={{ zIndex: 1100 }}
                    size="large"
                    options={projectOptions}
                    allowClear
                    onClear={() =>
                      setTableFilterData((prevState) => ({
                        ...prevState,
                        project: null,
                      }))
                    }
                  ></Select>
                </div>
                <Select
                  showSearch
                  name="status"
                  value={filterData?.status}
                  onChange={(value) => {
                    setTableFilterData((prevState) => ({
                      ...prevState,
                      status: value,
                    }));
                  }}
                  placeholder="Select Status"
                  className="fs-8 me-3 w-150px"
                  dropdownStyle={{ zIndex: 1100 }}
                  size="large"
                  options={editionsStatusOptions}
                  allowClear
                  onClear={() =>
                    setTableFilterData((prevState) => ({
                      ...prevState,
                      status: null,
                    }))
                  }
                ></Select>
              </div>
            </div>

            <div className="card-body py-2 pb-7">
              {/*begin::Table container*/}
              {openEditionsLoading ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div>
                  {openEditionsData?.length > 0 ? (
                    <>
                      <div className="table-responsive">
                        <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                          <thead>
                            <tr className="fw-bolder text-gray-900 bg-light-primary">
                              {/* Table Headers */}
                              <th className="min-w-80px">#</th>
                              <th className="min-w-150px">Name of Edition</th>
                              <th className="min-w-80px">Status</th>
                              <th className="min-w-100px">Total Apps</th>
                              <th className="min-w-100px">Total Sold</th>
                              <th className="min-w-125px">Funnel Quality</th>
                              <th className="min-w-90px">Conv%</th>
                              <th className="min-w-125px">
                                Non Curated Bookings
                              </th>
                              <th className="min-w-100px">
                                Active Invites Bookings
                              </th>
                              <th className="min-w-100px">
                                Dead Invites Bookings
                              </th>
                              <th className="min-w-100px">Drop-offs</th>
                            </tr>
                          </thead>

                          <tbody>
                            {openEditionsData?.length > 0 &&
                              openEditionsData.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td className="bdr-left-user text-center">
                                      {(activePage - 1) * limit + 1 + i}
                                    </td>
                                    <td className="fw-semibold fs-6">
                                      {item.name || "-"}
                                    </td>

                                    <td>
                                      <span
                                        className={`badge ${
                                          item.status_label === "Open"
                                            ? "badge-warning"
                                            : "badge-danger"
                                        }`}
                                      >
                                        {item.status_label}
                                      </span>
                                    </td>
                                    <td className="fw-semibold fs-6">
                                      {item?.edition_metrics["total_apps"] ||
                                        "-"}
                                    </td>

                                    <td className="fw-semibold fs-6">
                                      {item?.edition_metrics["total_sold"] ||
                                        "-"}
                                    </td>

                                    <td>
                                      {" "}
                                      {item?.edition_metrics[
                                        "funnel_quality"
                                      ] || "-"}
                                    </td>
                                    <td>
                                      {" "}
                                      {item?.edition_metrics["conversion"] ||
                                        "-"}
                                    </td>

                                    <td>
                                      {" "}
                                      {item?.edition_metrics[
                                        "non_curated_bookings"
                                      ] || "-"}
                                    </td>
                                    <td>
                                      {" "}
                                      {item?.edition_metrics[
                                        "active_invites_bookings"
                                      ] || "-"}
                                    </td>
                                    <td>
                                      {" "}
                                      {item?.edition_metrics[
                                        "dead_invites_booking"
                                      ] || "-"}
                                    </td>
                                    <td>
                                      {" "}
                                      {item?.edition_metrics["drop_offs"] ||
                                        "-"}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>

                      <div className="separator separator-dashed border-gray-200" />
                      <Pagination
                        setLimit={setLimit}
                        limit={limit}
                        data={openEditionsData}
                        activePage={activePage}
                        totalRecords={totalRecords}
                        onChange={(pageNumber) => {
                          setOpenEditionsLoading(true);
                          setActivePage(pageNumber);
                        }}
                      />
                    </>
                  ) : (
                    <div className="row g-5 mb-0 gx-md-10">
                      <div className="col-md-12 text-center">
                        <div className="mt-0">
                          <img
                            src="/media/no-data-found.png"
                            className="mw-25"
                            alt="No Data Found"
                          />
                        </div>
                        <h1 className="mt-4">No Data Found</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/*end::Pagination*/}
            </div>
          </div>
        </div>
      </div>

      <div className="row g-5 g-xxl-10">
        <div className="col-xxl-12 ">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header">
              <div className="d-flex flex-stack flex-wrap gap-4">
                <div className="position-relative mt-4">
                  <h2>Charts</h2>
                </div>
              </div>
              <div className="card-toolbar">
                <div className="col-md-4">
                  <DebouncedSelect
                    apiEndpoint="api/editions/editions-select/"
                    value={chartFilterData?.edition}
                    onChange={(value) =>
                      setChartFilterData((prev) => {
                        return { ...prev, edition: value };
                      })
                    }
                    placeholder="Select Edition"
                    labelFormatter={(item) => item.ip}
                    size="large"
                    style={{ width: "200px" }}
                    allowClear
                    onClear={() => {
                      setChartFilterData((prev) => {
                        return { ...prev, edition: null };
                      });
                    }}
                  />

                  {/* <RangePicker
                    value={[
                      dayjs(filterData.start_date, "YYYY-MM-DD"),
                      dayjs(filterData.end_date, "YYYY-MM-DD"),
                    ]}
                    presets={rangePresets}
                    format="DD-MM-YYYY"
                    onChange={(date, dateString) => {
                      // console.log(date, dateString, "date");

                      const utcDateString1 =
                        date && date[0]
                          ? date[0].format("YYYY-MM-DD")
                          : dayjs().subtract(30, "days").format("YYYY-MM-DD");

                      const utcDateString2 =
                        date && date[1]
                          ? date[1].format("YYYY-MM-DD")
                          : dayjs().endOf("month").format("YYYY-MM-DD");

                      setFilterData((prevData) => ({
                        ...prevData,
                        start_date: utcDateString1,
                        end_date: utcDateString2,
                      }));
                    }}
                    style={{ width: "300px" }}
                    size="large"
                  /> */}
                </div>
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body py-5 pb-7">
              <AppChart
                applicationChartLoading={applicationChartLoading}
                applicationChartLeadLoading={applicationChartLeadLoading}
                applicationChartData={applicationChartData}
                applicationChartLeadData={applicationChartLeadData}
              />
            </div>
            {/*begin::Body*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdMetrics;
