import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../config/service";
import { DatePicker, Image, Select } from "antd";
import DebouncedSelect from "../../../components/debounced-select";
import dayjs from "dayjs";

export default function PassportDetailsModal({
  show,
  cityList,
  onHide,
  data,
  onSuccess,
}) {
  const [sending, setSending] = useState(false);
  const [passportData, setPassportData] = useState({
    passport_number: "",
    name_on_passport: "",
    last_name: "",
    dob_on_passport: "",
    expiry_date: "",
    city: null,
  });
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [frontPreview, setFrontPreview] = useState("");
  const [backPreview, setBackPreview] = useState("");
  const frontFileInputRef = useRef(null);
  const backFileInputRef = useRef(null);

  useEffect(() => {
    if (show && data?.user?.uuid) {
      getData();
    }
  }, [show, data]);

  const getData = async () => {
    fetchData(
      `api/travel_info/passport/details-update/?user=${data.user.uuid}`
    ).then((res) => {
      setSending(false);
      if (res.success) {
        const passportDetails = res;
        setPassportData({
          passport_number: passportDetails.passport_number,
          name_on_passport: passportDetails.name_on_passport,
          last_name: passportDetails.last_name,
          dob_on_passport: passportDetails.dob_on_passport,
          expiry_date: passportDetails.expiry_date,
          city: passportDetails.city,
        });

        setFrontImage(passportDetails.passsport_front_image);
        setBackImage(passportDetails.passsport_back_image);

        setFrontPreview(passportDetails.passsport_front_image);
        setBackPreview(passportDetails.passsport_back_image);
      } else {
        toast.error(res.message);
      }
    });
  };

  const setPassportDataValue = (key, value) => {
    setPassportData({ ...passportData, [key]: value });
  };

  const handleFrontImageChange = (e) => {
    const file = e.target.files[0];

    if (file && file.size > 5 * 1024 * 1024) {
      toast.error("File size must be less than 5MB");
      if (frontFileInputRef.current) {
        frontFileInputRef.current.value = "";
      }
      return;
    }

    setFrontImage(file);
    setFrontPreview(file ? URL.createObjectURL(file) : "");
  };

  const handleBackImageChange = (e) => {
    const file = e.target.files[0];

    if (file && file.size > 5 * 1024 * 1024) {
      toast.error("File size must be less than 5MB");
      if (backFileInputRef.current) {
        backFileInputRef.current.value = "";
      }
      return;
    }

    setBackImage(file);
    setBackPreview(file ? URL.createObjectURL(file) : "");
  };

  const onSubmit = async () => {
    // if (!frontImage || !backImage) {
    //   toast.error("Please upload both front and back images.");
    //   return;
    // }

    const isURL = (str) => {
      try {
        new URL(str);
        return true;
      } catch {
        return false;
      }
    };

    setSending(true);

    const body = {
      ...passportData,
      dob_on_passport: dayjs(passportData.dob_on_passport).format("YYYY-MM-DD"),
      expiry_date: dayjs(passportData.expiry_date).format("YYYY-MM-DD"),
      ...(frontImage && isURL(frontImage)
        ? {}
        : { front_image: frontImage || "" }),
      ...(backImage && isURL(backImage) ? {} : { back_image: backImage || "" }),

      // user: data?.user?.uuid,
    };

    fetchData(
      `customer/api/travel_info/passport/details-update/?user=${data?.user?.uuid}`,
      "PATCH",
      body,
      "",
      true
    ).then((res) => {
      setSending(false);
      if (res.success) {
        onHide();
        onSuccess();
        toast.success("Passport details added successfully!");
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleRemoveFrontImage = () => {
    setFrontImage(null);
    setFrontPreview("");
    if (frontFileInputRef.current) {
      frontFileInputRef.current.value = "";
    }
  };

  const handleRemoveBackImage = () => {
    setBackImage(null);
    setBackPreview("");
    if (backFileInputRef.current) {
      backFileInputRef.current.value = "";
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">
            {data?.is_passport
              ? "Edit Passport Details"
              : "Add Passport Details"}{" "}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row g-5">
          <div className="col-6 mt-4">
            <label className="fw-semibold mb-2">
              Upload Passport Front Image
            </label>
            {!frontPreview && (
              <input
                type="file"
                ref={frontFileInputRef}
                onChange={handleFrontImageChange}
                accept=".png, .jpg, .jpeg"
                className="form-control"
              />
            )}
            {frontPreview && (
              <div className="mt-3">
                <div style={{ position: "relative" }}>
                  <Image
                    src={frontPreview}
                    alt="Front Preview"
                    className="w-100 rounded"
                    height={100}
                    width={100}
                  />
                  <i
                    title="Remove Image"
                    className="ki-outline ki-cross fs-2"
                    onClick={handleRemoveFrontImage}
                    style={{
                      position: "absolute",
                      top: "-8px",
                      left: "90px",
                      cursor: "pointer",
                      zIndex: 2,
                      backgroundColor: "white",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="col-6 mt-4">
            <label className="fw-semibold mb-2">
              Upload Passport Back Image
            </label>
            {!backPreview && (
              <input
                type="file"
                ref={backFileInputRef}
                onChange={handleBackImageChange}
                accept=".png, .jpg, .jpeg"
                className="form-control"
              />
            )}
            {backPreview && (
              <div className="mt-3">
                <div style={{ position: "relative" }}>
                  <Image
                    src={backPreview}
                    alt="Back Preview"
                    className="w-100 rounded"
                    height={100}
                    width={100}
                  />
                  <i
                    title="Remove Image"
                    className="ki-outline ki-cross fs-2"
                    onClick={handleRemoveBackImage}
                    style={{
                      position: "absolute",
                      top: "-8px",
                      left: "90px",
                      cursor: "pointer",
                      zIndex: 2,
                      backgroundColor: "white",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="col-6 mb-2">
            <label className="fw-semibold  mb-2">Passport Number</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Passport Number"
              value={passportData.passport_number}
              onChange={(e) =>
                setPassportDataValue("passport_number", e.target.value)
              }
            />
          </div>

          <div className="col-6  mb-2">
            <label className="fw-semibold  mb-2">Place of Issue (City)</label>
            <DebouncedSelect
              apiEndpoint="api/country/state/city/"
              value={passportData.city}
              onChange={(value) => setPassportDataValue("city", value)}
              placeholder="Select City"
              labelFormatter={(item) => item.name}
              size="large"
              style={{ width: "100%" }}
              dropdownStyle={{ zIndex: 1100 }}
              allowClear
              onClear={() => {
                setPassportDataValue("city", null);
              }}
              defaultSearchKey={"city_id"}
              defaultSearchValue={passportData.city}
              additionalParams={{ is_active: 1, phone_code: 91 }}
            />
          </div>

          <div className="col-6 mb-2">
            <label className="fw-semibold  mb-2">First Name on Passport</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter First Name"
              value={passportData.name_on_passport}
              onChange={(e) =>
                setPassportDataValue("name_on_passport", e.target.value)
              }
            />
          </div>

          <div className="col-6  mb-2">
            <label className="fw-semibold  mb-2">Last Name on Passport</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Last Name"
              value={passportData.last_name}
              onChange={(e) =>
                setPassportDataValue("last_name", e.target.value)
              }
            />
          </div>

          <div className="col-6  mb-2">
            <label className="fw-semibold  mb-2">
              Date of Birth (as per Passport)
            </label>
            <DatePicker
              value={
                passportData.dob_on_passport
                  ? dayjs(passportData.dob_on_passport)
                  : null
              }
              className="w-100 fs-6"
              onChange={(date) => {
                setPassportDataValue("dob_on_passport", date);
              }}
              size="large"
            />
          </div>

          <div className="col-6  mb-2">
            <label className="fw-semibold  mb-2">Expiry Date</label>
            <DatePicker
              value={
                passportData.expiry_date
                  ? dayjs(passportData.expiry_date)
                  : null
              }
              className="w-100 fs-6"
              onChange={(date) => {
                setPassportDataValue("expiry_date", date);
              }}
              size="large"
            />
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button type="button" className="btn btn-light-danger" onClick={onHide}>
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={onSubmit}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
