import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { Select, Checkbox } from "antd";
import { fetchData } from "../../../config/service";

const { Option } = Select;

const bhxOptions = [
  {
    value: "Find a Community to be a part of",
    label: "Find a Community to be a part of",
  },
  { value: "Meet new People", label: "Meet new People" },
  {
    value: "Want Perspectives - Feeling Burntout/Creative Block",
    label: "Want Perspectives - Feeling Burntout/Creative Block",
  },
  {
    value: "Launching something new, and want feedback",
    label: "Launching something new, and want feedback",
  },
  {
    value: "Growing my network beyond my industry",
    label: "Growing my network beyond my industry",
  },
  { value: "Having a creative block", label: "Having a creative block" },
  {
    value: "Validation for my idea/startup",
    label: "Validation for my idea/startup",
  },
  { value: "Need a Productive break", label: "Need a Productive break" },
  {
    value: "Need to Travel / Do something new",
    label: "Need to Travel / Do something new",
  },
  {
    value: "Getting more clients, Increasing Revenue",
    label: "Getting more clients, Increasing Revenue",
  },
  { value: "Unhappy with my Job", label: "Unhappy with my Job" },
  { value: "Seek funding", label: "Seek funding" },
];

export default function CallNotesModal({ show, onHide, onSuccess, data }) {
  const [sending, setSending] = useState(false);
  const [formData, setFormData] = useState({
    consideration_journey: null,
    change_their_edition: null,
    What_definite_yes: null,
  });
  const [callNoteId, setCallNoteId] = useState(null);

  //   useEffect(() => {
  //     console.log(data, "user_data");
  //   }, [data]);

  useEffect(() => {
    if (show) {
      getCallNotes();
    }
  }, [show]);

  const getCallNotes = () => {
    fetchData(
      `api/call/call-edition-response/details/?edition=${data?.edition}`,
      "get"
    ).then((res) => {
      if (res.success) {
        const data = res?.data[0];
        if (data?.id) {
          setFormData(data);
          setCallNoteId(data?.id);
        } else {
          setFormData({
            consideration_journey: null,
            change_their_edition: null,
            What_definite_yes: null,
          });
        }
      } else {
        toast.error(res.message);
      }
    });
  };

  const onSubmitUser = async () => {
    setSending(true);

    fetchData(`api/call/call-edition-response/details/`, "post", {
      user: data?.user_data?.uuid,
      ...formData,
      edition: data?.edition,
    }).then((res) => {
      toast.remove();
      setSending(false);
      if (res.success) {
        onHide();
        onSuccess();
        toast.success("Success");
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3 className="modal-title">
            {data?.user_data?.first_name + " " + data?.user_data?.last_name}
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row g-5 mb-5">
          {/* Consideration Journey Dropdown */}
          <div className="col-md-12">
            <label className="form-label fw-semibold">
              Where are they on their consideration journey?
            </label>
            <Select
              value={formData.consideration_journey}
              onChange={(value) => handleChange("consideration_journey", value)}
              style={{ width: "100%" }}
              size="large"
              placeholder="Select an option"
              dropdownStyle={{ zIndex: 1111 }}
              options={[
                { value: "Surely Coming", label: "Surely Coming" },
                {
                  value: "Considering Another Edition",
                  label: "Considering Another Edition",
                },
                { value: "Won't Make It", label: "Won't Make It" },
              ]}
            />
          </div>

          <div className="col-md-12">
            <label className="form-label fw-semibold">
              What will make BHX a definite yes for the user?
            </label>
            <Select
              //   mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select factors"
              size="large"
              dropdownStyle={{ zIndex: 1111 }}
              value={formData.What_definite_yes}
              onChange={(values) => handleChange("What_definite_yes", values)}
              options={bhxOptions}
            />
          </div>
          {/* What Will Make BHX a Definite Yes - Checkboxes */}
          {/* <div className="col-md-12">
            <label className="form-label fw-semibold">
              What will make BHX a definite yes for the user?
            </label>
            {[
              {
                key: "find_community",
                label: "Find a Community to be a part of",
              },
              { key: "meet_new_people", label: "Meet new People" },
              {
                key: "want_perspectives",
                label: "Want Perspectives - Feeling Burntout/Creative Block",
              },
              {
                key: "launching_something_new",
                label: "Launching something new, and want feedback",
              },
              {
                key: "growing_network",
                label: "Growing my network beyond my industry",
              },
              {
                key: "having_creative_block",
                label: "Having a creative block",
              },
              {
                key: "validation_for_idea",
                label: "Validation for my idea/startup",
              },
              {
                key: "need_productive_break",
                label: "Need a Productive break",
              },
              {
                key: "need_travel",
                label: "Need to Travel / Do something new",
              },
              {
                key: "getting_more_clients",
                label: "Getting more clients, Increasing Revenue",
              },
              { key: "unhappy_with_job", label: "Unhappy with my Job" },
              { key: "seek_funding", label: "Seek funding" },
            ].map((item) => (
              <div key={item.key} className="mb-2">
                <Checkbox
                  checked={formData[item.key]}
                  onChange={(e) => handleChange(item.key, e.target.checked)}
                >
                  {item.label}
                </Checkbox>
              </div>
            ))}
          </div> */}

          {/* Likelihood of Changing Edition Dropdown */}
          <div className="col-md-12 mt-5">
            <label className="form-label fw-semibold">
              How likely are they to change their edition?
            </label>
            <Select
              value={formData.change_their_edition}
              onChange={(value) => handleChange("change_their_edition", value)}
              style={{ width: "100%" }}
              dropdownStyle={{ zIndex: 1111 }}
              size="large"
              placeholder="Select an option"
              options={[
                { value: "Very Likely", label: "Very Likely" },
                { value: "Unlikely", label: "Unlikely" },
                { value: "User Unsure", label: "User Unsure" },
              ]}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={onSubmitUser}
          disabled={sending}
        >
          {callNoteId ? "Update" : "Create"}
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
