import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { fetchData } from "../../config/service";
import { Select } from "antd";
import { defaultRoutes } from "../../routers";

export default function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [emailOtp, setEmailOtp] = useState("");
  const [phoneOtp, setPhoneOtp] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [userObj, setUserObj] = useState({});
  const [validations, setValidations] = useState({
    length: false,
    lettersAndNumbers: false,
    symbol: false,
    capitalLetter: false,
  });
  const [showPass1, setShowPass1] = useState(false);
  const [showPass2, setShowPass2] = useState(false);

  const [travel_agency, setTravel_agency] = useState("");
  const [travelAgencyOptions, setTravelAgencyOptions] = useState([]);

  useEffect(() => {
    const length = password.length >= 8;
    const lettersAndNumbers =
      /[a-zA-Z]/.test(password) && /[0-9]/.test(password);
    const symbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const capitalLetter = /[A-Z]/.test(password);

    setValidations({
      length,
      lettersAndNumbers,
      symbol,
      capitalLetter,
    });
  }, [password]);

  useEffect(() => {
    if (Object.keys(user)?.length !== 0) {
      navigate(defaultRoutes[user?.user_type]);
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (userObj?.is_email_verified) {
      toast.success(
        "Your account verified successfully, please login to continue!"
      );
      navigate("/login");
    }
  }, [userObj]);

  useEffect(() => {
    getTravelAgency();
  }, []);

  const getTravelAgency = () => {
    fetchData("api/marketplace/travel-agencies/").then((res) => {
      if (res.success) {
        const data = res.data?.map((e) => {
          return {
            label: e.name,
            value: e.name,
          };
        });

        setTravelAgencyOptions(res.data);
      } else {
        setTravelAgencyOptions([]);
        toast.error(res.message);
      }
    });
  };

  const onRegister = () => {
    if (
      !name ||
      // !phone ||
      !email ||
      // phone.length !== 10 ||
      !password ||
      !confirmPassword ||
      password !== confirmPassword
      // ||
      // !acceptTerms
    ) {
      if (!name) {
        toast.error("Please enter your name!");
      } else if (!email) {
        toast.error("Please enter your email!");
      }
      // else if (!phone) {
      //   toast.error("Please enter your phone number!");
      // }
      // else if (phone.length !== 10) {
      //   toast.error("Phone number must be 10 digits long!");
      // }
      else if (!password) {
        toast.error("Please enter your password!");
      } else if (!confirmPassword) {
        toast.error("Please enter confirm password!");
      } else if (password !== confirmPassword) {
        toast.error("Passwords do not match!");
      }
      // else if (!acceptTerms) {
      //   toast.error("Please accept terms & conditions!");
      // }
    } else {
      setWaiting(true);

      const body = {
        first_name: name,
        ...(phone ? { phone: "+91" + phone } : {}),
        password: password,
        confirm_password: confirmPassword,
        email: email,
        travel_agency: travel_agency,
      };

      fetchData("api/accounts/registrations/", "post", body).then((res) => {
        setWaiting(false);
        if (res.success) {
          setOtpSent(true);
          setUserObj(res);
        } else {
          toast.error(res.message);
        }
      });
    }
  };

  // const verifyOtp = () => {
  //   if (
  //     !phoneOtp ||
  //     phoneOtp.length !== 4 ||
  //     !emailOtp ||
  //     emailOtp.length !== 4
  //   ) {
  //     if (!phoneOtp) {
  //       toast.error("Please enter SMS OTP!");
  //     } else if (phoneOtp.length !== 4) {
  //       toast.error("Please enter valid SMS OTP!");
  //     } else if (!emailOtp) {
  //       toast.error("Please enter email OTP!");
  //     } else if (phoneOtp.length !== 4) {
  //       toast.error("Please enter valid email OTP!");
  //     }
  //   } else {
  //     toast.success("coming soon...");
  //     //   setWaiting(true);
  //     //   const body = {
  //     //     email: email,
  //     //     // otp: otp,
  //     //   };
  //     //   fetchData("api/accounts/auth/verify-otp/", "post", body).then((res) => {
  //     //     setWaiting(false);
  //     //     // alert("ok");
  //     //     if (res.success) {
  //     //     //   dispatch(setUser(res.data));
  //     //     } else {
  //     //       toast.error(res.message);
  //     //     }
  //     //   });
  //   }
  // };

  const verifyEmailOTP = () => {
    if (!emailOtp || emailOtp.length !== 4) {
      if (!emailOtp) {
        toast.error("Please enter email OTP!");
      } else if (phoneOtp.length !== 4) {
        toast.error("Please enter valid email OTP!");
      }
    } else {
      setWaiting("email");
      const body = {
        email: userObj.email,
        otp: emailOtp,
      };
      fetchData("api/accounts/registrations/verify-email/", "post", body).then(
        (res) => {
          setWaiting(false);
          if (res.success) {
            // toast.success(res.message);
            setUserObj({
              ...userObj,
              is_email_verified: true,
            });
          } else {
            toast.error(res.message);
          }
        }
      );
    }
  };

  const verifyPhoneOTP = () => {
    if (!phoneOtp || phoneOtp.length !== 4) {
      if (!phoneOtp) {
        toast.error("Please enter sms OTP!");
      } else if (phoneOtp.length !== 4) {
        toast.error("Please enter valid sms OTP!");
      }
    } else {
      setWaiting("phone");
      const body = {
        phone: userObj.phone,
        otp: phoneOtp,
      };
      fetchData("api/accounts/registrations/verify-phone/", "post", body).then(
        (res) => {
          setWaiting(false);
          if (res.success) {
            // toast.success(res.message);
            setUserObj({
              ...userObj,
              is_phone_verified: true,
            });
          } else {
            toast.error(res.message);
          }
        }
      );
    }
  };
  const [inputValue, setInputValue] = useState("");

  const handleSearch = (value) => {
    setInputValue(value);
  };

  const options = travelAgencyOptions
    ? [
        ...travelAgencyOptions.map((e) => ({ label: e.name, value: e.name })),
        ...(inputValue &&
        !travelAgencyOptions.some((e) => e.name === inputValue)
          ? [{ label: inputValue, value: inputValue }]
          : []),
      ]
    : [];

  return (
    <div className="auth-bg">
      <div className="d-flex flex-column flex-root">
        <div
          className="d-flex flex-column flex-lg-row flex-column-fluid"
          style={{ height: "100vh" }}
        >
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
            <div className="d-flex flex-center flex-column flex-lg-row-fluid">
              <div className="w-lg-500px p-10">
                <div className=" mb-10">
                  <Link
                    to={"/login"}
                    className=" text-dark d-flex align-items-center justify-content-center"
                  >
                    <img
                      alt="Logo"
                      src="/media/icon-bhx.png"
                      className="h-20px h-lg-90px theme-light-show "
                    />
                    {/* <img alt="Logo" src="/media/icon-bhx.png" class="h-20px h-lg-50px theme-dark-show" /> */}
                    {/* <span class="text-gray-800 ms-2 fs-1 fw-bold">Experience Co.</span> */}
                  </Link>
                </div>
                <div className="form w-100">
                  <div className="text-center mb-11">
                    <h1 className="text-gray-900 fw-bolder mb-3">
                      Join Experience Co.
                    </h1>

                    <div className="text-gray-500 fw-semibold fs-6">
                      Sign in to plan and book your next adventure.
                    </div>
                  </div>
                  {otpSent ? (
                    <div>
                      {!userObj.is_email_verified && (
                        <div className="fv-row mb-8">
                          <label className="text-gray-600 fw-semibold fs-7">
                            We just sent an OTP to {userObj.email}
                          </label>
                          <div className="d-flex">
                            <input
                              type="text"
                              placeholder="Email OTP"
                              className="form-control bg-transparent h-60px border-gray-400 ps-7"
                              value={emailOtp}
                              maxLength={4}
                              onChange={(e) => {
                                setEmailOtp(e.target.value);
                              }}
                            />
                            <button
                              className="btn btn-primary ml-10px"
                              onClick={verifyEmailOTP}
                              disabled={
                                waiting === "email" || emailOtp.length !== 4
                              }
                            >
                              {waiting === "email" ? (
                                <span className="spinner-border spinner-border-sm align-middle ms-2" />
                              ) : (
                                <i className={"ki-outline ki-check fs-1"} />
                              )}
                            </button>
                          </div>
                        </div>
                      )}
                      {/* {phone && phone !== "" && !userObj.is_phone_verified && (
                        <div className="fv-row mb-8">
                          <label className="text-gray-600 fw-semibold fs-7">
                            We just sent an OTP to {userObj.phone}
                          </label>
                          <div className="d-flex">
                            <input
                              type="text"
                              placeholder="SMS OTP"
                              className="form-control bg-transparent h-60px border-gray-400 ps-7"
                              value={phoneOtp}
                              maxLength={4}
                              onChange={(e) => {
                                setPhoneOtp(e.target.value);
                              }}
                            />
                            <button
                              className="btn btn-primary ml-10px"
                              onClick={verifyPhoneOTP}
                              disabled={
                                waiting === "phone" || phoneOtp.length !== 4
                              }
                            >
                              {waiting === "phone" ? (
                                <span className="spinner-border spinner-border-sm align-middle ms-2" />
                              ) : (
                                <i className={"ki-outline ki-check fs-1"} />
                              )}
                            </button>
                          </div>
                        </div>
                      )} */}
                    </div>
                  ) : (
                    <div>
                      <div className="fv-row mb-5">
                        <input
                          type="text"
                          placeholder="Full Name"
                          className="form-control bg-transparent"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="fv-row mb-5">
                        <input
                          type="email"
                          placeholder="Email"
                          className="form-control bg-transparent"
                          value={email}
                          onChange={(e) => {
                            const emailValue = e.target.value.toLowerCase();
                            setEmail(emailValue);
                          }}
                        />
                      </div>
                      <div className="fv-row mb-5">
                        <Select
                          showSearch
                          optionFilterProp="label"
                          name="city"
                          value={travel_agency || null}
                          onChange={(value) => setTravel_agency(value)}
                          placeholder="Select Travel Agency"
                          className="fs-7 w-100 custom-select"
                          style={{ width: "100%", height: "45px" }}
                          dropdownStyle={{ zIndex: 1100 }}
                          size="large"
                          options={options}
                          onSearch={handleSearch} // Capture typed input
                          loading={false}
                        />
                      </div>

                      <div className="fv-row mb-5">
                        <input
                          type="number"
                          placeholder="Mobile Number"
                          className="form-control bg-transparent"
                          value={phone}
                          maxLength={10}
                          onChange={(e) => {
                            setPhone(e.target.value);
                          }}
                        />
                      </div>
                      <div className="fv-row mb-5">
                        <div className="mb-1">
                          <div className="password-container mb-3">
                            <input
                              className="form-control bg-transparent"
                              type={showPass1 ? "text" : "password"}
                              placeholder="Password"
                              value={password}
                              onChange={(e) => {
                                setPassword(e.target.value);
                              }}
                            />
                            <span
                              className="eye-password"
                              onClick={() => {
                                setShowPass1(!showPass1);
                              }}
                            >
                              <i
                                className={`fs-1 ki-outline ${
                                  showPass1 ? "ki-eye-slash" : "ki-eye"
                                }  me-2`}
                              />
                            </span>
                          </div>

                          <div
                            className="d-flex align-items-center mb-3"
                            data-kt-password-meter-control="highlight"
                          >
                            <div
                              className={`flex-grow-1 bg-gray-300 bg-active-success rounded h-5px me-2  ${
                                validations.lettersAndNumbers && "active"
                              }`}
                            />
                            <div
                              className={`flex-grow-1 bg-gray-300 bg-active-success rounded h-5px me-2  ${
                                validations.capitalLetter && "active"
                              }`}
                            />
                            <div
                              className={`flex-grow-1 bg-gray-300 bg-active-success rounded h-5px me-2 ${
                                validations.symbol && "active"
                              }`}
                            />
                            <div
                              className={`flex-grow-1 bg-gray-300 bg-active-success rounded h-5px ${
                                validations.length && "active"
                              }`}
                            />
                          </div>
                        </div>

                        <div className="text-muted">
                          Use 8 or more characters with a mix of letters,
                          numbers &amp; symbols.
                        </div>
                      </div>
                      <div className="fv-row mb-5 password-container">
                        <input
                          placeholder="Repeat Password"
                          type={showPass2 ? "text" : "password"}
                          className="form-control bg-transparent"
                          value={confirmPassword}
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                          }}
                        />
                        <span
                          className="eye-password"
                          onClick={() => {
                            setShowPass2(!showPass2);
                          }}
                        >
                          <i
                            className={`fs-1 ki-outline ${
                              showPass2 ? "ki-eye-slash" : "ki-eye"
                            }  me-2`}
                          />
                        </span>
                      </div>
                      <div className="fv-row mb-5">
                        {/* <label className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={acceptTerms}
                            onChange={(e) => {
                              setAcceptTerms(e.target.value);
                            }}
                          />
                          <span className="form-check-label fw-semibold text-gray-700 fs-base ms-1">
                            I Accept the
                            <a href="#" className="ms-1 link-primary">
                              Terms
                            </a>
                          </span>
                        </label> */}
                      </div>
                    </div>
                  )}
                  {!otpSent && (
                    <div className="d-grid mb-10">
                      <button
                        className="btn btn-primary"
                        onClick={onRegister}
                        disabled={waiting === true}
                      >
                        {waiting ? (
                          <span>
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span>
                        ) : (
                          <span className="indicator-label">Sign Up</span>
                        )}
                      </button>
                    </div>
                  )}

                  <div className="text-gray-500 text-center fw-semibold fs-6">
                    Already have an Account?{" "}
                    <Link to="/login" className="link-primary fw-semibold">
                      Sign in
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
            style={{
              backgroundImage: "url(/media/bg1.png)",
              borderRadius: "170px 0px 0px 0px",
            }}
          >
            <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
