import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../config/service";
import { extractPhoneDetails } from "../../../config";
import { Input, Space } from "antd";
import { validateFields } from "../../../components/validation";

export default function EmergencyContactDetailsModal({
  show,
  onHide,
  data,
  onSuccess,
}) {
  const [sending, setSending] = useState(false);
  const [contactData, setContactData] = useState({
    name: "",
    phone: "",
    country_code: "+91",
    relationship: "",
  });

  useEffect(() => {
    if (show) {
      setContactData({
        name: data?.customer_emergency_contact?.name || "",
        country_code: data?.customer_emergency_contact?.phone_number
          ? extractPhoneDetails(data?.customer_emergency_contact?.phone_number)
              ?.countryCode
          : "+91",
        phone: data?.customer_emergency_contact?.phone_number
          ? extractPhoneDetails(data?.customer_emergency_contact?.phone_number)
              ?.phone
          : "",
        relationship: data?.customer_emergency_contact?.relationship || "",
      });
    }
  }, [data, show]);

  const validationRules = {
    name: { required: true, maxLength: 50 },
    phone: { required: true, maxLength: 10 },
    relationship: { required: true },
  };

  const onSubmitContact = async () => {
    const { isValid } = validateFields(contactData, validationRules);

    if (!isValid) {
      return;
    } else {
      toast.loading("Updating Contact");
    }

    setSending(true);

    const body = {
      user: data?.uuid,
      ...(contactData.name !== "" ? { name: contactData.name } : {}),
      ...(contactData.phone && contactData.phone.trim()
        ? { phone_number: contactData.country_code + contactData.phone }
        : {}),
      ...(contactData.relationship !== ""
        ? { relationship: contactData.relationship }
        : {}),
    };

    console.log(body, "body");

    fetchData(`customer/api/add-emergency-contact/`, "post", body).then(
      (res) => {
        toast.remove();
        setSending(false);
        if (res.success) {
          onHide();
          onSuccess();
          toast.success(res?.message || "Record Updated Successfully");
        } else {
          toast.error(res.message);
        }
      }
    );
  };

  const setContactDataValue = (key, value) => {
    setContactData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const setContactNumber = (key, value) => {
    setContactData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">Emergency Contact Details</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label>
            <span className=" fw-semibold fs-5">Name</span>
          </label>
          <input
            type="text"
            className="form-control fs-7"
            value={contactData.name}
            onChange={(e) => setContactDataValue("name", e.target.value)}
            placeholder="Enter Name"
          />
        </div>

        <div className="mt-6">
          <label>
            <span className=" fw-semibold fs-5">Phone Number</span>
          </label>
          <Space.Compact style={{ width: "100%" }}>
            <Input
              size="large"
              style={{ width: "30%" }}
              value={contactData?.country_code}
              onChange={(e) => setContactNumber("country_code", e.target.value)}
            />
            <Input
              size="large"
              style={{ width: "70%" }}
              value={contactData?.phone}
              onChange={(e) => setContactNumber("phone", e.target.value)}
            />
          </Space.Compact>
        </div>

        <div className="mt-6">
          <label>
            <span className=" fw-semibold fs-5">Relationship</span>
          </label>
          <input
            type="text"
            className="form-control fs-7"
            value={contactData.relationship}
            onChange={(e) =>
              setContactDataValue("relationship", e.target.value)
            }
            placeholder="Enter Relationship"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => {
            onSubmitContact();
          }}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
