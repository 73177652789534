import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../config/service";
import { Loader } from "../../../components";

export default function HotelVoucherModal({ show, onHide, data }) {
  const [sendingHotelId, setSendingHotelId] = useState(null);
  const [hotels, setHotels] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // console.log(show, "show");
    if (show) {
      getHotels();
    }
  }, [show]);

  const getHotels = () => {
    setLoading(true);
    fetchData(
      `api/hotel/edition-hotels/${data?.edition}/?booking_id=${data?.id}`
    )
      .then((res) => {
        setLoading(false);
        if (res.success) {
          const { success, ...data } = res;
          const newData = Object.values(data || {})
            .map((item) => item)
            .filter((item) => item?.confirmation_number !== null);
          setHotels(newData);
        } else {
          toast.error(res.message);
          setHotels([]);
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error("Failed to fetch hotels");
        setHotels([]);
      });
  };

  const generateVoucher = async (hotel) => {
    setSendingHotelId(hotel.id);

    const body = {
      edition_id: data?.edition,
      user_id: data?.user?.uuid,
      confirmation_number: hotel?.confirmation_number,
      booking_id: data?.id,
      hotel_id: hotel?.id,
    };

    fetchData(`get/voucher/`, "POST", body)
      .then((res) => {
        if (res.success) {
          toast.success("Voucher generated successfully");
          onHide();
          // setTimeout(() => {
          //   setSendingHotelId(null);
          //   toast.success("Voucher generated successfully");
          //   getHotels();
          // }, 3000);
        } else {
          toast.error(res.message);
        }
      })
      .catch(() => {
        setSendingHotelId(null);
        toast.error("Failed to generate voucher");
      });
  };

  const downloadVoucher = async (url) => {
    const toastId = toast.loading("Downloading voucher...");

    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const link = document.createElement("a");

      link.href = window.URL.createObjectURL(blob);
      link.download = "Hotel_Voucher.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success("Download complete!", { id: toastId });
    } catch (error) {
      toast.error("Failed to download voucher", { id: toastId });
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">Generate Hotel Vouchers</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loader cols={1} width={250} />
        ) : hotels.length > 0 ? (
          hotels.map((hotel, index) => (
            <div
              key={hotel.id}
              className={`d-flex justify-content-between align-items-center pb-5 ${
                index === hotels.length - 1
                  ? "mb-0 border-none"
                  : "mb-10 border-bottom"
              }`}
            >
              <div style={{ maxWidth: "580px" }}>
                <p className="fw-semibold mb-1">{hotel.name}</p>
                <span className="text-muted fs-7">{hotel.address}</span>
              </div>
              {hotel.voucher_pdf ? (
                <button
                  type="button"
                  className="btn btn-success fs-7"
                  onClick={() => downloadVoucher(hotel.voucher_pdf)}
                >
                  Download Voucher
                </button>
              ) : (
                // <a
                //   href={hotel.voucher_pdf}
                //   // target="_blank"
                //   rel="noopener noreferrer"
                //   className="btn btn-success fs-7"
                // >
                //   Download Voucher
                // </a>
                <button
                  type="button"
                  className="btn btn-primary fs-7"
                  onClick={() => generateVoucher(hotel)}
                  disabled={sendingHotelId === hotel.id}
                >
                  {sendingHotelId === hotel.id ? (
                    <>
                      Generating...
                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </>
                  ) : (
                    "Generate Voucher"
                  )}
                </button>
              )}
            </div>
          ))
        ) : (
          <div className="row g-5 mb-0 gx-md-10">
            <div className="col-md-12 text-center">
              <div className="mt-0">
                <img
                  src="/media/no-data-found.png"
                  className="mw-25"
                  alt="No Data Found"
                />
              </div>
              <h1 className="mt-4">No Data Found</h1>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
