import React, { useCallback } from "react";
import { LIMITS } from "../config";
import PaginationModule from "react-js-pagination";

export default function Pagination({
  data,
  limit,
  setLimit,
  activePage,
  totalRecords,
  onChange,
}) {
  // useCallback ensures that we don't trigger re-renders unnecessarily
  const handlePageChange = useCallback(
    (pageNumber) => {
      if (pageNumber === activePage) {
        return; // Do nothing if the active page is clicked again
      }
      // Trigger the page change if it's a different page
      onChange(pageNumber);
    },
    [activePage, onChange]
  );

  return (
    <div>
      {data.length > 0 && (
        <div className="d-flex flex-stack flex-wrap pt-8 pt-md-5 pt-lg-5  pe-7">
          <div className="fs-7 fw-bold text-gray-700 Showing d-flex align-items-center  mb-5 mb-md-0 mb-lg-0">
            <select
              className="form-select form-select-sm form-select-solid text-center fw-bolder w-75px me-3"
              value={limit}
              onChange={(e) => {
                const newLimit = parseInt(e.target.value, 10); // Ensure the limit is a number
                setLimit(newLimit);
                onChange(1); // Reset activePage to 1
              }}
            >
              {LIMITS.map((item, i) => {
                return (
                  <option value={item} key={i}>
                    {item}
                  </option>
                );
              })}
            </select>
            <div className=" vw-35">

            Showing {data.length} of {totalRecords} entries
            </div>
          </div>
          <PaginationModule
            activePage={activePage}
            itemsCountPerPage={limit}
            totalItemsCount={totalRecords}
            pageRangeDisplayed={5}
            itemClass="page-item"
            linkClass="page-link"
            activeLinkClass="active"
            onChange={handlePageChange} // Use the handlePageChange function here
          />
        </div>
      )}
    </div>
  );
}
