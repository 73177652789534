import React, { useState } from "react";
import { Nav, Accordion } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
const MobileMenuItem = (props) => {
  const navigate = useNavigate();

  const { routesList, pathname, activeKey, setActiveKey, user } = props;

  const handleSelect = (eventKey) => {
    setActiveKey(activeKey === eventKey ? null : eventKey);
  };

  return (
    <>
      <div className="text-dark d-flex align-items-center justify-content-center mb-7">
        <img
          alt="Logo"
          src="/media/icon-bhx.png"
          className="h-20px h-lg-50px theme-light-show "
        />
        <span className="text-gray-800 ms-2 fs-1 fw-bold">Experience Co.</span>
      </div>
      <div className="separator separator-dashed"></div>

      <Accordion activeKey={activeKey} onSelect={handleSelect} className="mt-5">
        {[1, 2, 3, 4, 5, 6, 10].includes(user?.user_type) && (
          <Accordion.Item eventKey="-1">
            <Accordion.Header
              onClick={() => navigate("/")}
              className="direct-menu"
            >
              <i className="text-primary fs-1 ki-outline ki-home me-2" />
              Dashboard
            </Accordion.Header>
          </Accordion.Item>
        )}
        {[1, 2, 3, 4, 5, 6, 8, 9, 10].includes(user?.user_type) && (
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <i className="text-primary  fs-1 ki-outline ki-abstract-26 me-2" />
              Manage Editions
            </Accordion.Header>
            <Accordion.Body>
              <div className="my-5">
                <Link
                  to={routesList["projects"]?.path}
                  className={`${
                    routesList?.["projects"]?.path === pathname
                      ? "text-primary  fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  <i className="text-primary  fs-1 ki-outline ki-cube-2 me-2" />
                  Projects
                </Link>
              </div>

              <div className="my-5">
                <Link
                  to={routesList["manage_editions"]?.path}
                  className={`${
                    routesList?.["manage_editions"]?.path === pathname
                      ? "text-primary  fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  {" "}
                  <i className="text-primary  fs-1 ki-outline ki-abstract-26 me-2" />{" "}
                  Manage Edition
                </Link>
              </div>
              <div className="my-5">
                <Link
                  to={routesList["manage_addons"]?.path}
                  className={`${
                    routesList?.["manage_addons"]?.path === pathname
                      ? "text-primary  fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  <i className="text-primary  fs-1 ki-outline ki-element-plus me-2" />{" "}
                  Manage Add-ons
                </Link>
              </div>
              <div className="my-5">
                <Link
                  to={routesList["manage_hotels"]?.path}
                  className={`${
                    routesList?.["manage_hotels"]?.path === pathname
                      ? "text-primary  fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  <i className="text-primary  fs-1 ki-outline ki-delivery-door me-2" />{" "}
                  Manage Hotel
                </Link>
              </div>
              <div className="my-5">
                <Link
                  to={routesList["manage_destinations"]?.path}
                  className={`${
                    routesList?.["manage_destinations"]?.path === pathname
                      ? "text-primary  fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  <i className="text-primary  fs-1 ki-outline ki-geolocation-home me-2" />{" "}
                  Manage Destination
                </Link>
              </div>
              {/* <div className="my-5">
              {" "}
              <Link
                to={routesList["manage_experiences"]?.path}
                className={`${
                  routesList?.["manage_experiences"]?.path === pathname
                    ? "text-primary fw-bold"
                    : "text-gray-700"
                } d-flex align-items-center`}
              >
                <i className="text-primary  fs-1 ki-outline ki-notepad-bookmark me-2" />{" "}
                Addon Experiences
              </Link>
            </div> */}
            </Accordion.Body>
          </Accordion.Item>
        )}

        {[1, 2, 3, 4, 5, 6, 10].includes(user.user_type) && (
          <Accordion.Item eventKey="1">
            <Accordion.Header
            // onClick={() => navigate("/customers")}
            // className="direct-menu"
            >
              <i className="text-primary fs-1 ki-outline ki-profile-user me-2" />
              Users
            </Accordion.Header>
            <Accordion.Body>
              <div className="my-5">
                <Link
                  to={routesList["customers"]?.path}
                  className={`${
                    routesList?.["customers"]?.path === pathname
                      ? "text-primary  fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  <i className="text-primary  fs-1 ki-outline ki-calendar-tick me-2" />
                  Users
                </Link>
              </div>

              <div className="my-5">
                <Link
                  to={routesList["curation_central"]?.path}
                  className={`${
                    routesList?.["curation_central"]?.path === pathname
                      ? "text-primary  fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  {" "}
                  <i className="text-primary  fs-1 ki-outline ki-scroll me-2" />{" "}
                  Curation Central
                </Link>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        )}
        {[1, 2, 3, 4, 5, 6, 8, 10].includes(user.user_type) && (
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <i className="text-primary  fs-1 ki-outline ki-calendar-tick me-2" />
              Bookings & Payments
            </Accordion.Header>
            <Accordion.Body>
              <div className="my-5">
                <Link
                  to={routesList["bookings"]?.path}
                  className={`${
                    routesList?.["bookings"]?.path === pathname
                      ? "text-primary  fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  {" "}
                  <i className="text-primary  fs-1 ki-outline ki-calendar-tick me-2" />{" "}
                  Bookings
                </Link>
              </div>
              <div className="my-5">
                <Link
                  to={routesList["payments"]?.path}
                  className={`${
                    routesList?.["payments"]?.path === pathname
                      ? "text-primary  fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  {" "}
                  <i className="text-primary  fs-1 ki-outline ki-cheque me-2" />{" "}
                  Payments
                </Link>
              </div>
              <div className="my-5">
                <Link
                  to={routesList["call_requests"]?.path}
                  className={`${
                    routesList?.["call_requests"]?.path === pathname
                      ? "text-primary  fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  <i className="text-primary  fs-1 ki-outline ki-address-book me-2" />{" "}
                  Call Requests
                </Link>
              </div>
              <div className="my-5">
                <Link
                  to={routesList["call_recordings"]?.path}
                  className={`${
                    routesList?.["call_recordings"]?.path === pathname
                      ? "text-primary  fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  <i className="text-primary  fs-1 ki-outline ki-address-book me-2" />{" "}
                  Call Recordings
                </Link>
              </div>
              <div className="my-5">
                <Link
                  to={routesList["visa_center"]?.path}
                  className={`${
                    routesList?.["visa_center"]?.path === pathname
                      ? "text-primary  fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  <i className="text-primary  fs-1 ki-outline ki-airplane me-2" />{" "}
                  Visa Centers
                </Link>
              </div>
              <div className="my-5">
                <Link
                  to={routesList["onboarding_calls"]?.path}
                  className={`${
                    routesList?.["onboarding_calls"]?.path === pathname
                      ? "text-primary  fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  <i className="text-primary  fs-1 ki-outline ki-call me-2" />{" "}
                  Onboarding calls
                </Link>
              </div>
              <div className="my-5">
                {" "}
                <Link
                  to={routesList["room_manager"]?.path}
                  className={`${
                    routesList?.["room_manager"]?.path === pathname
                      ? "text-primary fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  <i className="text-primary  fs-1 ki-outline ki-security-user me-2" />{" "}
                  Room Manager
                </Link>
              </div>{" "}
              <div className="my-5">
                {" "}
                <Link
                  to={routesList["change_edition"]?.path}
                  className={`${
                    routesList?.["change_edition"]?.path === pathname
                      ? "text-primary fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  <i className="text-primary  fs-1 ki-outline  ki-update-file me-2" />{" "}
                  Change Edition
                </Link>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        )}

        {[1, 2, 3, 4, 5, 6, 10].includes(user?.user_type) && (
          <Accordion.Item eventKey="-1">
            <Accordion.Header
              onClick={() => navigate("/tribe")}
              className="direct-menu"
            >
              <span className="menu-icon me-2 mx-0">
                <i className=" text-primary fs-1 ki-outline ki-people" />{" "}
              </span>
              Event
            </Accordion.Header>
          </Accordion.Item>
        )}

        {[1, 2, 3, 4, 5, 6, 10].includes(user?.user_type) && (
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <i className="text-primary  fs-1 ki-outline ki-setting-2 me-2" />
              Settings
            </Accordion.Header>
            <Accordion.Body>
              {/* <div className="my-5">
              <Link
                to={routesList["settings"]?.path}
                className={`${
                  routesList?.["settings"]?.path === pathname
                    ? "text-primary  fw-bold"
                    : "text-gray-700"
                } d-flex align-items-center`}
              >
                {" "}
                <i className="text-primary  fs-1 ki-outline ki-setting-2  me-2" />{" "}
                Settings
              </Link>
            </div> */}
              <div className="my-5">
                <Link
                  to={routesList["manage_employee"]?.path}
                  className={`${
                    routesList?.["manage_employee"]?.path === pathname
                      ? "text-primary  fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  <i className="text-primary  fs-1 ki-outline ki-people me-2" />{" "}
                  Manage Team
                </Link>
              </div>
              <div className="my-5">
                <Link
                  to={routesList["change_password"]?.path}
                  className={`${
                    routesList?.["change_password"]?.path === pathname
                      ? "text-primary  fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  <i className="text-primary  fs-1 ki-outline ki-faceid me-2" />{" "}
                  Change Password
                </Link>
              </div>
              <div className="my-5">
                <Link
                  to={routesList["visa_guideline"]?.path}
                  className={`${
                    routesList?.["visa_guideline"]?.path === pathname
                      ? "text-primary  fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  <i className="text-primary  fs-1 ki-outline ki-notepad me-2" />{" "}
                  Visa Guidelines
                </Link>
              </div>
              <div className="my-5">
                <Link
                  to={routesList["faqs"]?.path}
                  className={`${
                    routesList?.["faqs"]?.path === pathname
                      ? "text-primary  fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  <i className="text-primary  fs-1 ki-outline ki-message-question me-2" />{" "}
                  FAQs
                </Link>
              </div>
              <div className="my-5">
                <Link
                  to={routesList["business_metrics"]?.path}
                  className={`${
                    routesList?.["business_metrics"]?.path === pathname
                      ? "text-primary  fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  <i className="text-primary  fs-1 ki-outline ki-graph-up me-2" />{" "}
                  Business Metrics
                </Link>
              </div>
              <div className="my-5">
                <Link
                  to={routesList["ad_metrics"]?.path}
                  className={`${
                    routesList?.["ad_metrics"]?.path === pathname
                      ? "text-primary  fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  <i className="text-primary  fs-1 ki-outline ki-chart-pie-4  me-2" />{" "}
                  Ad Metrics
                </Link>
              </div>
              <div className="my-5">
                {" "}
                <Link
                  to={routesList["feedback"]?.path}
                  className={`${
                    routesList?.["feedback"]?.path === pathname
                      ? "text-primary fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  <i className="text-primary fs-1 ki-outline ki-sms me-2" />{" "}
                  Feedback
                </Link>
              </div>
              <div className="my-5">
                {" "}
                <Link
                  to={routesList["hot_leads"]?.path}
                  className={`${
                    routesList?.["hot_leads"]?.path === pathname
                      ? "text-primary fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  <i className="text-primary fs-1 ki-outline ki-user-square me-2" />{" "}
                  Hot Leads
                </Link>
              </div>
              <div className="my-5">
                {" "}
                <Link
                  to={routesList["crm"]?.path}
                  className={`${
                    routesList?.["crm"]?.path === pathname
                      ? "text-primary fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  <i className="text-primary fs-1 ki-outline ki-badge me-2" />{" "}
                  CRM
                </Link>
              </div>
              {/* <div className="my-5">
                {" "}
                <Link
                  to={routesList["utm"]?.path}
                  className={`${
                    routesList?.["utm"]?.path === pathname
                      ? "text-primary fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  <i className="text-primary fs-1 ki-outline ki-social-media me-2" />{" "}
                  UTM
                </Link>
              </div> */}
              <div className="my-5">
                {" "}
                <Link
                  to={routesList["policy"]?.path}
                  className={`${
                    routesList?.["policy"]?.path === pathname
                      ? "text-primary fw-bold"
                      : "text-gray-700"
                  } d-flex align-items-center`}
                >
                  <i className="text-primary fs-1 ki-outline ki-file-left me-2" />{" "}
                  Policy
                </Link>
              </div>
              {/* <div className="my-5">
              {" "}
              <Link
                to={routesList["refund_policy"]?.path}
                className={`${routesList?.["refund_policy"]?.path === pathname
                    ? "text-primary fw-bold"
                    : "text-gray-700"
                  } d-flex align-items-center`}
              >
                <i className="text-primary fs-1 ki-outline ki-file-left me-2" />{" "}
                Refund Policy
              </Link>
            </div>{" "}
            <div className="my-5">
              {" "}
              <Link
                to={routesList["cancellation_policy"]?.path}
                className={`${routesList?.["cancellation_policy"]?.path === pathname
                    ? "text-primary fw-bold"
                    : "text-gray-700"
                  } d-flex align-items-center`}
              >
                <i className="text-primary  fs-1 ki-outline  ki-file-deleted me-2" />{" "}
                Cancellation Policy
              </Link>
            </div> */}
            </Accordion.Body>
          </Accordion.Item>
        )}
      </Accordion>
    </>
  );
};

export default MobileMenuItem;
