import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { formatNumber, LIMITS } from "../../../../../config";
import { fetchData } from "../../../../../config/service";
import { Loader, Pagination } from "../../../../../components";
import dayjs from "dayjs";
import circularProgressBar from "../../../../../components/CirculerProgressBar";
const tab2Data = [
  {
    user: {
      name: "BHX Prague & Berlin 4",
      avatar: "/media/img.jpg",
    },
    days: 97,
    paidOn: "Jan 21, 2024",
    editionChanges: "-",
    score: 3.5,
    feeForUser: {
      amount: "₹1,25,000",
      grant: "₹1,25,000",
    },
    calledBy: {
      name: "Ruchir",
      time: "Within 3 days",
    },
    onboarded: {
      time: "Within 8 days",
    },
    status: "Move to other...",
  },
  // Add more rows here as needed
];

const GuestList = ({
  editionData,
  setCurrentCount,
  setAvgPrice,
  setSecondPaymentsCount,
}) => {
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData(editionData?.id);
  }, [editionData?.id]);

  const getData = (id) => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}&edition_id=${id}`;

    setLoading(true);
    fetchData(`api/editions/statistic/guestlist/?edition_id=${id}`, "get").then(
      (res) => {
        setLoading(false);
        if (res.success) {
          setCurrentCount(res.current_count);
          setAvgPrice(res.average_price);
          setData(res?.data);
          setSecondPaymentsCount(res.second_payments);

          // setData(transformedData);
          setTotalRecords(res.count ? res.count - 1 : 0);
        } else {
          setData([]);
          toast.error(res.message);
        }
      }
    );
  };

  return (
    <div>
      <div className="table-responsive tbl-sales">
        {loading ? (
          <div className="table-responsive">
            <Loader cols={7} width={250} />
          </div>
        ) : (
          <div>
            {data?.length > 0 ? (
              <>
                <div className="table-responsive tbl-sales">
                  {/*begin::Table*/}
                  <table
                    className="table align-middle table-row-dashed table-row-gray-300 gy-4 gs-4 border-top-d"
                    id
                  >
                    <thead>
                      <tr className="fw-bolder text-gray-900 bg-light">
                        <th className="min-w-100px">User</th>
                        <th className="min-w-100px">Days</th>
                        <th className="min-w-125px">Gender</th>
                        <th className="min-w-100px">Paid on</th>
                        <th className="min-w-125px">Edition Changes</th>
                        <th className="min-w-125px ">Score</th>
                        <th className="min-w-150px">Fee for User</th>
                        <th className="min-w-100px">Called By</th>
                        <th className="min-w-100px">Onboarded</th>
                        <th className="min-w-125px">Status</th>
                        {/* <th className="min-w-100px">Action</th> */}
                      </tr>
                    </thead>
                    <tbody className="fw-semibold fs-7">
                      {data.map((row, index) => {
                        // Calculate the days between the start date and the current date
                        const startDate = new Date(row.start_date);
                        const currentDate = new Date();
                        const timeDiff = currentDate - startDate;
                        const daysDiff = Math.floor(
                          timeDiff / (1000 * 60 * 60 * 24)
                        );

                        return (
                          <tr key={index}>
                            <td className="bdr-left-user">
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-30px overflow-hidden me-3">
                                  <img
                                    src={row?.user?.photo}
                                    alt={row?.user?.first_name}
                                  />
                                </div>
                                <div className="d-flex flex-column text-start">
                                  <a
                                    href="#"
                                    className="text-primary mb-1 fw-bolder fs-6 min-w-200px"
                                  >
                                    {row?.user?.first_name +
                                      " " +
                                      row?.user?.last_name}
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td>{daysDiff} days</td>
                            <td>{row?.gender}</td>
                            <td>
                              {row.paid_on
                                ? dayjs(row.paid_on).format("DD-MM-YYYY")
                                : "-"}
                            </td>
                            <td>{row.edition_changes}</td>
                            <td className="d-flex">
                              {/* {row.score} */}
                              <span>
                                {circularProgressBar(row.score || 0, 35, 3)}
                              </span>
                            </td>
                            <td>
                              <div className="fw-bolder fs-6 text-gray-900">
                                {formatNumber(row.fee_for_user)}
                                <br />
                                {/* <small className="text-gray-600">
                                  / Grant: N/A
                                </small> */}
                              </div>
                            </td>
                            <td>
                              <div>
                                <div className="fw-bold fs-6">
                                  {row.call_by || "Not Called"}
                                </div>
                                {/* <div className="fs-8 text-gray-600">
                                  {row.call_by
                                    ? new Date(row.paid_on).toLocaleTimeString()
                                    : ""}
                                </div> */}
                              </div>
                            </td>
                            <td>
                              <div>
                                <div className="fw-bold fs-6">
                                  {row.on_boarded ? "Yes" : "No"}
                                </div>
                                {/* <div className="fs-8 text-gray-600">
                                  {row.on_boarded
                                    ? new Date(
                                        row.on_boarded
                                      ).toLocaleDateString()
                                    : ""}
                                </div> */}
                              </div>
                            </td>
                            <td>{row.status || "N/A"}</td>
                            {/* <td>

                              <button className="btn btn-sm btn-primary">
                                Action
                              </button>
                            </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {/*end::Table*/}
                </div>

                <div className="separator separator-dashed border-gray-200" />
                <Pagination
                  setLimit={setLimit}
                  limit={limit}
                  data={data}
                  activePage={activePage}
                  totalRecords={totalRecords}
                  onChange={(pageNumber) => {
                    setLoading(true);
                    setActivePage(pageNumber);
                  }}
                />
              </>
            ) : (
              <div className="row g-5 mb-0 gx-md-10">
                <div className="col-md-12 text-center">
                  <div className="mt-0">
                    <img
                      src="/media/no-data-found.png"
                      className="mw-25"
                      alt="No Data Found"
                    />
                  </div>
                  <h1 className="mt-4">No Data Found</h1>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default GuestList;
