import React, { useEffect, useState } from "react";
import { FeedbackModal } from "./modals";
import { fetchData } from "../../../config/service";
import toast from "react-hot-toast";
import DebouncedSelect from "../../../components/debounced-select";
import { useParams } from "react-router-dom";
import { Popover, Button, Spin } from "antd";
import WhatsappTemplateModal from "../../customers/modals/whatsapp-template";
import MakeCallModal from "../../../components/make-call-modal";
import { Loader } from "../../../components";

const projects = [
  {
    name: "BHX Beaches 18",
    status: "Ended 363 days back",
    pending: 14,
    achievement: 142,
    goal: 80,
    npsScore: 178,
    feedback: { positive: 106, neutral: 6, negative: 42 },
  },
  {
    name: "BHX Rome Adventure",
    status: "Ongoing",
    pending: 7,
    achievement: 95,
    goal: 85,
    npsScore: 150,
    feedback: { positive: 70, neutral: 10, negative: 10 },
  },
  {
    name: "BHX Berlin & Prague 3",
    status: "Ended 330 days back",
    pending: 3,
    achievement: 67,
    goal: 80,
    npsScore: 120,
    feedback: { positive: 90, neutral: 15, negative: 25 },
  },
];

const contactData = [
  {
    id: 1,
    name: "Dhaval P",
    time: "11:58 am / Aug 09",
    score: 3,
    bhkBall: "BHK Balli 8",
    age: 25,
    revenue: "",
    category: "",
    story: "",
    email: "dhaval@socialiate.net",
  },
  {
    id: 2,
    name: "Shashank R",
    time: "11:57 am / Aug 09",
    score: 10,
    bhkBall: "BHK Ball 8",
    age: 25,
    revenue: "",
    category: "",
    story: "",
    email: "shashank@socialiate.net",
  },
  {
    id: 3,
    name: "Shashank R",
    time: "11:56 am / Aug 09",
    score: 7,
    bhkBall: "BHK Ball 8",
    age: 25,
    revenue: "",
    category: "",
    story: "",
    email: "shashank@socialiate.net",
  },
];

export default function Feedback() {
  const [activeTab, setActiveTab] = useState("tab3");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { id } = useParams();

  const [totalRecords, setTotalRecords] = useState(0);
  // State to manage collapse for each contact
  const [collapseState, setCollapseState] = useState({});

  const [loading, setLoading] = useState(false);
  const [overViewData, setOverViewData] = useState([]);
  const [filterData, setFilterData] = useState({
    project: null,
    edition: null,
  });

  const [selected, setSelected] = useState(null);
  const [isMakeCallModalOpen, setIsMakeCallModalOpen] = useState(false);
  const [whatsappTemplateModal, setWhatsAppTemplateModal] = useState(false);
  const [loadeMoredata, setLoadeMoredata] = useState(false);
  // console.log(id, "id");

  useEffect(() => {
    setLoading(true);
    getOverviewData();
  }, [filterData]);

  const getOverviewData = () => {
    setLoading(true);

    // const editionValue = edition || filterData?.edition;
    // const editionValue = id !== ":id" ? id : filterData?.edition;

    fetchData(
      `customer/api/feedback/edition-feedback-list/?project=${
        filterData?.project || ""
      }&edition=${filterData?.edition || ""}
      `,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        setOverViewData(res?.data);
        setTotalRecords(res.count);
      } else {
        toast.error(res.message);
      }
    });
  };

  // Toggle collapse function
  const toggleCollapse = (contactId) => {
    setCollapseState((prevState) => ({
      ...prevState,
      [contactId]: !prevState[contactId],
    }));
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const tabs = [
    // { id: "tab1", label: "KPIs" },
    // { id: "tab2", label: "Overview" },
    { id: "tab3", label: "Details" },
    // { id: "tab4", label: "Feedback View", extraClass: "min-w-150px" },
  ];

  return (
    <>
      <div className="col-xxl-12">
        <div className="card card-xxl-stretch mb-5 mb-xl-8 ">
          {/* <div className="card-header ">
            <ul
              className="nav nav-pills nav-pills-custom position-relative mx-0 mb-0 nav-stretch"
              role="tablist"
            >
              {tabs.map((tab) => (
                <li
                  className="nav-item mx-0 p-0"
                  role="presentation"
                  key={tab.id}
                >
                  <div
                    className={`nav-link d-flex justify-content-center w-100 border-0 h-100 cursor-pointer ${
                      tab.extraClass || ""
                    } ${activeTab === tab.id ? "active" : ""}`}
                    data-bs-toggle="pill"
                    aria-selected={activeTab === tab.id}
                    role="tab"
                    onClick={() => handleTabClick(tab.id)}
                  >
                    <span className="nav-text text-gray-800 fw-bold fs-5 mb-0">
                      {tab.label}
                    </span>
                    <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-3px bg-primary rounded" />
                  </div>
                </li>
              ))}
            </ul>
            <div className="card-toolbar "></div>
          </div> */}
          <div className="card-header">
            <div className="d-flex flex-stack flex-wrap gap-4">
              <div className="position-relative mr-4">
                {/* <h2>Open Editions</h2> */}
              </div>
            </div>
            <div className="card-toolbar">
              <div className="position-relative me-3 mb-4 mb-sm-0 mb-md-0">
                <DebouncedSelect
                  apiEndpoint="api/project/"
                  value={filterData?.project}
                  onChange={(value) =>
                    setFilterData((prev) => {
                      return { ...prev, project: value };
                    })
                  }
                  placeholder="Select Project"
                  labelFormatter={(item) => item.name}
                  size="large"
                  style={{ width: "200px" }}
                  allowClear
                  onClear={() => {
                    setFilterData((prev) => {
                      return { ...prev, project: null };
                    });
                  }}
                />
              </div>
              <DebouncedSelect
                apiEndpoint="api/editions/editions-select/"
                value={filterData?.edition}
                onChange={(value) =>
                  setFilterData((prev) => {
                    return { ...prev, edition: value };
                  })
                }
                placeholder="Select Edition"
                labelFormatter={(item) => item.ip}
                size="large"
                style={{ width: "200px" }}
                allowClear
                onClear={() => {
                  setFilterData((prev) => {
                    return { ...prev, edition: null };
                  });
                }}
              />
            </div>
          </div>

          <div className="card-body py-4">
            <div className="tab-content">
              {/* tab 1 */}
              <div
                className={`tab-pane fade ${
                  activeTab === "tab1" ? "active show" : ""
                }`}
                id="kt_list_widget_10_tab_1"
                role="tabpanel"
              >
                <div className="container mt-4 mb-10">
                  {projects.map((project, index) => (
                    <div
                      key={index}
                      className="border-bottom p-4 mb-5"
                      style={{
                        boxShadow:
                          "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <h3 className="fw-bold fs-1">{project.name}</h3>
                        <span
                          className={`badge badge-light-${
                            project.status === "Ongoing" ? "success" : "danger"
                          } fs-6`}
                        >
                          {project.status}
                        </span>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <span className="me-2">
                              <i className="bi bi-play-fill text-primary fs-4" />
                            </span>
                            <span className="fw-semibold">
                              Achievement:
                              <span className="fw-bolder text-primary ms-1">
                                {project.achievement}
                              </span>
                              <span
                                className={`ms-1 fs-8 ${
                                  project.achievement > project.goal
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                (
                                {project.achievement > project.goal ? "+" : "-"}
                                {Math.abs(
                                  ((project.achievement - project.goal) /
                                    project.goal) *
                                    100
                                ).toFixed(2)}
                                %)
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <span className="me-2">
                              <i className="ki-outline ki-watch text-primary fs-4"></i>
                            </span>
                            <span
                              className="fw-semibold  mb-1 cursor-pointer"
                              onClick={() => setIsOpenModal(true)}
                            >
                              Pending:
                              <span className="text-primary fw-bold ms-1">
                                {project.pending}
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <span className="fw-semibold me-2">NPS Score:</span>
                            <span className="text-primary fw-bold">
                              {project.npsScore}%
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-3 mt-3">
                        <h5 className="fw-bold  text-gray-700 mb-2 fs-5">
                          Feedback Summery
                        </h5>
                        <div className="col-md-4">
                          <div className="d-flex justify-content-between mb-1">
                            <span className="fs-7">Positive</span>
                            <span className="fs-7">
                              {project.feedback.positive}%
                            </span>
                          </div>
                          <div className="progress" style={{ height: "8px" }}>
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{
                                width: `${project.feedback.positive}%`,
                              }}
                              aria-valuenow={project.feedback.positive}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex justify-content-between mb-1">
                            <span className="fs-7">Neutral</span>
                            <span className="fs-7">
                              {project.feedback.neutral}%
                            </span>
                          </div>
                          <div className="progress" style={{ height: "8px" }}>
                            <div
                              className="progress-bar bg-warning"
                              role="progressbar"
                              style={{
                                width: `${project.feedback.neutral}%`,
                              }}
                              aria-valuenow={project.feedback.neutral}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex justify-content-between mb-1">
                            <span className="fs-7">Negative</span>
                            <span className="fs-7">
                              {project.feedback.negative}%
                            </span>
                          </div>
                          <div className="progress" style={{ height: "8px" }}>
                            <div
                              className="progress-bar bg-danger"
                              role="progressbar"
                              style={{
                                width: `${project.feedback.negative}%`,
                              }}
                              aria-valuenow={project.feedback.negative}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="d-flex justify-content-end align-items-center mt-5">
                        <button
                          className="btn btn-primary"
                          onClick={() => setIsOpenModal(true)}
                        >
                          View Details
                        </button>
                      </div> */}
                    </div>
                  ))}
                </div>
              </div>

              {/* tab 2 OverView Tab */}
              <div
                className={`tab-pane fade ${
                  activeTab === "tab2" ? "active show" : ""
                }`}
                id="kt_list_widget_10_tab_2"
                role="tabpanel"
              >
                <div className="container mt-4">
                  {/* <div className="card mb-4">
                    <div className="card-body">
                      <h5 className="card-title text-gray-700">
                        NPS Score - 0%
                      </h5>
                      <div className="d-flex align-items-start justify-content-between">
                        <div className="d-flex justify-content-start gap-3 mt-3">
                          <div className="text-success">😃 Type 1</div>
                          <div className="text-warning">😐 Type m</div>
                          <div className="text-danger">😞 Type h</div>
                        </div>
                        <div>
                          <p className="text-primary fw-bold fs-3">
                            All Editions
                          </p>
                          <p>
                            Quality:{" "}
                            <span className="text-muted">(0 out of 0)</span>
                          </p>
                          <p>
                            Pending: <span className="text-muted">0</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="row">
                    <div className="col-md-6">
                      <div className="fw-bold fs-2 my-4">Overview</div>
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="text-gray-600 fw-semibold fs-5">
                              Rating:
                            </td>
                            <td className="text-end fw-bold fs-4">
                              {(overViewData?.overall_rating || 0).toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-gray-600 fw-semibold fs-5">
                              Overall vibe of Tribe:
                            </td>
                            <td className="text-end fw-bold fs-4">
                              {(
                                overViewData?.avg_overall_tribe_vibe || 0
                              ).toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-gray-600 fw-semibold fs-5">
                              Rate your Facilitators:
                            </td>
                            <td className="text-end fw-bold fs-4">
                              {(
                                overViewData?.avg_rate_facilitators || 0
                              ).toFixed(2)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-6">
                      <div className="fw-bold fs-2 my-4">Production</div>
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="text-gray-600 fw-semibold fs-5">
                              Hotel
                            </td>
                            <td className="text-end fw-bold fs-4">
                              {(overViewData?.avg_hotel || 0).toFixed(2)}
                            </td>
                          </tr>
                          {/* <tr>
                            <td className="text-gray-600 fw-semibold fs-5">
                              Food Design:
                            </td>
                            <td className="text-end fw-bold fs-4">
                              {(0.0).toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-gray-600 fw-semibold fs-5">
                              Room:
                            </td>
                            <td className="text-end fw-bold fs-4">
                              {(0.0).toFixed(2)}
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-12">
                      <div className="col-md-12">
                        <div className="fw-bold fs-2 my-4">Programming</div>
                        <table className="table table-sm">
                          <tbody>
                            {[
                              {
                                label: "I got what I was looking for",
                                value:
                                  overViewData?.avg_i_got_what_i_was_looking_for,
                              },
                              {
                                label:
                                  "How likely are you to recommend bucketlist to someone like you?",
                                value: overViewData?.avg_project_recommend,
                              },
                              {
                                label:
                                  "I felt well-prepared before the experience",
                                value: overViewData?.avg_felt_well_prepared,
                              },
                              {
                                label:
                                  "I broke free from the daily hustle in a meaningful way",
                                value:
                                  overViewData?.avg_broke_free_daily_hustle,
                              },
                              {
                                label: "I crossed things off my Bucketlist",
                                value: overViewData?.avg_crossed_off_project,
                              },
                              {
                                label:
                                  "I found a community of people I can vibe with",
                                value: overViewData?.avg_found_vibing_community,
                              },
                              {
                                label:
                                  "I made connections with people from diverse backgrounds",
                                value:
                                  overViewData?.avg_made_diverse_connections,
                              },
                              {
                                label: "I unlocked new core memories",
                                value: overViewData?.avg_unlocked_core_memories,
                              },
                              {
                                label:
                                  "I was able to expand my horizon and level up",
                                value: overViewData?.avg_expanded_horizon,
                              },
                              {
                                label:
                                  "I got the opportunity to reconnect, refresh & reset",
                                value:
                                  overViewData?.avg_opportunity_to_reconnect,
                              },
                              {
                                label:
                                  "I had moments where I felt infinite & possible",
                                value: overViewData?.avg_felt_infinite_possible,
                              },
                              {
                                label:
                                  "I gained new and interesting perspectives",
                                value: overViewData?.avg_gained_perspectives,
                              },
                            ].map((item, index) => (
                              <tr
                                key={index}
                                style={{ borderBottom: "1px dashed #ccc" }}
                              >
                                <td className="text-gray-600 fw-semibold fs-5">
                                  {item.label}
                                </td>
                                <td className="text-end fw-bold fs-4">
                                  {(item.value || 0).toFixed(2)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {/* <div className="fw-bold fs-2 mt-4">
                    What needs to be improved upon?
                  </div>
                  <table className="table rounded mt-4">
                    <thead className="">
                      <tr>
                        <th className="fs-5 text-gray-700 fw-bold">Item</th>
                        <th className="text-end fs-5 text-gray-700 fw-bold">
                          Count
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {[
                        { label: "Time Management", value: 0 },
                        { label: "Programme & Sessions", value: 0 },
                        { label: "Facilitator/s", value: 0 },
                        { label: "Curation of Residents", value: 0 },
                        { label: "Outdoor Experiences", value: 0 },
                        { label: "Food Quality", value: 0 },
                        { label: "Choice of Stay", value: 0 },
                        { label: "Rooms", value: 0 },
                        { label: "Property Staff", value: 0 },
                      ]?.map((item, index) => (
                        <tr
                          key={index}
                          style={{ borderBottom: "1px dashed #ccc" }}
                        >
                          <td className="text-gray-600 fw-semibold fs-5">
                            {item.label}
                          </td>
                          <td className="text-end fw-bold fs-4">
                            {item.value}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table> */}
                </div>
              </div>

              {/* tab 3 */}
              <div
                className={`tab-pane fade ${
                  activeTab === "tab3" ? "active show" : ""
                }`}
                id="kt_list_widget_10_tab_3"
                role="tabpanel"
              >
                <div
                  style={{
                    maxHeight: "1200px",
                    overflowY: "scroll",
                    marginBottom: "16px",
                    marginTop: "16px",
                  }}

                  // onScroll={handleScroll} // Attach the scroll event handler
                >
                  <div className="row row g-5 g-xxl-5">
                    {loading ? (
                      <div className="table-responsive">
                        <Loader cols={1} width={550} />
                      </div>
                    ) : (
                      <>
                        {overViewData?.length > 0 ? (
                          overViewData.map((user) => (
                            <div
                              key={user.user_details.uuid}
                              className="card mb-3"
                            >
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="d-flex justify-content-start align-items-start flex-wrap">
                                      <div>
                                        <div className="symbol symbol-40px me-0 me-md-5">
                                          <div className="symbol-label fs-2 fw-bold bg-light-danger text-danger">
                                            {user.user_details.first_name.charAt(
                                              0
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="d-flex flex-column justify-content-between gap-5 mt-2 mt-md-0 align-items-start">
                                        <div className="d-flex justify-content-between gap-4 gap-md-15 gap-lg-15 align-items-start">
                                          <div>
                                            <h5 className="card-title mb-0">
                                              {user.user_details.first_name}{" "}
                                              {user.user_details.last_name}
                                            </h5>
                                            <small className="text-muted">
                                              {user.project_name}
                                            </small>
                                          </div>
                                          <button className="btn btn-sm text-primary bg-light-primary fs-1 rounded-pill ms-10 ms-md-15">
                                            {user.user_details?.score === null
                                              ? "-"
                                              : user.user_details?.score}
                                            {/* {user.user_details.score} */}
                                          </button>
                                        </div>
                                        <div className="d-flex align-items-center">
                                          <div
                                            className="btn-group"
                                            role="group"
                                          >
                                            <button
                                              type="button"
                                              className="btn btn-warning btn-sm"
                                              onClick={() =>
                                                toggleCollapse(user.booking)
                                              }
                                            >
                                              View Feedback
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-3 mt-2 mt-md-0">
                                    <p className="mb-0">
                                      <strong>Email:</strong>{" "}
                                      {user.user_details.email}
                                    </p>
                                    <p className="mb-0">
                                      <strong>Age:</strong>{" "}
                                      {new Date().getFullYear() -
                                        new Date(
                                          user.user_details.date_of_birth
                                        ).getFullYear()}
                                    </p>
                                    <p className="mb-0">
                                      <strong>Revenue:</strong>{" "}
                                      {user.user_details.revenue}
                                    </p>
                                  </div>

                                  <div className="col-md-2">
                                    <p className="mb-0">
                                      <strong>Score:</strong>{" "}
                                      {user.user_details?.score === null
                                        ? "-"
                                        : user.user_details?.score}
                                    </p>
                                    <p className="mb-0">
                                      <strong>Category:</strong>{" "}
                                      {user.user_details.category}
                                    </p>
                                    <p className="mb-0">
                                      <strong>Bio:</strong>{" "}
                                      {user.user_details.bio}
                                    </p>
                                  </div>

                                  <div className="col-md-3 mt-4 mt-md-0">
                                    <div className="d-flex flex-column justify-content-start gap-5 align-items-start">
                                      <div className="d-flex justify-content-between align-items-center gap-15">
                                        {user.user_details.phone ? (
                                          <Popover
                                            content={
                                              <>
                                                <div>
                                                  <Button
                                                    type="link"
                                                    className="text-dark fw-bold"
                                                    onClick={() => {
                                                      setWhatsAppTemplateModal(
                                                        true
                                                      );
                                                      setSelected(user);
                                                    }}
                                                  >
                                                    WhatsApp Template
                                                  </Button>
                                                  <br />
                                                  <Button
                                                    type="link"
                                                    className="text-dark fw-bold"
                                                    href={`https://wa.me/${user.user_details.phone}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    Open in WhatsApp
                                                  </Button>
                                                </div>
                                              </>
                                            }
                                            trigger="hover"
                                          >
                                            <div className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3">
                                              <i
                                                className="bi bi-whatsapp fs-4 text-success"
                                                style={{ cursor: "pointer" }}
                                              />
                                            </div>
                                          </Popover>
                                        ) : (
                                          <div
                                            className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                            disabled
                                            style={{
                                              cursor: "not-allowed",
                                              opacity: "0.5",
                                            }}
                                          >
                                            <i className="bi bi-whatsapp fs-2 text-success" />
                                          </div>
                                        )}

                                        <button className="btn btn-sm">
                                          <i
                                            className="bi bi-telephone fs-4 text-info"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              setSelected(user);
                                              setIsMakeCallModalOpen(true);
                                            }}
                                          />
                                        </button>
                                      </div>
                                      <div className="text-muted">
                                        {user.user_details.email}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* Collapsible Content */}
                                <div
                                  id={`collapseContent${user.booking}`}
                                  className={`collapse mt-3 ${
                                    collapseState[user.booking] ? "show" : ""
                                  }`}
                                >
                                  <div className="container mt-10">
                                    <div className="container mt-10">
                                      <h4 className="text-gray-700 fw-bold fs-2">
                                        Project Feedback
                                      </h4>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <p>
                                            <span className="text-gray-600">
                                              Project Recommend:
                                            </span>{" "}
                                            {user?.project_recommend}
                                          </p>
                                          <p>
                                            <span className="text-gray-600">
                                              Project Review:
                                            </span>{" "}
                                            {user?.project_review || "N/A"}
                                          </p>
                                          <p>
                                            <span className="text-gray-600">
                                              Got What I Was Looking For:
                                            </span>{" "}
                                            {user?.i_got_what_i_was_looking_for}
                                          </p>
                                          <p>
                                            <span className="text-gray-600">
                                              Felt Well Prepared:
                                            </span>{" "}
                                            {user?.felt_well_prepared}
                                          </p>
                                          <p>
                                            <span className="text-gray-600">
                                              Broke Free from Daily Hustle:
                                            </span>{" "}
                                            {user?.broke_free_daily_hustle}
                                          </p>
                                          <p>
                                            <span className="text-gray-600">
                                              Crossed Off Project:
                                            </span>{" "}
                                            {user?.crossed_off_project}
                                          </p>
                                        </div>
                                        <div className="col-md-6">
                                          <p>
                                            <span className="text-gray-600">
                                              Found Vibing Community:
                                            </span>{" "}
                                            {user?.found_vibing_community}
                                          </p>
                                          <p>
                                            <span className="text-gray-600">
                                              Made Diverse Connections:
                                            </span>{" "}
                                            {user?.made_diverse_connections}
                                          </p>
                                          <p>
                                            <span className="text-gray-600">
                                              Unlocked Core Memories:
                                            </span>{" "}
                                            {user?.unlocked_core_memories}
                                          </p>
                                          <p>
                                            <span className="text-gray-600">
                                              Expanded Horizon:
                                            </span>{" "}
                                            {user?.expanded_horizon}
                                          </p>
                                          <p>
                                            <span className="text-gray-600">
                                              Opportunity to Reconnect:
                                            </span>{" "}
                                            {user?.opportunity_to_reconnect}
                                          </p>
                                          <p>
                                            <span className="text-gray-600">
                                              Felt Infinite Possibilities:
                                            </span>{" "}
                                            {user?.felt_infinite_possible}
                                          </p>
                                          <p>
                                            <span className="text-gray-600">
                                              Gained Perspectives:
                                            </span>{" "}
                                            {user?.gained_perspectives}
                                          </p>
                                        </div>
                                      </div>

                                      <h4 className="text-gray-700 fw-bold fs-2 mt-4">
                                        Ratings
                                      </h4>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <p>
                                            <span className="text-gray-600">
                                              Overall Tribe Vibe:
                                            </span>{" "}
                                            {user?.overall_tribe_vibe}
                                          </p>
                                          <p>
                                            <span className="text-gray-600">
                                              Rate Facilitators:
                                            </span>{" "}
                                            {user?.rate_facilitators}
                                          </p>
                                          <p>
                                            <span className="text-gray-600">
                                              Review Facilitators:
                                            </span>{" "}
                                            {user?.review_facilitators || "N/A"}
                                          </p>
                                        </div>
                                        <div className="col-md-6">
                                          <p>
                                            <span className="text-gray-600">
                                              Rate Hotel:
                                            </span>{" "}
                                            {user?.rate_hotel}
                                          </p>
                                          <p>
                                            <span className="text-gray-600">
                                              Overall Rating:
                                            </span>{" "}
                                            {user?.overall_rating}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="row">
                                  <div className="col-md-6">
                                    <div className="mb-4">
                                      <div className="text-gray-700 fw-bold fs-2">
                                        Facilitators
                                      </div>
                                      <div className="text-muted fw-semibold">
                                        Rating: {user.rate_facilitators}
                                      </div>
                                      <div className="text-muted fw-semibold">
                                        Review: {user.review_facilitators}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="mb-4">
                                      <div className="text-gray-700 fw-bold fs-2">
                                        Production Ratings
                                      </div>
                                      <div className="text-muted fw-semibold">
                                        Hotel: {user.rate_hotel}
                                      </div>
                                      <div className="text-muted fw-semibold">
                                        Overall Experience:{" "}
                                        {user.overall_rating}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="mb-4">
                                    <h4 className="text-gray-700 fw-bold fs-2">
                                      Programming
                                    </h4>
                                    <ul className="list-unstyled">
                                      <li className="text-muted fw-semibold fs-5 mb-1">
                                        <span className="bullet bullet-dot bg-primary me-2 mb-1 h-7px w-7px"></span>
                                        I got what I was looking for:{" "}
                                        {user.i_got_what_i_was_looking_for
                                          ? "Yes"
                                          : "No"}
                                      </li>
                                      <li className="text-muted fw-semibold fs-5 mb-1">
                                        <span className="bullet bullet-dot bg-primary me-2 mb-1 h-7px w-7px"></span>
                                        I found a vibing community:{" "}
                                        {user.found_vibing_community
                                          ? "Yes"
                                          : "No"}
                                      </li>
                                      <li className="text-muted fw-semibold fs-5 mb-1">
                                        <span className="bullet bullet-dot bg-primary me-2 mb-1 h-7px w-7px"></span>
                                        I gained new perspectives:{" "}
                                        {user.gained_perspectives
                                          ? "Yes"
                                          : "No"}
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="mb-4">
                                    <h4 className="text-gray-700 fw-bold fs-2">
                                      Subjective Questions
                                    </h4>
                                    <p className="text-muted fw-semibold fs-6">
                                      Experience in Two Words:{" "}
                                      {user.experience_in_two_words || "N/A"}
                                    </p>
                                    <p className="text-muted fw-semibold fs-6">
                                      Additional Notes: {user.note || "N/A"}
                                    </p>
                                  </div>
                                </div> */}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="row g-5 mb-0 gx-md-10">
                            <div className="col-md-12 text-center">
                              <div className="mt-0">
                                <img
                                  src="/media/no-data-found.png"
                                  className="mw-25"
                                  alt="No Data Found"
                                />
                              </div>
                              <h1 className="mt-4">No Data Found</h1>
                            </div>
                          </div>
                        )}

                        {loadeMoredata && (
                          <div style={{ textAlign: "center", padding: "20px" }}>
                            <Spin />
                          </div>
                        )}
                        {overViewData?.length > 0 && (
                          <>
                            {overViewData?.length >= totalRecords &&
                              !loadeMoredata && (
                                <div className="fs-1 text-center text-muted mt-">
                                  You've reached the end of the list
                                </div>
                              )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>

              {/* tab 4 */}
              <div
                className={`tab-pane fade ${
                  activeTab === "tab4" ? "active show" : ""
                }`}
                id="kt_list_widget_10_tab_4"
                role="tabpanel"
              >
                <label htmlFor="" className="fs-6 fw-bold mb-2 mt-4">
                  Feedback View Tab
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpenModal && (
        <FeedbackModal
          show={isOpenModal}
          onHide={() => {
            setIsOpenModal(false);
          }}
        />
      )}

      {whatsappTemplateModal && (
        <WhatsappTemplateModal
          show={whatsappTemplateModal}
          onHide={() => {
            setWhatsAppTemplateModal(false);
          }}
          data={{
            user_data: selected?.user_details,
            edition: selected?.edition,
          }}
        />
      )}

      {isMakeCallModalOpen && (
        <MakeCallModal
          show={isMakeCallModalOpen}
          onHide={() => {
            setIsMakeCallModalOpen(false);
          }}
          data={{
            to_number: selected?.user_details?.phone,
          }}
        />
      )}
    </>
  );
}
