import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { validateEmail } from "../../config";
import { fetchData } from "../../config/service";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [showPass1, setShowPass1] = useState(false);
  const [showPass2, setShowPass2] = useState(false);

  const sendOTP = () => {
    if (!email || !validateEmail(email)) {
      if (!email) {
        toast.error("Please enter your email!");
      } else if (!validateEmail(email)) {
        toast.error("Please enter valid email!");
      }
    } else {
      setWaiting(true);
      const body = {
        email: email,
      };
      fetchData(
        "api/accounts/registrations/forgot-password/",
        "post",
        body
      ).then((res) => {
        setWaiting(false);
        if (res.success) {
          toast.success(res.message);
          setOtpSent(true);
        } else {
          toast.error(res.message);
        }
      });
    }
  };

  const verifyOtp = () => {
    if (!otp || otp.length !== 4) {
      if (!otp) {
        toast.error("Please enter OTP!");
      } else {
        toast.error("Please enter valid OTP!");
      }
    } else {
      setWaiting(true);
      const body = {
        email: email,
        otp: otp,
      };
      fetchData(
        "api/accounts/registrations/forgot-password-otp-verify/",
        "post",
        body
      ).then((res) => {
        setWaiting(false);
        // alert("ok");
        // console.log(res);
        if (res.success) {
          setOtpVerified(true);
          // toast.success("Successfully logged in!");
          // dispatch(setUser(res));
        } else {
          toast.error(res.message);
        }
      });
    }
  };

  const resetPassword = () => {
    if (!password || !confirmPassword || password !== confirmPassword) {
      if (!password) {
        toast.error("Please enter password!");
      } else if (!confirmPassword) {
        toast.error("Please enter confirm password!");
      } else if (password !== confirmPassword) {
        toast.error("Passwords do not match!");
      }
    } else {
      setWaiting(true);
      const body = {
        email: email,
        password: password,
        confirm_password: confirmPassword,
      };
      fetchData(
        "api/accounts/registrations/create-new-password/",
        "post",
        body
      ).then((res) => {
        setWaiting(false);
        if (res.success) {
          toast.success(res.message);
          navigate("/login");
        } else {
          toast.error(res.message);
        }
      });
    }
  };

  return (
    <div className="auth-bg">
      <div className="d-flex flex-column flex-root">
        <div
          className="d-flex flex-column flex-lg-row flex-column-fluid"
          style={{ height: "100vh" }}
        >
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
            <div className="d-flex flex-center flex-column flex-lg-row-fluid">
              <div className="w-lg-500px p-10">
                <div className=" mb-10">
                  <Link
                    to={"/login"}
                    className=" text-dark d-flex align-items-center justify-content-center"
                  >
                    <img
                      alt="Logo"
                      src="/media/icon-bhx.png"
                      className="h-20px h-lg-90px theme-light-show "
                    />
                  </Link>
                </div>
                <div className="form w-100">
                  <div className="text-center mb-11">
                    <h1 className="text-gray-900 fw-bolder mb-3">
                      Welcome to Experience Co.
                    </h1>
                    <div className="text-gray-500 fw-semibold fs-6">
                      {otpSent
                        ? otpVerified
                          ? "Please enter your new password."
                          : `We just sent an OTP to your registered email.`
                        : "Please enter your email."}
                    </div>
                  </div>
                  {otpSent ? (
                    <div>
                      {otpVerified ? (
                        <div>
                          <div className="fv-row mb-3 password-container">
                            <input
                              type={showPass1 ? "text" : "password"}
                              placeholder="New Password"
                              className="form-control bg-transparent h-60px border-gray-400 ps-7"
                              value={password}
                              onChange={(e) => {
                                setPassword(e.target.value);
                              }}
                            />
                            <span
                              className="eye-password"
                              onClick={() => {
                                setShowPass1(!showPass1);
                              }}
                            >
                              <i
                                className={`fs-1 ki-outline ${
                                  showPass1 ? "ki-eye-slash" : "ki-eye"
                                }  me-2`}
                              />
                            </span>
                          </div>
                          <div className="fv-row mb-3 password-container">
                            <input
                              type={showPass2 ? "text" : "password"}
                              placeholder="Confirm Password"
                              className="form-control bg-transparent h-60px border-gray-400 ps-7"
                              value={confirmPassword}
                              onChange={(e) => {
                                setConfirmPassword(e.target.value);
                              }}
                            />
                            <span
                              className="eye-password"
                              onClick={() => {
                                setShowPass2(!showPass2);
                              }}
                            >
                              <i
                                className={`fs-1 ki-outline ${
                                  showPass2 ? "ki-eye-slash" : "ki-eye"
                                }  me-2`}
                              />
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="fv-row mb-8">
                          <input
                            type="text"
                            placeholder="OTP"
                            className="form-control bg-transparent h-60px border-gray-400 ps-7"
                            value={otp}
                            onChange={(e) => {
                              setOtp(e.target.value);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <div className="fv-row mb-8">
                        <input
                          type="email"
                          placeholder="Email"
                          className="form-control bg-transparent h-60px border-gray-400 ps-7"
                          value={email}
                          onChange={(e) => {
                            const emailValue = e.target.value.toLowerCase();
                            setEmail(emailValue);
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {/*end::Wrapper*/}
                  {/*begin::Submit button*/}
                  <div className="d-grid mb-10">
                    <button
                      className="btn btn-primary h-45px"
                      onClick={
                        otpSent
                          ? otpVerified
                            ? resetPassword
                            : verifyOtp
                          : sendOTP
                      }
                      disabled={waiting}
                    >
                      {waiting ? (
                        <span>
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>
                      ) : (
                        <span className="indicator-label">
                          {otpSent
                            ? otpVerified
                              ? "Reset Password"
                              : "Verify"
                            : "Send OTP"}
                        </span>
                      )}
                    </button>
                  </div>
                  {/*end::Submit button*/}
                  {/*begin::Sign up*/}
                  <div className="text-gray-500 text-center fw-semibold fs-6">
                    Not a Member yet?
                    <Link to="/register" className="link-primary">
                      {" "}
                      Sign up
                    </Link>
                  </div>
                  {/*end::Sign up*/}
                </div>
                {/*end::Form*/}
              </div>
              {/*end::Wrapper*/}
            </div>
            {/*end::Form*/}
          </div>
          {/*end::Body*/}
          {/*begin::Aside*/}
          <div
            className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
            style={{
              backgroundImage: "url(/media/bg1.png)",
              borderRadius: "170px 0px 0px 0px",
            }}
          >
            {/*begin::Content*/}
            <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100"></div>
            {/*end::Content*/}
          </div>
          {/*end::Aside*/}
        </div>
        {/*end::Authentication - Sign-in*/}
      </div>
    </div>
  );
}
