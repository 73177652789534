import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { message, Select, Input } from "antd";
import { fetchData } from "../../../../config/service";
import DebouncedSelect from "../../../../components/debounced-select";

export default function RoomAssignModal({
  show,
  statusList,
  onHide,
  data,
  forUserPage = false,
}) {
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customerData, setCustomerData] = useState({
    user: null,
    hotel: null,
    room_number: "",
  });

  const [hotelList, setHotelList] = useState([]);
  const [assignId, setAssignId] = useState(null);

  useEffect(() => {
    if (data) {
      setHotelList(
        data?.hotels?.map((e) => {
          return {
            label: e.name,
            value: e.id,
          };
        })
      );
    }
  }, [data]);

  useEffect(() => {
    if (show) {
      getRoomManagerDetails();
    }
  }, [show]);

  const getRoomManagerDetails = () => {
    setLoading(true);
    fetchData(
      `api/room-manager/add-rooms/?edition=${data?.edition}&user=${data?.user?.uuid}`,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        const { success, ...data } = res; // Remove success from response

        if (data[0]?.id) {
          setAssignId(data[0]?.id);
          const users = data[0]?.users?.map((e) => e.uuid);
          console.log(users, "users");
          setCustomerData({
            ...customerData,
            user: users[0],
            hotel: data[0]?.hotel?.id,
            room_number: data[0]?.room_number,
          });
        } else {
          setCustomerData({
            ...customerData,
            user: null,
            hotel: null,
            room_number: "", // Reset room_number if no room data
          });
          setAssignId(null);
        }
      } else {
        toast.error(res.message);
      }
    });
  };

  const onSubmitUser = async () => {
    setSending(true);

    const body = {
      edition: data?.edition,
      users: [data?.user?.uuid, customerData?.user],
      ...(!forUserPage && { hotel: customerData?.hotel }),
      ...(!forUserPage && { room_number: customerData?.room_number }),
    };

    console.log(customerData, "customerData");

    console.log(body, "body");

    const root = forUserPage
      ? "api/room-manager/pair-user"
      : "api/room-manager/add-rooms";

    const path = assignId ? `${root}/${assignId}/` : `${root}/`;

    const method = assignId ? "put" : "post";

    console.log(body, "body", path, method);

    fetchData(path, method, body).then((res) => {
      setSending(false);
      if (res.success) {
        onHide();
        toast.success(res?.message || "Success");
      } else {
        toast.error(res.message);
      }
    });
  };

  const setCustomerDataValue = (key, value) => {
    const common_data = {
      ...customerData,
      [key]: value,
    };

    setCustomerData(common_data);
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">
            <span className="text-primary">
              {data?.user?.first_name + " " + data?.user?.last_name + " "}
            </span>
            is Travelling with
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row g-9 align-items-center">
          <div className="col-12">
            <label>
              <span className="required fw-semibold fs-5">Select User</span>
            </label>

            <DebouncedSelect
              apiEndpoint="customer/api/customer/"
              //   mode="multiple"
              value={customerData?.user}
              onChange={(value) => setCustomerDataValue("user", value)}
              placeholder="Select User"
              labelFormatter={(item) => item.first_name + " " + item.last_name}
              valueFormatter={(item) => item.uuid}
              size="large"
              style={{ width: "100%" }}
              dropdownStyle={{ zIndex: 1111 }}
              allowClear
              onClear={() => {
                setCustomerDataValue("user", null);
              }}
              additionalParams={{
                edition: data?.edition,
                exclude_user: data?.user?.uuid,
              }}
            />
          </div>
          {!forUserPage && (
            <>
              <div className="col-12">
                <label>
                  <span className="fw-semibold fs-5">Select Hotel</span>
                </label>

                <Select
                  value={customerData?.hotel}
                  onChange={(value) => setCustomerDataValue("hotel", value)}
                  placeholder="Select Hotel"
                  style={{ width: "100%" }}
                  dropdownStyle={{ zIndex: 1111 }}
                  options={hotelList}
                  size="large"
                />
              </div>

              <div className="col-12">
                <label>
                  <span className="fw-semibold fs-5">Room Number</span>
                </label>
                <Input
                  value={customerData?.room_number}
                  onChange={(e) =>
                    setCustomerDataValue("room_number", e.target.value)
                  }
                  placeholder="Enter Room Number"
                  size="large"
                />
              </div>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => onSubmitUser()}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
