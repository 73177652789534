import React, { useEffect, useState } from "react";
import { fetchData } from "../../../../config/service";
import toast from "react-hot-toast";
import { formatNumber } from "../../../../config";
import { Tooltip } from "antd";
import dayjs from "dayjs";
import ChangeAmountModal from "../../modals/change-amount";

const Payment = ({ editionData, getEditionsLitFromBookingData }) => {
  const [paymentDetailsLoading, setPaymentDetailsLoading] = useState(false);
  const [paymentsDetailData, setPaymentsDetailsData] = useState([]);
  const [bookingId, setBookingId] = useState(null);
  const [changeAmountModal, setChangeAmountModal] = useState(false);
  const [visaFee, setVisaFee] = useState(0);
  const [updateVisaFeeLoading, setUpdateVisaFeeLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [selectedModalData, setSelectedModalData] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const [grantValue, setGrantValue] = useState(0);
  const [updateGrantLoading, setUpdateGrantLoading] = useState(false);

  useEffect(() => {
    setBookingId(editionData?.id);
    if (editionData?.id) {
      getPaymentDetails(editionData?.id);
    }
  }, [editionData]);

  useEffect(() => {
    setIsEditable(false);
  }, [editionData]);

  const getPaymentDetails = (bookingId) => {
    const body = {
      booking_id: `${bookingId}`,
    };

    setPaymentDetailsLoading(true);
    fetchData(`api/booking/payments-details/`, "post", body).then((res) => {
      setPaymentDetailsLoading(false);
      if (res.success) {
        setPaymentsDetailsData(res);
        setVisaFee(res?.visa_fee);
        setGrantValue(res?.grant);
      } else {
        setPaymentsDetailsData({});
        toast.error(res.message);
      }
    });
  };

  const handleKeyDown = (event, type) => {
    if (type === "grant") {
      if (event.key === "Enter") {
        // Call the API here
        setUpdateGrantLoading(true);
        toast.loading("Updating Grant Value...");
        updateGrantValue();
      }
    } else if (type === "visa") {
      if (event.key === "Enter") {
        // Call the API here
        setUpdateVisaFeeLoading(true);
        toast.loading("Updating Visa Fee...");
        updateVisaFee();
      }
    }
  };

  const updateVisaFee = () => {
    const body = {
      visa_fee: visaFee,
    };

    fetchData(
      `customer/api/visa/visa-information-admin-details/${paymentsDetailData?.visa_id}/`,
      "PATCH",
      body
    ).then((res) => {
      toast.dismiss();
      setUpdateVisaFeeLoading(false);
      if (res.success) {
        toast.success("Visa Fee Updated Successfully");
        setIsEditable(false);
        getPaymentDetails(bookingId);
      } else {
        toast.error(res.message);
      }
    });
  };

  const updateGrantValue = () => {
    const body = {
      booking_id: `${editionData?.id}`,
      travel_grant: grantValue,
    };

    // console.log(body, "body");
    fetchData(`api/payment/edit-travel-grant/`, "post", body).then((res) => {
      toast.dismiss();
      setUpdateGrantLoading(false);
      if (res.success) {
        toast.success("Grant Value Updated Successfully");
        setIsEditable(false);
        getPaymentDetails(bookingId);
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <div className="card card-flush mb-6 mb-xl-9">
      <div className="card-header">
        <div className="card-title flex-column">
          <h2 className="mb-1">Payments</h2>
        </div>

        <div className="card-toolbar">
          {/* <div className="fs-5">
            Payments Made: <b>NA</b>
          </div> */}
          {/* <button type="button" class="btn btn-light-primary btn-sm" data-bs-toggle="modal"
                                              data-bs-target="#kt_modal_add_schedule">
                                              <i class="ki-duotone ki-brush fs-3"><span class="path1"></span><span
                                                      class="path2"></span></i> Add Schedule
                                          </button> */}
        </div>
      </div>

      <div className="card-body p-9 pt-2">
        <div className=" row g-5 mb-0 gx-md-10">
          <div className="col-md-6">
            <div className="bg-light-primary p-4 px-5 rounded mb-5 bdr-left-user">
              <div className="d-flex align-items-center flex-stack">
                <div className="fs-5 fw-bold">Total Fee:</div>
                <div className="fs-4 fw-bolder text-primary">
                  {" "}
                  {formatNumber(paymentsDetailData?.total_fee) || 0}
                  {/* {formatNumber(
                      parseInt(paymentsDetailData?.edition_cost || 0) +
                        parseInt(paymentsDetailData?.dynamic_pricing || 0) -
                        parseInt(paymentsDetailData?.grant || 0) +
                        parseInt(paymentsDetailData?.reschedule_fee || 0) +
                        parseInt(paymentsDetailData?.visa_fee || 0)
                    )} */}
                </div>
              </div>
              <div className="separator separator-dashed my-3 " />
              <div className=" mt-1">
                <small>
                  <small className="fs-8">
                    Visible to User = Fee for Edition + Dynamic Pricing (if any)
                    - Grant + Reschedule Fees (if any) + Visa Fee
                  </small>
                </small>
              </div>
            </div>
            <div className="bg-light-primary p-4 px-5 rounded bdr-left-user">
              <div className="d-flex align-items-center flex-stack">
                <div className="fs-5 fw-bold">Pending Fee:</div>
                <div className="fs-4 fw-bolder text-primary">
                  {formatNumber(paymentsDetailData?.pending_fee) || 0}
                </div>
              </div>
              <div className="separator separator-dashed my-3 " />
              <div className=" mt-1">
                <small>
                  Due Date:{" "}
                  {paymentsDetailData?.due_date
                    ? dayjs(
                        paymentsDetailData?.edition_details?.start_date
                      ).format("DD-MM-YYYY h:mm A")
                    : "-"}
                </small>
              </div>
            </div>
            <div className="mt-10">
              {paymentsDetailData?.transactions &&
                paymentsDetailData?.transactions.length > 0 && (
                  <h4 className="mb-8 fs-4 fw-bold">Transaction Status</h4>
                )}

              {paymentsDetailData?.transactions &&
              paymentsDetailData?.transactions.length > 0 ? (
                <div className="card">
                  <div className="card-body p-0">
                    <div
                      style={{ maxHeight: "400px", overflowY: "auto" }}
                      className="px-5"
                    >
                      {paymentsDetailData.transactions.map((item, i) => (
                        <div key={item.id}>
                          <div className="d-flex flex-row justify-content-between align-items-center py-4">
                            <div className="d-flex flex-column gap-1">
                              <div className="fs-7 text-gray-800">
                                <span className="fw-bold me-1">Made on:</span>
                                <span className="text-gray-600">
                                  {item?.created_at
                                    ? dayjs(item.created_at).format(
                                        "DD MMM YYYY"
                                      )
                                    : "-"}
                                </span>
                              </div>
                              <div className="fs-7 text-gray-800">
                                <span className="fw-bold me-1">Amount:</span>
                                <span className="text-gray-700">
                                  {item?.amount
                                    ? formatNumber(item.amount)
                                    : "-"}
                                </span>
                              </div>
                              <div
                                className="fs-7 text-gray-800"
                                style={{
                                  width: "250px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <span className="fw-bold me-1">Comment:</span>
                                <Tooltip title={item?.comment}>
                                  <span className="text-gray-700 cursor-pointer">
                                    {item?.comment || "-"}
                                  </span>
                                </Tooltip>
                              </div>
                            </div>
                            <div className="d-flex align-items-center gap-5">
                              <span
                                className={`badge ${
                                  item?.status?.toLowerCase() === "success"
                                    ? "badge-light-success"
                                    : item?.status?.toLowerCase() === "failed"
                                    ? "badge-light-danger"
                                    : "badge-light-dark"
                                }`}
                              >
                                {item?.status || "-"}
                              </span>
                              {item?.transaction_type === 3 && (
                                <Tooltip title="Change Amount">
                                  <div
                                    onClick={() => {
                                      setSelectedModalData(item);
                                      setPaymentId(item?.id);
                                      setChangeAmountModal(!changeAmountModal);
                                    }}
                                    className="cursor-pointer"
                                  >
                                    <i className="ki-outline ki-pencil fs-4 text-primary"></i>
                                  </div>
                                </Tooltip>
                              )}
                            </div>
                          </div>

                          {i < paymentsDetailData.transactions.length - 1 && (
                            <div className="separator separator-dashed"></div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="mt-10">
              {/* <h4 className="mb-8">BHX Controls</h4> */}
              {/* <div className="mb-3">
              <a href className="fs-6 fw-semibold">
                Add First Payment
              </a>
            </div>
            <div className="mb-3">
              <a href className="fs-6 fw-semibold">
                Add Second Payment
              </a>
            </div>
            <div className="mb-10">
              <a href className="fs-6 fw-semibold">
                Add Third Payment
              </a>
            </div> */}
              {/* <div className="mb-3">
                  <a href className="fs-6 fw-semibold">
                    Add / Edit Reschedule Fees
                  </a>
                </div>
                <div className="mb-10">
                  <a href className="fs-6 fw-semibold">
                    Add Room Addon
                  </a>
                </div>
                <div className="mb-3">
                  <a href className="fs-6 fw-semibold">
                    Create Invoice for User
                  </a>
                </div>
                <div className="mb-3">
                  <a href className="fs-6 fw-semibold text-danger">
                    Give a Refund (Razorpay Connected)
                  </a>
                </div>
                <div className="mb-3">
                  <a href className="fs-6 fw-semibold">
                    Find New Edition Fee
                  </a>
                </div> */}
            </div>
          </div>

          <div className="col-md-6">
            <h4 className="mb-8">Breakdown</h4>
            <div className="d-flex flex-stack">
              <div className="fs-6">Edition Fee:</div>
              <div className="fs-6 fw-bolder">
                {formatNumber(paymentsDetailData?.edition_cost) || 0}
              </div>
            </div>
            <div className="separator separator-dashed my-4 " />
            <div className="d-flex flex-stack">
              <div className="fs-6">
                <span className="cursor-pointer">
                  {" "}
                  GST Amount:({paymentsDetailData?.gst}%)
                </span>
              </div>
              <div className="fs-6 fw-bolder">
                {formatNumber(paymentsDetailData?.gst_amount) || 0}
              </div>
            </div>

            <div className="separator separator-dashed my-4 " />

            <div className="d-flex flex-stack">
              <div className="fs-6">
                <span className="cursor-pointer"> GST</span>
              </div>
              <div className="fs-6 fw-bolder">
                {formatNumber(paymentsDetailData?.tax_breakdown?.gst, false) ||
                  0}
                %
              </div>
            </div>

            <div className="separator separator-dashed my-4 " />

            <div className="d-flex flex-stack">
              <div className="fs-6">
                <span className="cursor-pointer"> CGST</span>
              </div>
              <div className="fs-6 fw-bolder">
                {formatNumber(paymentsDetailData?.tax_breakdown?.cgst, false) ||
                  0}
                %
              </div>
            </div>

            <div className="separator separator-dashed my-4 " />

            <div className="d-flex flex-stack">
              <div className="fs-6">
                <span className="cursor-pointer"> SGST</span>
              </div>
              <div className="fs-6 fw-bolder">
                {formatNumber(paymentsDetailData?.tax_breakdown?.sgst, false) ||
                  0}
                %
              </div>
            </div>

            <div className="separator separator-dashed my-4 " />

            <div className="d-flex flex-stack">
              <div className="fs-6">
                <span className="cursor-pointer"> TCS</span>
              </div>
              <div className="fs-6 fw-bolder">
                {formatNumber(paymentsDetailData?.tax_breakdown?.tcs, false) ||
                  0}
                %
              </div>
            </div>

            <div className="separator separator-dashed my-4 " />

            <div className="d-flex flex-stack">
              <div className="fs-6">Grant:</div>
              <div className="fs-6 fw-bolder">
                {/* {formatNumber(paymentsDetailData?.grant) || 0} */}
                <div className="fs-6 fw-bolder">
                  <div className="position-relative d-flex align-items-center">
                    <span className="d-flex flex-stack">
                      {isEditable ? (
                        <input
                          value={grantValue}
                          type="text"
                          className="border form-control-sm w-150px fs-6"
                          placeholder="Enter Visa Fee"
                          onChange={(e) => setGrantValue(e.target.value)}
                          onKeyDown={(e) => {
                            if (
                              e.key === "Enter" &&
                              Number(grantValue) >
                                paymentsDetailData?.pending_fee
                            ) {
                              toast.error(
                                "Grant value can not be greater than pending fee."
                              );
                            } else {
                              handleKeyDown(e, "grant");
                            }
                          }}
                          disabled={updateGrantLoading || paymentDetailsLoading}
                          title="Press Enter to save"
                        />
                      ) : (
                        <div className="text-primary fw-bold">
                          {formatNumber(paymentsDetailData?.grant) || 0}
                        </div>
                      )}

                      {
                        <>
                          {isEditable ? (
                            <div className="d-flex align-items-center">
                              <Tooltip title="Save">
                                <span
                                  className="cursor-pointer d-flex align-items-center"
                                  onClick={() => {
                                    if (
                                      Number(grantValue) >
                                      paymentsDetailData?.pending_fee
                                    ) {
                                      toast.error(
                                        "Grant value can not be greater than pending fee."
                                      );
                                    } else {
                                      updateGrantValue();
                                    }
                                  }}
                                  style={{ marginLeft: "8px" }}
                                  // title="Save"
                                >
                                  <i className="ki-outline ki-check fs-2 text-dark"></i>
                                </span>
                              </Tooltip>
                              <span
                                className="cursor-pointer d-flex align-items-center"
                                onClick={() => {
                                  setIsEditable(false);
                                }}
                                style={{ marginLeft: "8px" }}
                                // title="Save"
                              >
                                <i className="ki-outline ki-cross fs-2 text-dark"></i>
                              </span>
                            </div>
                          ) : (
                            <Tooltip title="Edit">
                              {" "}
                              <span
                                className="cursor-pointer d-flex align-items-center"
                                onClick={() => setIsEditable(!isEditable)}
                                style={{ marginLeft: "8px" }}
                                // title="Edit"
                              >
                                <i className="fa-solid fa-edit fs-5 text-dark ps-2"></i>
                              </span>
                            </Tooltip>
                          )}
                        </>
                      }
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="separator separator-dashed my-4 " />
            <div className="d-flex flex-stack">
              <div className="fs-6">Tribe Discount:</div>

              <div className="fs-6 fw-bolder">
                {formatNumber(paymentsDetailData?.tribe_discount) || 0}
              </div>
            </div>
            <div className="separator separator-dashed my-4 " />
            <div className="d-flex flex-stack">
              <div className="fs-6">Dynamic Pricing:</div>
              <div className="fs-6 fw-bolder">
                {formatNumber(paymentsDetailData?.dynamic_pricing) || 0}
              </div>
            </div>
            <div className="separator separator-dashed my-4 " />
            <div className="d-flex flex-stack">
              <div className="fs-6">Reschedule Fee:</div>
              <div className="fs-6 fw-bolder">
                {formatNumber(paymentsDetailData?.reschedule_fee) || 0}
              </div>
            </div>
            <div className="separator separator-dashed my-4 " />
            <div className="d-flex flex-stack">
              <div className="fs-6">Fee for User:</div>
              <div className="fs-6 fw-bolder">
                {formatNumber(paymentsDetailData?.fee_for_user) || 0}
              </div>
            </div>
            <div className="separator separator-dashed my-4 " />
            <div className="d-flex flex-stack">
              <div className="fs-6">Amount Pay By User:</div>
              <div className="fs-6 fw-bolder">
                {formatNumber(paymentsDetailData?.amount_pay_by_user) || 0}
              </div>
            </div>

            <div className="separator separator-dashed my-4 " />
            <div className="d-flex flex-stack align-items-start">
              <div className="fs-6">
                <div className="mt-2"> Visa Fee:</div>
                {editionData?.edition_details?.is_visa_required && (
                  <>
                    {!editionData?.is_visa && (
                      <div className="mt-2">
                        <small className="text-danger fs-9">
                          Visa Fee has not been added to User Fee yet. <br />
                          This payment could be pending. Please recheck.
                        </small>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="text-primary fw-bold">
                {formatNumber(paymentsDetailData?.visa_fee) || 0}
              </div>
              {/* <div className="fs-6 fw-bolder">
                <div className="position-relative d-flex align-items-center">
                  <span className="d-flex flex-stack">
                    {isEditable ? (
                      <input
                        value={visaFee}
                        type="text"
                        className="border form-control-sm w-150px fs-6"
                        placeholder="Enter Visa Fee"
                        onChange={(e) => setVisaFee(e.target.value)}
                        onKeyDown={handleKeyDown}
                        disabled={updateVisaFeeLoading || paymentDetailsLoading}
                        title="Press Enter to save"
                      />
                    ) : (
                      <div className="text-primary fw-bold">
                        {formatNumber(paymentsDetailData?.visa_fee) || 0}
                      </div>
                    )}

                    {Number(paymentsDetailData?.visa_fee) > 0 && (
                      <>
                        {isEditable ? (
                          <Tooltip title="Save">
                            <span
                              className="cursor-pointer d-flex align-items-center"
                              onClick={() => updateVisaFee()}
                              style={{ marginLeft: "8px" }}
                              // title="Save"
                            >
                              <i className="ki-outline ki-check fs-2 text-dark"></i>
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Edit">
                            {" "}
                            <span
                              className="cursor-pointer d-flex align-items-center"
                              onClick={() => setIsEditable(!isEditable)}
                              style={{ marginLeft: "8px" }}
                              // title="Edit"
                            >
                              <i className="fa-solid fa-edit fs-5 text-dark ps-2"></i>
                            </span>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </span>
                </div>
              </div> */}
            </div>

            <div className="separator separator-dashed my-4 " />
            {paymentsDetailData?.visa_service_fee > 0 && (
              <>
                <div className="d-flex flex-stack">
                  <div className="fs-6">Visa Service Fee:</div>
                  <div className="fs-6 fw-bolder">
                    {formatNumber(paymentsDetailData?.visa_service_fee) || 0}
                  </div>
                </div>
                <div className="separator separator-dashed my-4 " />
              </>
            )}

            {paymentsDetailData?.visa_appointment_fee > 0 && (
              <>
                <div className="d-flex flex-stack">
                  <div className="fs-6">Visa Appointment Fee:</div>
                  <div className="fs-6 fw-bolder">
                    {formatNumber(paymentsDetailData?.visa_appointment_fee) ||
                      0}
                  </div>
                </div>
                <div className="separator separator-dashed my-4 " />
              </>
            )}

            {paymentsDetailData?.visa_embassy_fee > 0 && (
              <>
                <div className="d-flex flex-stack">
                  <div className="fs-6">Visa Embassy Fee:</div>
                  <div className="fs-6 fw-bolder">
                    {formatNumber(paymentsDetailData?.visa_embassy_fee) || 0}
                  </div>
                </div>
                <div className="separator separator-dashed my-4 " />
              </>
            )}

            {paymentsDetailData?.visa_external_agency_fee > 0 && (
              <>
                <div className="d-flex flex-stack">
                  <div className="fs-6">Visa External Agency Fee:</div>
                  <div className="fs-6 fw-bolder">
                    {formatNumber(
                      paymentsDetailData?.visa_external_agency_fee
                    ) || 0}
                  </div>
                </div>
                <div className="separator separator-dashed my-4 " />
              </>
            )}

            {paymentsDetailData?.visa_exco_fee > 0 && (
              <>
                <div className="d-flex flex-stack">
                  <div className="fs-6">Visa Exco Fee:</div>
                  <div className="fs-6 fw-bolder">
                    {formatNumber(paymentsDetailData?.visa_exco_fee) || 0}
                  </div>
                </div>
                <div className="separator separator-dashed my-4 " />
              </>
            )}

            {paymentsDetailData?.visa_courier_fee > 0 && (
              <>
                <div className="d-flex flex-stack">
                  <div className="fs-6">Visa Courier Fee:</div>
                  <div className="fs-6 fw-bolder">
                    {formatNumber(paymentsDetailData?.visa_courier_fee) || 0}
                  </div>
                </div>
                <div className="separator separator-dashed my-4 " />
              </>
            )}

            {paymentsDetailData?.visa_miscellaneous_fee > 0 && (
              <>
                <div className="d-flex flex-stack">
                  <div className="fs-6">Visa Miscellaneous Fee:</div>
                  <div className="fs-6 fw-bolder">
                    {formatNumber(paymentsDetailData?.visa_miscellaneous_fee) ||
                      0}
                  </div>
                </div>
                <div className="separator separator-dashed my-4 " />
              </>
            )}

            {paymentsDetailData?.visa_insurance_fee > 0 && (
              <>
                <div className="d-flex flex-stack">
                  <div className="fs-6">Visa Insurance Fee:</div>
                  <div className="fs-6 fw-bolder">
                    {formatNumber(paymentsDetailData?.visa_insurance_fee) || 0}
                  </div>
                </div>
                <div className="separator separator-dashed my-4 " />
              </>
            )}

            {/* <div className="mt-10">
              {paymentsDetailData?.transactions &&
                paymentsDetailData?.transactions.length > 0 && (
                  <h4 className="mb-8 fs-4 fw-bold">Transaction Status</h4>
                )}

              {paymentsDetailData?.transactions &&
              paymentsDetailData?.transactions.length > 0 ? (
                <div className="card">
                  <div className="card-body p-0">
                    <div
                      style={{ maxHeight: "400px", overflowY: "auto" }}
                      className="px-5"
                    >
                      {paymentsDetailData.transactions.map((item, i) => (
                        <div key={item.id}>
                          <div className="d-flex flex-row justify-content-between align-items-center py-4">
                            <div className="d-flex flex-column gap-1">
                              <div className="fs-7 text-gray-800">
                                <span className="fw-bold me-1">Made on:</span>
                                <span className="text-gray-600">
                                  {item?.created_at
                                    ? dayjs(item.created_at).format(
                                        "DD MMM YYYY"
                                      )
                                    : "-"}
                                </span>
                              </div>
                              <div className="fs-7 text-gray-800">
                                <span className="fw-bold me-1">Amount:</span>
                                <span className="text-gray-700">
                                  {item?.amount
                                    ? formatNumber(item.amount)
                                    : "-"}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center gap-5">
                              <span
                                className={`badge ${
                                  item?.status?.toLowerCase() === "success"
                                    ? "badge-light-success"
                                    : item?.status?.toLowerCase() === "failed"
                                    ? "badge-light-danger"
                                    : "badge-light-dark"
                                }`}
                              >
                                {item?.status || "-"}
                              </span>
                              {item?.transaction_type === 3 && (
                                <Tooltip title="Change Amount">
                                  <div
                                    onClick={() => {
                                      setSelectedModalData(item);
                                      setPaymentId(item?.id);
                                      setChangeAmountModal(!changeAmountModal);
                                    }}
                                    className="cursor-pointer"
                                  >
                                    <i className="ki-outline ki-pencil fs-4 text-primary"></i>
                                  </div>
                                </Tooltip>
                              )}
                            </div>
                          </div>

                          {i < paymentsDetailData.transactions.length - 1 && (
                            <div className="separator separator-dashed"></div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div> */}
          </div>
        </div>
      </div>

      <ChangeAmountModal
        show={changeAmountModal}
        data={editionData}
        onHide={() => {
          setChangeAmountModal(false);
          // getAmountOptions();
        }}
        onSuccess={() => {
          getPaymentDetails(bookingId);
        }}
        paymentData={selectedModalData}
      />
    </div>
  );
};

export default Payment;
