import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { LIMITS, routesList } from "../../../config";
import {
  CustomerModal,
  EditCustomerModal,
  FeedbackModal,
  NotesModal,
  StatusMarker,
  StatusModal,
  UserRoleModal,
} from "../modals";
import { fetchData } from "../../../config/service";
import toast from "react-hot-toast";
import moment from "moment";
import {
  EditionsDetails,
  Booking,
  Curation,
  InvoiceData,
  Payment,
  Preferences,
  Visa,
} from "./tab-content";
import {
  Button,
  Form,
  Input,
  Modal,
  Popover,
  Select,
  Skeleton,
  Spin,
  Timeline,
  Tooltip,
} from "antd";
import VisaJourneyTimeline from "./visa-timeline";
import DetractorModal from "../modals/detractor";
import dayjs from "dayjs";
import UpdateCallStatus from "../modals/update-call-status";
import { Alert } from "../../../components";
import WhatsappTemplateModal from "../modals/whatsapp-template";
import { useSelector } from "react-redux";
import MakeCallModal from "../../../components/make-call-modal";
import EmailTemplateModal from "../../../components/email-template-modal";
import CallNotesModal from "../modals/call-notes";
import EmergencyContactDetailsModal from "../modals/emergency-contact-details";

const noteTypeMapping = {
  1: "Visa Related",
  2: "Production Related",
  3: "Curation Related",
  4: "Marketing Related",
  5: "Onboarding Related",
  6: "Payment Related",
  7: "Support Related",
  8: "Edition Related",
  9: "Other",
};

export default function () {
  const { id } = useParams();
  const user = useSelector((state) => state.user);

  // console.log(user, "user");
  const [expand, setExpand] = useState(1);
  const [activeTab, setActiveTab] = useState(3);
  const [isOpenScoreModal, setIsOpenScoreModal] = useState(false);
  const [isOpenNotesModal, setIsOpenNotesModal] = useState(false);
  const [isOpenCustomerModal, setIsOpenCustomerModal] = useState(false);
  const [isOpenFeedbackModal, setIsOpenFeedbackModal] = useState(false);
  const [isOpenUserRoleModal, setIsOpenUserRoleModal] = useState(false);
  const [isOpenStatusMarkerModal, setIsOpenStatusMarkerModal] = useState(false);
  const [detractorModal, setDetractorModal] = useState(false);
  const [updateCallStatusModal, setUpdateCallStatusModal] = useState(false);
  const [callNotesModal, setCallNotesModal] = useState(false);

  const [user_data, set_user_data] = useState({});

  const [loading, setLoading] = useState(true);
  const [notesLoading, setNotesLoading] = useState(true);

  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [data, setData] = useState([]);
  const [notes, setNotes] = useState([]);
  const [notesOptions, setNotesOptions] = useState([]);
  const [noteType, setNoteType] = useState(null);
  const [statusList, setStatusList] = useState([]);
  const [growthAreaList, setGrowthAreaList] = useState({});
  const [topPriorityList, setTopPriorityList] = useState({});
  const [feedbackQlist, setFeedbackQlist] = useState([]);
  const [feedbackList, setFeedbackList] = useState([]);
  const [editionListBookingLoading, setEditionListBookingLoading] =
    useState(false);
  const [editionsData, setEditionsData] = useState({
    editionsOptions: [],
    data: [],
  });
  const [editionsOptions, setEditionsOptions] = useState([]);
  const [filterData, setFilterData] = useState({
    edition: null,
  });
  const [isStarProfile, setIsStarProfile] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedNotesData, setSelectedNotesData] = useState(null);
  const [tribeFlag, setTribeFlag] = useState(false);

  const [isOpenUserModal, setIsOpenUserModal] = useState(false);

  const [editionListLoading, setEditionListLoading] = useState(false);
  const [editionActivePage, setEditionActivePage] = useState(1);
  const [editionSearchTerm, setEditionSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] =
    useState(editionSearchTerm);
  const [whatsappTemplateModal, setWhatsAppTemplateModal] = useState(false);
  const [isMakeCallModalOpen, setIsMakeCallModalOpen] = useState(false);
  const [emailTemplateModal, setEmailTemplateModal] = useState(false);
  const [emergencyContactDetailModal, setEmergencyContactDetailModal] =
    useState(false);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(editionSearchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [editionSearchTerm]);

  useEffect(() => {
    getEditionOptions();
  }, [id, editionActivePage, debouncedSearchTerm]);

  useEffect(() => {
    if (id) {
      getData();
      getStatus();
      getNotesOptions();
      getUserChoices();
      getFeedbackQuestion();
      // getEditionOptions();
      // getFeedbackList(); Feedback update is pending from backend
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      getNotes();
    }
  }, [id, noteType]);

  useEffect(() => {
    if (filterData?.edition) {
      getEditionsLitFromBookingData();
    }
  }, [filterData?.edition]);

  const getStatus = () => {
    fetchData(
      `api/accounts/user-choices/?type=user_status_choices`,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        setStatusList(res["user_status_choices"]);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getNotesOptions = () => {
    fetchData(`api/note/notes-enum/`, "get").then((res) => {
      setLoading(false);
      if (res.success) {
        setNotesOptions(res["note_choices"]);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getData = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}`;

    fetchData(`customer/api/customer/${id}/`, "get").then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res);
        setTotalRecords(res.count);
        setTribeFlag(res?.user_profile ? res.user_profile.in_tribe : false);
      } else {
        toast.error(res.message);
      }
    });
  };

  // console.log(data, "data");
  const getEditionsLitFromBookingData = (editionId) => {
    setEditionListBookingLoading(true);
    fetchData(
      `api/booking/?limit=${limit}&offset=${
        (activePage - 1) * limit
      }&user=${id}&edition=${editionId || filterData?.edition || ""}`,
      "get"
    ).then((res) => {
      setEditionListBookingLoading(false);
      if (res.success) {
        const data = res.data;

        setEditionsData((prevData) => ({
          ...prevData,
          data: data, // Merge new data into existing state
        }));
      } else {
        toast.error(res.message);
      }
    });
  };

  const getEditionOptions = () => {
    const newSearch = debouncedSearchTerm;

    const limitNew = newSearch ? 50 : limit;
    const offsetNew = newSearch ? 0 : (editionActivePage - 1) * limit;

    setEditionListLoading(true);

    fetchData(
      `api/editions/editions-select/?user_id=${id}&offset=${offsetNew}&limit=${limitNew}&search=${encodeURIComponent(
        newSearch || ""
      )}`,
      "get"
    ).then((res) => {
      setEditionListLoading(false);
      if (res.success) {
        const data = res.data.map((e) => {
          return { label: e.ip, value: e.id };
        });
        getEditionsLitFromBookingData(data[0]?.value);

        setEditionsOptions((prevOptions) => {
          const combined = [...(newSearch ? [] : prevOptions), ...data];
          return Array.from(new Set(combined.map((opt) => opt.value))).map(
            (id) => combined.find((opt) => opt.value === id)
          );
        });

        // setEditionsData((prevData) => ({
        //   ...prevData,
        //   editionsOptions: data,
        // }));
      } else {
        toast.error(res.message);
      }
    });
  };

  const getNotes = () => {
    fetchData(
      `api/note/?user=${id}&note_type=${encodeURIComponent(noteType || "")}`,
      "get"
    ).then((res) => {
      setNotesLoading(false);
      if (res.success) {
        // const transformedData = res?.data?.map((item) => {
        //   return {
        //     date: moment(item.created_at).format("DD-MM-YYYY"),
        //     title: noteTypeMapping[item.note_type] || "Other Note",
        //     content: [`${item.note}`],
        //     note_type: item.note_type,
        //     id: item.id,
        //     note: item.note,
        //   };
        // });

        setNotes(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getFeedbackQuestion = () => {
    fetchData(`api/feedback/feedback-question/`, "get").then((res) => {
      if (res.success) {
        // Add key to each question object
        const modifiedQuestions = res.data.map((item, index) => ({
          ...item,
          key: `que_${index + 1}`, // Adding 'key' with value 'que_1', 'que_2', etc.
        }));
        setFeedbackQlist(modifiedQuestions);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getFeedbackList = () => {
    fetchData(`api/feedback/${id}/`, "get").then((res) => {
      if (res.success) {
        setFeedbackList(res.feedback_data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getUserChoices = () => {
    fetchData(`api/accounts/user-choices/`, "get").then((res) => {
      setNotesLoading(false);
      if (res.success) {
        // Transform the growth_areas_choices array into an object
        const growthAreaObject = res["growth_areas_choices"]?.reduce(
          (acc, e) => {
            acc[e.value] = e.display_name;
            return acc;
          },
          {}
        );

        // Transform the top_priority_choices array into an object
        const topPriorityObject = res["top_priority_choices"]?.reduce(
          (acc, e) => {
            acc[e.value] = e.display_name;
            return acc;
          },
          {}
        );

        // Set the transformed objects in state
        setGrowthAreaList(growthAreaObject);
        setTopPriorityList(topPriorityObject);
      } else {
        // Handle error case
        setGrowthAreaList({});
        setTopPriorityList({});
        toast.error(res.message);
      }
    });
  };
  const setFilterDataValue = (key, value) => {
    const common_data = {
      ...filterData,
      [key]: value,
    };
    setFilterData(common_data);
  };

  const handleStarClick = async (userId, star) => {
    const newStarProfile = !star;
    setIsStarProfile(newStarProfile);

    const body = {
      is_star_profile: newStarProfile,
    };

    fetchData(`customer/api/customer/${userId}/`, "PATCH", body, "", true).then(
      (res) => {
        toast.remove();
        // setSending(false);
        if (res.success) {
          setLoading(true);
          getData();
          const successMessage = newStarProfile
            ? "Profile starred successfully!"
            : "Profile unstarred successfully!";
          toast.success(successMessage);
        } else {
          toast.error(res.message);
        }
      }
    );
  };

  const deleteBookingApplication = () => {
    const bookingId = editionsData?.data[0]?.id;
    // console.log(bookingId, "bookingId");

    fetchData(`api/booking/${bookingId}`, "delete").then((res) => {
      toast.dismiss();
      if (res.success) {
        toast.success("Record deleted successfully");
        getEditionsLitFromBookingData();
        getEditionOptions();
      } else {
        toast.error(res.message);
      }
    });
  };

  const resetCurationTimestamp = async () => {
    const bookingId = editionsData?.data[0]?.id;
    // console.log(bookingId, "bookingId");

    const currentTimestamp = dayjs().toISOString();

    const body = {
      reset_curation_timestamp: currentTimestamp,
    };

    // console.log(body, "body");

    toast.loading("Resetting Curation Timestamp");

    fetchData(`api/booking/${bookingId}/`, "PATCH", body).then((res) => {
      toast.dismiss();
      if (res.success) {
        toast.success("Curation Timestamp reset successfully!");
        // getEditionsLitFromBookingData();
        // getEditionOptions();
      } else {
        toast.error(res.message);
      }
    });
  };

  const markAsTribe = async (userId, isTribe) => {
    const body = {
      user_profile: {
        in_tribe: isTribe,
      },
    };

    try {
      toast.loading("Marking as Tribe");
      const response = await fetchData(
        `customer/api/customer/${userId}/`,
        "PATCH",
        body
      );

      toast.dismiss();

      if (response.success) {
        getData();
        if (isTribe) {
          toast.success("Marked as Tribe successfully!");
        } else {
          toast.success("Unmarked as Tribe successfully!");
        }
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.dismiss();
      toast.error("An error occurred while marking as Tribe.");
    }
  };

  const tabs = [
    { id: 3, label: "Bookings" },
    { id: 2, label: "Payments" },
    { id: 4, label: "Curation" },
    { id: 5, label: "Preferences" },
    { id: 6, label: "Data for Invoices" },
    { id: 7, label: "Visa & Flight" },
    { id: 1, label: "About" },
  ];

  // Filter tabs based on data.is_curation_note
  const filteredTabs =
    data?.score >= 3 ? tabs : tabs.filter((tab) => tab.label !== "Curation");

  // console.log(editionsData, "editionsData");
  // console.log(String(editionsData?.editionsOptions[1]?.value), "editionsData");

  const popoverContent = (
    <div>
      <Button
        type="link"
        className="text-dark fw-bold"
        onClick={() => setWhatsAppTemplateModal(true)}
      >
        WhatsApp Template
      </Button>
      <br />
      <Button
        type="link"
        className="text-dark fw-bold"
        href={`https://wa.me/${data?.phone}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Open in WhatsApp
      </Button>
    </div>
  );

  return (
    <div>
      <div
        className="d-flex flex-column flex-lg-row "
        style={
          loading
            ? {
                filter: "blur(5px)",
                pointerEvents: "none",
              }
            : {}
        }
      >
        <div className="flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10 ">
          <div className="card mb-5 mb-xl-5">
            {/* for ribbon */}

            {/* <div className="card-body ribbon ribbon-top">
              <div class="ribbon-label bg-primary">Ribbon</div> */}

            <div
              className={`card-body px-2 px-md-2 px-lg-4 px-xl-10   ${
                tribeFlag && "tribeFlag ribbon ribbon-top"
              } `}
            >
              {loading ? (
                <Skeleton
                  avatar
                  paragraph={{
                    rows: 4,
                  }}
                />
              ) : (
                <>
                  {tribeFlag && (
                    <div class="ribbon-label bg-primary">Tribe</div>
                  )}

                  <div className="d-flex align-items-center mb-5">
                    <Link
                      to={routesList["customers"].path}
                      className="btn btn-light-primary btn-icon me-3 me-md-0 me-lg-3 me-xl-3 p-5 p-md-3 p-md-lg-4 p-md-xl-5"
                    >
                      <i
                        className="ki-outline ki-arrow-left fs-2"
                        data-bs-toggle="tooltip"
                        title="Back to Users Page"
                      />
                    </Link>
                    <div className="w-100 me-3 me-md-0 me-lg-3 me-xl-3">
                      <Select
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        value={filterData?.edition || editionsOptions[0]?.value}
                        showSearch
                        onSearch={(value) => {
                          setEditionSearchTerm(value);
                        }}
                        optionFilterProp="label"
                        onChange={(value) =>
                          setFilterDataValue("edition", value)
                        }
                        placeholder="Select Edition"
                        style={{ width: "160px" }}
                        options={editionsOptions}
                        // allowClear
                        // onClear={() => {
                        //   setFilterDataValue("edition", null);
                        // }}
                        onPopupScroll={(e) => {
                          const bottom =
                            Math.abs(
                              e.target.scrollHeight -
                                (e.target.scrollTop + e.target.clientHeight)
                            ) < 1.5;
                          if (bottom && !loading) {
                            setEditionActivePage((prevState) => prevState + 1);
                          }
                        }}
                        notFoundContent={
                          <>
                            <div className="d-flex justify-content-center align-items-center">
                              {editionListLoading ? (
                                <Spin size="small" />
                              ) : (
                                "No Data Found"
                              )}
                            </div>
                          </>
                        }
                        size="large"
                        loading={editionListLoading}
                      />
                    </div>

                    <div className="d-flex align-items-center">
                      <div
                        className="w-100 me-3 me-md-0 me-lg-3 me-xl-3"
                        onClick={() => {
                          toast.loading("Loading..");
                          handleStarClick(
                            data.uuid,
                            data.user_profile?.is_star_profile
                          );
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {data.user_profile?.is_star_profile ? (
                          <i className="ki-duotone ki-star fs-1 text-warning" />
                        ) : (
                          <i className="ki-outline ki-star fs-1" />
                        )}
                      </div>
                      <div
                        className="w-100"
                        onClick={() => {
                          setDetractorModal(!detractorModal);
                          // toast.loading("Loading..");
                          // handleStarClick(
                          //   data.uuid,
                          //   data.user_profile?.is_star_profile
                          // );
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {data?.is_detractors ? (
                          <i className="ki-solid  ki-flag fs-2 text-dark" />
                        ) : (
                          <i className="ki-outline ki-flag fs-1" />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-center flex-column py-5 pb-0 pt-0 ">
                    <div className="symbol symbol-100px symbol-circle mb-7">
                      <img
                        src={`${data?.photo || "/media/blank.png"}`}
                        alt="image"
                      />
                    </div>

                    <a
                      href="#"
                      className="fs-3 text-gray-800 text-hover-primary fw-bold mb-1"
                    >
                      {data?.first_name + " " + data?.last_name || "-"}
                    </a>
                    <div className="d-inline">{data?.email || "-"}</div>

                    <div className="fw-bolder mt-3">{data?.phone || "-"}</div>

                    {data?.score && (
                      <div className="mt-3">
                        <span className="fw-semibold">Score: </span>
                        {data.score === null ? "-" : data.score}
                        {/* {data.score || "-"} */}
                      </div>
                    )}

                    <div className="d-flex align-items-center">
                      {data?.user_profile?.instagram_url && (
                        <a
                          href={data?.user_profile?.instagram_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mt-3"
                        >
                          <i className="ki-outline ki-instagram fs-2 text-danger" />
                        </a>
                      )}

                      {data?.user_profile?.linkedin_url && (
                        <a
                          href={data?.user_profile?.linkedin_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mt-3 ms-3"
                        >
                          <i class="lab la-linkedin fs-1 text-info"></i>
                        </a>
                      )}

                      {data?.phone ? (
                        <Popover content={popoverContent} trigger="hover">
                          <div className="mt-3 ms-3">
                            <i
                              className="bi bi-whatsapp fs-4 text-success"
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </Popover>
                      ) : (
                        <Tooltip title="Please add phone number">
                          <div
                            className="mt-3 ms-3"
                            disabled
                            style={{
                              cursor: "not-allowed",
                              opacity: "0.5",
                            }}
                          >
                            <i className="bi bi-whatsapp fs-4 text-success" />
                          </div>
                        </Tooltip>
                      )}

                      {data?.email && editionsData?.data[0]?.edition ? (
                        <div
                          className="mt-3 ms-3"
                          onClick={() => {
                            setEmailTemplateModal(true);
                          }}
                        >
                          <i
                            className="bi bi-envelope fs-4 text-danger"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      ) : (
                        <Tooltip title="Please select edition to send mail">
                          <div
                            className="mt-3 ms-3"
                            disabled
                            style={{
                              cursor: "not-allowed",
                              opacity: "0.5",
                            }}
                          >
                            <i className="bi bi-envelope fs-4 text-danger" />
                          </div>
                        </Tooltip>
                      )}

                      <div className="mt-3 ms-3">
                        <i
                          className="bi bi-telephone fs-4 text-info"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setIsMakeCallModalOpen(true);
                          }}
                        />
                      </div>
                    </div>

                    {data?.curator_name && (
                      <div className="mt-4">
                        <span className="fw-semibold">POC Name: </span>
                        {data.curator_name}
                      </div>
                    )}

                    {data?.curator_name && (
                      <div className="mt-2">
                        <span className="fw-semibold">Resource Manager: </span>
                        {data.curator_name}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="accordion accordion-icon-toggle" id="kt_accordion_2">
            <div className="mb-5 card">
              <div
                className={`accordion-header py-5 px-5 d-flex ${
                  expand !== 1 && "collapsed"
                }`}
                onClick={() => {
                  setExpand(expand === 1 ? null : 1);
                }}
              >
                <span className="accordion-icon">
                  <i className="ki-duotone ki-arrow-right fs-4">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </span>
                <h3 className="fs-4 fw-bold mb-0 ms-4">Key Information</h3>
              </div>

              <div
                id="kt_accordion_2_item_1"
                className={`fs-6 collapse ${
                  expand === 1 && "show"
                } px-10 pb-5 pt-2`}
                data-bs-parent="#kt_accordion_2"
              >
                <div className>
                  <div className="d-flex align-items-center flex-stack mb-5">
                    <div className="fw-bold ">Country:</div>
                    <div className="text-gray-600">
                      {data?.user_profile?.country_name || "-"}
                    </div>
                  </div>

                  <div className="d-flex align-items-center flex-stack mb-5">
                    <div className="fw-bold ">City:</div>
                    <div className="text-gray-600">
                      {data?.user_profile?.city_name || "-"}
                    </div>
                  </div>

                  {/* <div className="d-flex align-items-center flex-stack mb-5">
                    <div className="fw-bold ">Designation:</div>
                    <div
                      className="text-gray-600"
                      style={{ maxWidth: "130px" }}
                    >
                      {data?.user_work?.length > 0
                        ? data.user_profile?.designation
                        : "-"}
                    </div>
                  </div> */}

                  <div className="d-flex align-items-center flex-stack mb-5">
                    <div className="fw-bold ">Revenue:</div>
                    <div className="text-gray-600">
                      {data?.user_work?.length > 0
                        ? data.user_work[0].revenue
                        : "-"}
                    </div>
                  </div>

                  <div className="d-flex align-items-center flex-stack mb-5">
                    <div className="fw-bold ">Age:</div>
                    <div className="text-gray-600">
                      {data.date_of_birth !== null && data.date_of_birth !== ""
                        ? new Date().getFullYear() -
                          new Date(data.date_of_birth).getFullYear()
                        : "-"}
                    </div>
                  </div>

                  <div className="d-flex align-items-center flex-stack mb-5">
                    <div className="fw-bold ">Status:</div>
                    <div className="text-gray-600">
                      {data?.status == 1
                        ? "Pending"
                        : data.status == 2
                        ? "Abandon"
                        : data?.status == 3
                        ? "Approved"
                        : "Attended"}
                    </div>
                  </div>

                  <div className="d-flex align-items-center flex-stack mb-5">
                    <div className="fw-bold ">Gender:</div>
                    <div className="text-gray-600">
                      {data?.gender == 1 ? "Female" : "Male"}
                    </div>
                  </div>

                  <div className="d-flex align-items-center flex-stack mb-5">
                    <div className="fw-bold ">Company:</div>
                    <div className="text-gray-600">
                      {data?.user_work?.length > 0
                        ? data.user_work[0].name
                        : "-"}
                    </div>
                  </div>

                  {/* <div className="d-flex align-items-center flex-stack mb-5">
                    <div className="fw-bold ">University:</div>
                    <div className="text-gray-600">
                      {(data?.user_work?.length > 0 &&
                        data.user_work[0].u_name) ||
                        "-"}
                    </div>
                  </div> */}

                  <div className="d-flex align-items-center justify-content-center">
                    <p className="fw-semibold fs-6 text-start text-gray-800">
                      Emergency Contact Details
                    </p>

                    <Tooltip title="Edit Emergency Contact Details">
                      <i
                        className="ki-outline ki-pencil fs-6 ms-3 mb-4 text-primary cursor-pointer"
                        onClick={() => {
                          setEmergencyContactDetailModal(
                            !emergencyContactDetailModal
                          );
                        }}
                      ></i>
                    </Tooltip>
                  </div>

                  <div className="d-flex align-items-center flex-stack mb-5">
                    <div className="fw-bold ">Name:</div>
                    <div className="text-gray-600">
                      {(data?.customer_emergency_contact &&
                        data?.customer_emergency_contact?.name) ||
                        "-"}
                    </div>
                  </div>

                  <div className="d-flex align-items-center flex-stack mb-5">
                    <div className="fw-bold ">Contact No:</div>
                    <div className="text-gray-600">
                      {(data?.customer_emergency_contact &&
                        data?.customer_emergency_contact?.phone_number) ||
                        "-"}
                    </div>
                  </div>

                  <div className="d-flex align-items-center flex-stack mb-5">
                    <div className="fw-bold ">Relationship:</div>
                    <div className="text-gray-600">
                      {(data?.customer_emergency_contact &&
                        data.customer_emergency_contact.relationship) ||
                        "-"}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-5 card">
              <div
                className={`accordion-header py-5 px-5 d-flex ${
                  expand !== 2 && "collapsed"
                }`}
                onClick={() => {
                  setExpand(expand === 2 ? null : 2);
                }}
              >
                <span className="accordion-icon">
                  <i className="ki-duotone ki-arrow-right fs-4">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </span>
                <h3 className="fs-4 fw-bold mb-0 ms-4">Control Panel</h3>
              </div>

              <div
                className={`fs-6 collapse ${
                  expand === 2 && "show"
                } px-10 pb-5 pt-2`}
              >
                <div id className=" ">
                  <div className="mb-3">
                    <div
                      className="fs-6 fw-semibold text-primary"
                      onClick={(e) => {
                        setIsOpenCustomerModal(!isOpenCustomerModal);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Edit User Profile
                    </div>
                  </div>
                  <div className="mb-3">
                    <div
                      className="fs-6 fw-semibold text-primary"
                      onClick={(e) => {
                        setIsOpenUserRoleModal(!isOpenUserRoleModal);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Change User Role
                    </div>
                  </div>

                  <div className="mb-3">
                    <div
                      className="fs-6 fw-semibold text-primary"
                      onClick={(e) => {
                        setCallNotesModal(!callNotesModal);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Add Call Notes
                    </div>
                  </div>

                  <div className="mb-3">
                    {/* <a
                      href
                      className="fs-6 fw-semibold"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_phone_insta"
                    >
                      Change Phone or Instagram
                    </a> */}
                  </div>
                  <div className="mb-3">
                    <div
                      className="fs-6 fw-semibold text-primary cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        resetCurationTimestamp();
                      }}
                    >
                      Reset Curation Timestamp (24H)
                    </div>
                  </div>

                  <div className="mb-3">
                    <div
                      className="fs-6 fw-semibold text-primary cursor-pointer"
                      onClick={() => {
                        const isTribe = !tribeFlag;
                        setTribeFlag(isTribe);
                        markAsTribe(data.uuid, isTribe);
                      }}
                    >
                      {tribeFlag ? "Unmark as Tribe" : " Mark as Tribe"}
                    </div>
                  </div>
                  {/* <div className="mb-3">
                    <a href className="fs-6 fw-semibold">
                      Change App linked to user
                    </a>
                  </div>
                  <div className="mb-3">
                    <a href className="fs-6 fw-semibold">
                      Generate Magic Link
                    </a>
                  </div> */}

                  <div className="mb-3">
                    <div
                      className="fs-6 fw-semibold text-danger cursor-pointer"
                      onClick={() => {
                        setShowDeleteModal(true);
                      }}
                    >
                      Delete Application
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-5 card">
              <div
                className={`accordion-header py-5 px-5 d-flex ${
                  expand !== 3 && "collapsed"
                }`}
                onClick={() => {
                  setExpand(expand === 3 ? null : 3);
                }}
              >
                <span className="accordion-icon">
                  <i className="ki-duotone ki-arrow-right fs-4">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </span>
                <h3 className="fs-4 fw-bold mb-0 ms-4">Notes</h3>
                <div
                  // href
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_add_note"
                  className="d-flex justify-content-end ms-auto align-items-center fw-bold"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpenNotesModal(!isOpenNotesModal);
                  }}
                >
                  <i className="me-1 ki-outline ki-plus-square text-primary" />{" "}
                  Add Note
                </div>
              </div>

              <div
                className={`fs-6 collapse ${
                  expand === 3 && "show"
                } px-10 pb-5 pt-2`}
              >
                <div className="mb-4">
                  <Select
                    options={notesOptions?.map((role) => {
                      return {
                        label: role?.display_name,
                        value: role?.value,
                      };
                    })}
                    value={noteType}
                    onChange={(e) => setNoteType(e)}
                    placeholder="Select Notes Subject"
                    dropdownStyle={{ zIndex: 1111 }}
                    style={{ width: "100%", marginTop: "10px" }}
                    size="large"
                    allowClear
                    onClear={() => setNoteType(null)}
                  />

                  {/* <select
                    className="form-select"
                    value={noteType}
                    onChange={(e) => setNoteType(e.target.value)}
                    // disabled="true"
                  >
                    {notesOptions?.map((role, i) => (
                      <option key={i} value={role?.value}>
                        {role?.display_name}
                      </option>
                    ))}
                  </select> */}
                  {/* <select
                    className="form-select fs-7"
                    data-control="select2"
                    data-placeholder="Select Notes Subject"
                  >
                    <option value />
                    <option>Visa Related</option>
                  </select> */}
                </div>
                {notes && notes.length > 0 ? (
                  notes.map((item, index) => (
                    <>
                      <div key={index}>
                        <div className="bg-light p-4 rounded mb-5 position-relative">
                          <button
                            className="btn btn-outline-primary btn-sm position-absolute top-0 end-0 m-3"
                            onClick={() => {
                              setIsOpenNotesModal(!isOpenNotesModal);
                              setSelectedNotesData(item);
                            }}
                          >
                            Edit
                          </button>
                          <div className="fs-5 text-primary fw-bold">
                            Note Added -{" "}
                            {dayjs.utc(item?.created_at).format("DD-MM-YYYY")}
                          </div>
                          <span className="fs-7 fw-semibold text-gray-700">
                            {item?.note_type_label}
                          </span>
                          <div className="d-flex align-items-center mt-4 mb-3">
                            <div className="symbol symbol-circle symbol-30px overflow-hidden me-3">
                              <img src={item?.created_by?.photo} alt="" />
                            </div>
                            <div className="d-flex flex-column">
                              <a
                                href="#"
                                className="text-gray-800 text-hover-primary mb-0 fw-bolder fs-6"
                              >
                                {item?.created_by?.first_name +
                                  " " +
                                  item?.created_by?.last_name}
                              </a>
                            </div>
                          </div>
                          <h3 className="mb-4 mt-2 fs-4">
                            {/* {noteTypeValue(item?.note_type || 0)} */}
                          </h3>
                          <div>{item?.note}</div>
                        </div>
                        <div className="separator separator-dashed my-5 border-gray-300" />
                      </div>
                    </>
                  ))
                ) : (
                  <div className="text-center fs-5 text-muted">
                    No notes found
                  </div>
                )}
              </div>
            </div>

            {/* Sales */}
            {/* <div className="mb-5 card">
              <div
                className={`accordion-header py-5 px-5 d-flex ${
                  expand !== 4 && "collapsed"
                }`}
                onClick={() => {
                  setExpand(expand === 4 ? null : 4);
                }}
              >
                <span className="accordion-icon">
                  <i className="ki-duotone ki-arrow-right fs-4">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </span>
                <h3 className="fs-4 fw-bold mb-0 ms-4">Sales</h3>
              </div>

              <div
                id="kt_accordion_2_item_4"
                className={`fs-6 collapse ${
                  expand === 4 && "show"
                } px-10 pb-5 pt-2`}
                data-bs-parent="#kt_accordion_2"
              >
                <div id className=" ">
                  <div className="mb-3">
                    <span className="fs-7 fw-semibold">
                      Invite extended till
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="fs-7 fw-semibold">Call Request Sent</span>
                  </div>
                  <div className="mb-3">
                    <span className="fs-7 fw-semibold">
                      Call Request Reminder Sent
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="fs-7 fw-semibold">Call Done On</span>
                  </div>
                  <div className="mb-3">
                    <span className="fs-7 fw-semibold">Call Feeler</span>
                  </div>
                  <div className="mb-3">
                    <span className="fs-7 fw-semibold">Call Followup On</span>
                  </div>
                  <div className="mb-7">
                    <span className="fs-7 fw-semibold">Followup Notes:</span>
                  </div>

                  <div className="mb-5">
                    <span className="fs-7 fw-bolder">Marketing Email Sent</span>
                    <div className="fs-7 fw-semibold">
                      1.1 Welcome to Experience Co.
                    </div>
                  </div>

                  <div className="mb-3">
                    <span className="fs-7 fw-bolder mb-2 d-block">
                      Call Recording
                    </span>
                    <div className="bg-light d-flex flex-stack p-3 rounded mb-3">
                      <div className="fs-7">Name of the curator</div>
                      <div className="fw-bold fs-7">08:00:08 14:00 PM</div>
                    </div>
                    <div className="bg-light d-flex flex-stack p-3 rounded mb-3">
                      <div className="fs-7">Name of the curator</div>
                      <div className="fw-bold fs-7">08:00:08 14:00 PM</div>
                    </div>
                  </div>

                  <div className="d-flex flex-stack  mb-0">
                    <div className="fs-7">
                      <Link
                        to={`${routesList["curation_central"].path.replace(
                          ":id",
                          id
                        )}`}
                        className="btn btn-primary btn-sm me-2"
                      >
                        Curate User
                      </Link>
                    </div>
                    <div className>
                      <button
                        className="btn btn-primary fw-bold fs-7 btn-sm"
                        onClick={() => {
                          setUpdateCallStatusModal(true);
                        }}
                      >
                        Update Call Status
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="mb-5 card">
              <div
                className={`accordion-header py-5 px-5 d-flex ${
                  expand !== 5 && "collapsed"
                }`}
                onClick={() => {
                  setExpand(expand === 5 ? null : 5);
                }}
              >
                <span className="accordion-icon">
                  <i className="ki-duotone ki-arrow-right fs-4">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </span>
                <h3 className="fs-4 fw-bold mb-0 ms-4">Feedback</h3>
              </div>

              <div
                className={`fs-6 collapse ${
                  expand === 5 && "show"
                } px-10 pb-5 pt-2`}
              >
                <div id className=" ">
                  <div className="mb-3">
                    <span className="fs-7 fw-semibold">Edition Attended:</span>{" "}
                    <b>{editionsData?.data[0]?.edition_name || "-"}</b>
                  </div>
                  <div className="mb-3">
                    <span className="fs-7 fw-semibold">Edition Ended on: </span>{" "}
                    <b>
                      {dayjs(
                        editionsData?.data[0]?.edition_details?.end_date
                      ).format("DD-MM-YYYY h:mm a") || "-"}
                    </b>
                  </div>
                  <div className="d-flex flex-stack  mt-7">
                    <div className="fs-7">
                      <button
                        className="btn btn-light-primary fw-bold fs-7 btn-sm"
                        onClick={() => {
                          setIsOpenFeedbackModal(!isOpenFeedbackModal);
                        }}
                      >
                        Update Feedback
                      </button>
                    </div>
                    {/* <div class=""><button class="btn btn-primary fw-bold fs-7 btn-sm">Update Call Status</button></div> */}
                  </div>
                </div>
              </div>
            </div>

            {/* Status Markers */}
            {/* <div className="mb-5 card">
              <div
                className={`accordion-header py-5 px-5 d-flex ${
                  expand !== 6 && "collapsed"
                }`}
                onClick={() => {
                  setExpand(expand === 6 ? null : 6);
                }}
              >
                <span className="accordion-icon">
                  <i className="ki-duotone ki-arrow-right fs-4">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </span>
                <h3 className="fs-4 fw-bold mb-0 ms-4">Status Markers</h3>
              </div>

              <div
                className={`fs-6 collapse ${
                  expand === 6 && "show"
                } px-10 pb-5 pt-2`}
              >
                <div id className=" ">
                  <div className="mb-3">
                    <span className="fs-7 fw-semibold">
                      Call request sent on -
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="fs-7 fw-semibold">Call at - </span>
                  </div>
                  <div className="mb-3">
                    <span className="fs-7 fw-semibold">Followup on - </span>
                  </div>
                  <div className="mb-3">
                    <span className="fs-7 fw-semibold">Followuped on - </span>
                  </div>
                  <div className="mb-3">
                    <span className="fs-7 fw-semibold">
                      Payment link sent on -{" "}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="fs-7 fw-semibold">Paid on - </span>
                  </div>
                </div>
                <div class="mt-4">
                  <button
                    class="btn btn-primary fw-bold fs-7 btn-sm"
                    onClick={() => {
                      setIsOpenStatusMarkerModal(!isOpenStatusMarkerModal);
                    }}
                  >
                    Update Status Marker
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className="flex-lg-row-fluid ms-lg-15">
          <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8">
            {tabs?.map((tab, index) => (
              <li className="nav-item" key={index}>
                <span
                  className={`nav-link text-gray-800 text-active-primary pb-4 cursor-pointer ${
                    activeTab === tab.id && "active"
                  }`}
                  onClick={() => setActiveTab(tab.id)}
                >
                  {tab.label}
                </span>
              </li>
            ))}
          </ul>

          <div className="tab-content">
            {activeTab === 1 && (
              <EditionsDetails
                data={data}
                editionData={editionsData?.data[0]}
                setIsOpenScoreModal={setIsOpenScoreModal}
                growthAreaList={growthAreaList}
                topPriorityList={topPriorityList}
              />
            )}

            {activeTab === 2 && (
              <Payment
                editionData={editionsData?.data[0]}
                getEditionsLitFromBookingData={getEditionsLitFromBookingData}
                // getEditionOptions={getEditionOptions}
                // getEditionsLitFromBookingData={getEditionsLitFromBookingData}
                // setIsOpenUserModal={setIsOpenUserModal}
              />
            )}

            {activeTab === 3 && (
              <Booking
                getEditionOptions={getEditionOptions}
                editionData={editionsData?.data[0]}
                getEditionsLitFromBookingData={getEditionsLitFromBookingData}
                setIsOpenUserModal={setIsOpenUserModal}
              />
            )}

            {activeTab === 4 && (
              <Curation
                data={data}
                id={data?.uuid}
                edition_id={filterData?.edition || editionsOptions[0]?.value}
              />
            )}

            {activeTab === 5 && (
              <Preferences
                id={data?.uuid}
                edition_id={filterData?.edition || editionsOptions[0]?.value}
              />
            )}

            {activeTab === 6 && (
              <InvoiceData
                userId={data?.uuid}
                edition_id={filterData?.edition || editionsOptions[0]?.value}
                editionData={editionsData?.data[0]}
                userData={data}
              />
            )}

            {activeTab === 7 && (
              <Visa
                id={data?.uuid}
                edition_id={filterData?.edition || editionsOptions[0]?.value}
                editionData={editionsData?.data[0]}
              />
            )}
          </div>

          <VisaJourneyTimeline
            userId={data?.uuid}
            notesOptions={notesOptions}
          />
        </div>
      </div>

      {isOpenScoreModal && (
        <CustomerModal
          show={isOpenScoreModal}
          statusList={statusList}
          onHide={() => {
            setIsOpenScoreModal(false);
            setLoading(true);
            getData();
          }}
          data={{
            uuid: id,
            score: data?.score,
            status: data?.status,
          }}
        />
      )}

      {isOpenNotesModal && (
        <NotesModal
          show={isOpenNotesModal}
          notesOptions={notesOptions}
          onHide={() => {
            setIsOpenNotesModal(false);
            setNotesLoading(true);
            getNotes();
          }}
          data={{
            uuid: id,
          }}
          selectedNotesData={selectedNotesData}
        />
      )}

      {isOpenCustomerModal && (
        <EditCustomerModal
          show={isOpenCustomerModal}
          statusList={statusList}
          onHide={() => {
            setIsOpenCustomerModal(false);
            setLoading(true);
            getData();
          }}
          data={data}
        />
      )}

      {isOpenFeedbackModal && (
        <FeedbackModal
          show={isOpenFeedbackModal}
          feedbackQlist={feedbackQlist}
          feedbackList={feedbackList}
          onHide={() => {
            setIsOpenFeedbackModal(false);
            setLoading(true);
            getData();
          }}
          data={data}
        />
      )}

      {isOpenUserRoleModal && (
        <UserRoleModal
          show={isOpenUserRoleModal}
          statusList={statusList}
          onHide={() => {
            setIsOpenUserRoleModal(false);
            setLoading(true);
            getData();
          }}
          data={data}
        />
      )}

      {isOpenStatusMarkerModal && (
        <StatusMarker
          show={isOpenStatusMarkerModal}
          onHide={() => {
            setIsOpenStatusMarkerModal(false);
            setLoading(true);
            getData();
          }}
          data={data}
        />
      )}

      {detractorModal && (
        <DetractorModal
          show={detractorModal}
          onHide={() => {
            setDetractorModal(false);
            setLoading(true);
            getData();
          }}
          data={data}
        />
      )}

      {updateCallStatusModal && (
        <UpdateCallStatus
          show={updateCallStatusModal}
          onHide={() => {
            setUpdateCallStatusModal(false);
            setLoading(true);
            getData();
          }}
          data={data}
        />
      )}

      {isOpenUserModal && (
        <StatusModal
          statusList={statusList}
          show={isOpenUserModal}
          onHide={() => {
            setIsOpenUserModal(false);
            setEditionListBookingLoading(true);
            getEditionsLitFromBookingData();
          }}
          data={{
            uuid: editionsData?.data[0]?.id, //Booking Id,
            score: data?.score,
            status: editionsData?.data[0]?.status,
          }}
        />
      )}

      {whatsappTemplateModal && (
        <WhatsappTemplateModal
          show={whatsappTemplateModal}
          onHide={() => {
            setWhatsAppTemplateModal(false);
          }}
          data={{
            user_data: data,
            edition: editionsData?.data[0]?.edition,
          }}
        />
      )}

      {isMakeCallModalOpen && (
        <MakeCallModal
          show={isMakeCallModalOpen}
          onHide={() => {
            setIsMakeCallModalOpen(false);
          }}
          data={{
            to_number: data?.phone,
          }}
        />
      )}

      {emailTemplateModal && (
        <EmailTemplateModal
          show={emailTemplateModal}
          onHide={() => {
            setEmailTemplateModal(false);
          }}
          data={{
            user_data: data,
            edition: editionsData?.data[0]?.edition,
          }}
        />
      )}

      {callNotesModal && (
        <CallNotesModal
          show={callNotesModal}
          statusList={statusList}
          onHide={() => {
            setCallNotesModal(false);
          }}
          onSuccess={() => {
            setLoading(true);
            getData();
          }}
          data={{
            user_data: data,
            edition: editionsData?.data[0]?.edition,
          }}
        />
      )}

      {emergencyContactDetailModal && (
        <EmergencyContactDetailsModal
          show={emergencyContactDetailModal}
          onHide={() => {
            setEmergencyContactDetailModal(false);
          }}
          data={data}
          onSuccess={() => {
            setLoading(true);
            getData();
          }}
        />
      )}

      <Alert
        show={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        // onSuccess={() => {
        //   getData();
        // }}
        onAction={() => {
          toast.loading("Please wait...");
          deleteBookingApplication();
        }}
      />
    </div>
  );
}
