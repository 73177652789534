import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../config/service";
import { Select, Spin } from "antd";
import DebouncedSelect from "../../../components/debounced-select";

export default function ChangeEditionsModal({
  show,
  editionsList,
  editionListLoading,
  setEditionSearchTerm,
  filterData,
  setFilterData,
  setEditionListLoading,
  setEditionActivePage,
  onHide,
  data,
}) {
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");
  const [oldEdition, setOldEdition] = useState(null);
  const [editionData, setEditionData] = useState({
    edition: null,
  });

  useEffect(() => {
    if (data) {
      // console.log(data, "user_data");
      setUserId(data?.user?.uuid);
      setOldEdition(data?.edition);
      setEditionData({
        edition: data?.edition,
      });
    }
  }, [data]);

  const onSubmitUser = async () => {
    setSending(true);

    const body = {
      user_uuid: userId,
      // new_edition_id: filterData?.edition,
      new_edition_id: editionData?.edition,
      old_edition_id: oldEdition,
    };

    // console.log(body, "body");

    fetchData(
      `api/booking/send-request-to-reschedule-booking/`,
      "post",
      body
    ).then((res) => {
      setSending(false);
      if (res.success) {
        onHide();
        toast.success("Success");
      } else {
        toast.error(res.message);
      }
    });
  };

  const setEditionDataValue = (key, value) => {
    const common_data = {
      ...editionData,
      [key]: value,
    };

    setEditionData(common_data);
  };

  return (
    <Modal show={show} onHide={onHide} size="sm" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">
            Change Edition <span className="text-primary"></span>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row g-9 align-items-center">
          <div className="col-12">
            <label>
              <span className="required fw-semibold fs-5">Select Edition</span>
            </label>

            {/* <DebouncedSelect
              apiEndpoint="api/editions/editions-select/"
              value={editionData?.edition}
              onChange={(e) => setEditionDataValue("edition", e)}
              placeholder="Select Edition"
              labelFormatter={(item) => item.ip}
              size="large"
              style={{ width: "100%", marginTop: "10px" }}
              allowClear
              dropdownStyle={{ zIndex: 1111 }}
              onClear={() => {
                // setFilterData((prevState) => ({
                //   ...prevState,
                //   edition: null,
                // }));
                setEditionDataValue("edition", null);
                setEditionActivePage(1);
              }}
            /> */}

            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              value={editionData?.edition}
              showSearch
              onSearch={(value) => {
                setEditionSearchTerm(value);
              }}
              optionFilterProp="label"
              onChange={(e) => setEditionDataValue("edition", e)}
              // onChange={(value) => {
              //   setFilterData((prevState) => ({
              //     ...prevState,
              //     edition: value,
              //   }));
              // }}
              placeholder="Select Edition"
              // style={{ width: 280 }}
              options={editionsList}
              allowClear
              onClear={() => {
                // setFilterData((prevState) => ({
                //   ...prevState,
                //   edition: null,
                // }));
                setEditionDataValue("edition", null);
                setEditionActivePage(1);
              }}
              onPopupScroll={(e) => {
                const bottom =
                  Math.abs(
                    e.target.scrollHeight -
                      (e.target.scrollTop + e.target.clientHeight)
                  ) < 1.5;

                if (bottom) {
                  setEditionListLoading(true);
                  setEditionActivePage((prevState) => prevState + 1);
                }
              }}
              notFoundContent={
                <>
                  <div className="d-flex justify-content-center align-items-center">
                    {editionListLoading ? (
                      <Spin size="small" />
                    ) : (
                      "No Data Found"
                    )}
                  </div>
                </>
              }
              size="large"
              loading={editionListLoading}
              dropdownStyle={{ zIndex: 1111 }}
              style={{ width: "100%", marginTop: "10px" }}
            ></Select>

            {/* <Select
              options={editionsList}
              value={editionData?.edition || ""}
              onChange={(e) => setEditionDataValue("edition", e)}
              placeholder="Select Edition"
              dropdownStyle={{ zIndex: 1111 }}
              style={{ width: "100%", marginTop: "10px" }}
              size="large"
              // allowClear
              // onClear={() => setEditionDataValue("status", null)}
            /> */}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => {
            onSubmitUser();
          }}
          disabled={
            sending ||
            oldEdition === editionData?.edition ||
            editionData?.edition == null
          }
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
