import React, { useEffect, useState } from "react";
import { Loader, Pagination } from "../../../components";
import { fetchData } from "../../../config/service";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import circularProgressBar from "../../../components/CirculerProgressBar";
import { Button, Popover, Select, Tooltip } from "antd";
import { formatNumber, LIMITS, routesList } from "../../../config";
import PaymentDrawer from "../bookings-page/drawer/payment";
import CancelEditionStatusModal from "./modals/cancelEditionStatus";
import WhatsappTemplateModal from "../../customers/modals/whatsapp-template";
import EmailTemplateModal from "../../../components/email-template-modal";
import { Link } from "react-router-dom";
import DebouncedSelect from "../../../components/debounced-select";

const userStatusOptions = [
  { label: "Newly Moved User", value: 1 },
  { label: "Coming for Sure", value: 2 },
  { label: "Coming / Will Pay", value: 3 },
  { label: "Coming / Needs Time", value: 4 },
  { label: "Payment Extension Requested", value: 5 },
  { label: "Paid / No Response", value: 6 },
  { label: "Not Sure", value: 7 },
  { label: "No Response", value: 8 },
  { label: "Not Coming", value: 9 },
  { label: "No Update", value: 10 },
  { label: "Move to other Edition", value: 11 },
];

export default function Payments() {
  const [paymentDataLoading, setPaymentDataLoading] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [activePage, setActivePage] = useState({
    editionCancellationData: 1,
    paymentData: 1,
    singleRoomAddons: 1,
    rescheduleEdition: 1,
  });
  // const [limit, setLimit] = useState(LIMITS[0]);
  const [limit, setLimit] = useState({
    editionCancellationData: LIMITS[0],
    paymentData: LIMITS[0],
    singleRoomAddons: LIMITS[0],
    rescheduleEdition: LIMITS[0],
  });

  const [totalRecords, setTotalRecords] = useState({
    editionCancellationData: 0,
    paymentData: 0,
    singleRoomAddons: 0,
    rescheduleEdition: 0,
  });
  const [editionsList, setEditionsList] = useState([]);

  const [filterData, setFilterData] = useState({
    resource_manager: null,
    edition: null,
  });
  const [paymentDrawer, setPaymentDrawer] = useState(false);
  const [selectedDrawerData, setSelectedDrawerData] = useState(null);

  const [singleRoomAddonsLoading, setSingleRoomAddonsLoading] = useState(false);
  const [singleRoomAddonsData, setSingleRoomAddonsData] = useState([]);

  const [editionCancellationLoading, setEditionCancellationLoading] =
    useState(false);
  const [editionCancellationData, setEditionCancellationData] = useState([]);

  const [rescheduleEditionLoading, setRescheduleEditionLoading] =
    useState(false);
  const [rescheduleEditionData, setRescheduleEditionData] = useState([]);

  const [statusModal, setStatusModal] = useState(false);
  const [statusModalData, setStatusModalData] = useState({});

  const [whatsappTemplateModal, setWhatsAppTemplateModal] = useState(false);
  const [emailTemplateModal, setEmailTemplateModal] = useState(false);
  const [selectedPaymentModalData, setSelectedPaymentModalData] = useState({});

  useEffect(() => {
    getPaymentData();
  }, [filterData, limit?.paymentData, activePage?.paymentData]);

  useEffect(() => {
    // getEditions();
  }, []);

  useEffect(() => {
    getSingleRoomAddons();
  }, [
    activePage?.singleRoomAddons,
    // filterData,
    limit?.singleRoomAddons,
  ]);

  useEffect(() => {
    getEditionCancellation();
  }, [activePage?.editionCancellationData, limit?.editionCancellationData]);

  useEffect(() => {
    getRescheduleEdition();
  }, [activePage?.rescheduleEdition, limit?.rescheduleEdition]);

  const getPaymentData = () => {
    setPaymentDataLoading(true);

    fetchData(
      `api/order/list/?edition=${filterData?.edition || ""}&curator_id=${
        filterData?.resource_manager || ""
      }&limit=${limit?.paymentData}&offset=${
        (activePage.paymentData - 1) * limit?.paymentData
      }`,
      "get"
    ).then((res) => {
      setPaymentDataLoading(false);
      if (res.success) {
        setTotalRecords((prev) => {
          return { ...prev, paymentData: res.count };
        });
        setPaymentData(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getSingleRoomAddons = () => {
    setSingleRoomAddonsLoading(true);

    fetchData(
      `api/payment/single-room-add-ons/?limit=${
        limit?.singleRoomAddons
      }&offset=${(activePage.singleRoomAddons - 1) * limit?.singleRoomAddons}`,
      "get"
    ).then((res) => {
      setSingleRoomAddonsLoading(false);
      if (res.success) {
        setTotalRecords((prev) => {
          return { ...prev, singleRoomAddons: res.count };
        });

        setSingleRoomAddonsData(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getEditionCancellation = () => {
    setEditionCancellationLoading(true);

    fetchData(
      `api/booking/booking-cancellation-request/?limit=${
        limit?.editionCancellationData
      }&offset=${
        (activePage.editionCancellationData - 1) *
        limit?.editionCancellationData
      }`,
      "get"
    ).then((res) => {
      setEditionCancellationLoading(false);
      if (res.success) {
        setTotalRecords((prev) => {
          return { ...prev, editionCancellationData: res.count };
        });

        setEditionCancellationData(res.data);
      } else {
        setEditionCancellationData([]);
        toast.error(res.message);
      }
    });
  };

  const getRescheduleEdition = () => {
    setRescheduleEditionLoading(true);

    fetchData(
      `api/booking/send-request-to-reschedule-booking/?limit=${
        limit?.rescheduleEdition
      }&offset=${
        (activePage.rescheduleEdition - 1) * limit?.rescheduleEdition
      }`,
      "get"
    ).then((res) => {
      setRescheduleEditionLoading(false);
      if (res.success) {
        setTotalRecords((prev) => {
          return { ...prev, rescheduleEdition: res.count };
        });

        setRescheduleEditionData(res.data);
      } else {
        setRescheduleEditionData([]);
        toast.error(res.message);
      }
    });
  };

  const getEditions = () => {
    fetchData(`api/editions/editions-select/`, "get").then((res) => {
      if (res.success) {
        const data = res.data.map((e) => {
          return { label: e.name, value: e.id };
        });
        setEditionsList(data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const setFilterDataValue = (key, value) => {
    const common_data = {
      ...filterData,
      [key]: value,
    };
    setFilterData(common_data);
  };

  const handleUserStatusChange = (value, data) => {
    toast.loading("Updating User Status...");
    fetchData(`api/order/order-details/${data.order_id}/`, "put", {
      user_order_status: value,
    }).then((res) => {
      toast.dismiss();
      if (res.success) {
        setPaymentDataLoading(true);
        getPaymentData();
        toast.success("User Status updated successfully");
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <div>
      <div className="row gy-5 g-xl-5">
        {/*Payment Details*/}
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8 ">
            <div className="card-header ">
              <h3 className="card-title">Payment Details</h3>
              <div className="card-toolbar gap-2">
                <div className="position-relative ">
                  <DebouncedSelect
                    apiEndpoint="api/editions/editions-select/"
                    value={filterData?.edition}
                    onChange={(value) => setFilterDataValue("edition", value)}
                    placeholder="Select Edition"
                    labelFormatter={(item) => item.ip}
                    size="large"
                    style={{ width: 200 }}
                    allowClear
                    onClear={() => {
                      setFilterDataValue("edition", null);
                    }}
                  />
                </div>

                {/* <div className="position-relative ">
                  <Select
                    value={filterData?.resource_manager}
                    showSearch
                    optionFilterProp="label"
                    onChange={(value) =>
                      setFilterDataValue("resource_manager", value)
                    }
                    placeholder="Select Resource Manager"
                    style={{ width: "200px" }}
                    // options={editionsList}
                    allowClear
                    onClear={() => {
                      setFilterDataValue("resource_manager", null);
                    }}
                    size="large"
                  />
                </div> */}
              </div>
            </div>

            <div className="card-body py-2 pb-7">
              {/*begin::Table container*/}
              {paymentDataLoading ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div>
                  {paymentData?.length > 0 ? (
                    <>
                      <div className="table-responsive">
                        <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                          <thead>
                            <tr className="fw-bolder text-dark bg-white">
                              <th className="min-w-75px text-center">#</th>
                              <th className="min-w-200px">Name</th>
                              <th className="min-w-125px">Duration</th>
                              <th className="min-w-200px"> Amount</th>
                              <th className="min-w-125px">Paid Amount</th>
                              <th className="min-w-125px">Edition</th>
                              {/* <th className="min-w-60px">Batch</th>
                     <th className="min-w-105px">Batch Name</th> */}
                              {/* <th className="min-w-60px">Resource Manager</th> */}
                              <th className="min-w-50px text-center">Rating</th>
                              <th className="min-w-100px text-center">
                                User Update
                              </th>
                              {/* <th className="min-w-100px">Pending Amount</th> */}
                              <th className="min-w-75px">Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {paymentData?.length > 0 ? (
                              paymentData.map((item, i) => {
                                return (
                                  <tr className="bg-light" key={i}>
                                    <td className="bdr-left-user text-center">
                                      {(activePage.paymentData - 1) *
                                        limit?.paymentData +
                                        1 +
                                        i}
                                    </td>
                                    <td>
                                      {item?.user_details &&
                                      Object.entries(item?.user_details)
                                        .length > 0 ? (
                                        <>
                                          <div
                                            className="d-flex align-items-center"
                                            title="Click to view Payment details"
                                            onClick={() => {
                                              setSelectedDrawerData({
                                                edition: item?.edition.id,
                                                user: item?.user_details,
                                                // ...item,
                                              });
                                              setPaymentDrawer(!paymentDrawer);
                                            }}
                                          >
                                            <div className="symbol symbol-circle symbol-45px overflow-hidden me-3">
                                              {item?.user_details?.photo ? (
                                                <img
                                                  src={
                                                    item?.user_details?.photo
                                                  }
                                                  alt={`${item?.user_details?.first_name}`}
                                                  className="rounded-circle"
                                                />
                                              ) : (
                                                <div className="symbol symbol-circle symbol-45px mr-3">
                                                  <span
                                                    className="symbol-label fs-2 text-primary bg-light-primary"
                                                    style={{
                                                      border:
                                                        "1px dashed #7843e6",
                                                      borderRadius: "50%",
                                                    }}
                                                  >
                                                    {item?.user_details?.first_name
                                                      ?.charAt(0)
                                                      .toUpperCase()}
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                            <div className="d-flex flex-column">
                                              <Link
                                                to={`${routesList[
                                                  "customer_details"
                                                ].path.replace(
                                                  ":id",
                                                  item.user_details.uuid
                                                )}`}
                                                className="text-primary mb-1 fw-bolder"
                                              >
                                                {`${
                                                  item?.user_details
                                                    ?.first_name || "-"
                                                } ${
                                                  item?.user_details?.last_name
                                                }`}
                                              </Link>
                                              <span className="text-gray-600 fw-semibold">
                                                {dayjs(item?.created_at).format(
                                                  "DD-MM-YYYY"
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>
                                      {dayjs(item?.user_details?.end_date).diff(
                                        dayjs(item?.user_details?.start_date),
                                        "month"
                                      )}
                                    </td>
                                    <td>
                                      <div className="d-flex flex-column">
                                        <span className="fw-semibold fs-8 text-gray-700">
                                          Total Amount:{" "}
                                          <b>
                                            {formatNumber(item.total_amount) ||
                                              "-"}
                                          </b>
                                        </span>
                                        <span className="fw-semibold fs-8 text-gray-700">
                                          Pending Amount:{" "}
                                          <b>
                                            {formatNumber(
                                              item.remaining_amount
                                            ) || "-"}
                                          </b>
                                        </span>
                                      </div>
                                    </td>

                                    <td className="fw-semibold fs-6">
                                      {formatNumber(item.amount_pay_by_user) ||
                                        "-"}
                                    </td>

                                    <td>{item?.edition?.ip || "-"}</td>
                                    {/* <td>
                                      {item?.user_details &&
                                      Object.entries(item?.user_details)
                                        .length > 0
                                        ? item?.user_details?.curator
                                            ?.curator_first_name
                                        : "-"}
                                    </td> */}
                                    <td>
                                      {item?.user_details &&
                                      Object.entries(item?.user_details)
                                        .length > 0
                                        ? item?.user_details?.score !== null &&
                                          item?.user_details?.score !==
                                            undefined
                                          ? circularProgressBar(
                                              item?.user_details?.score,
                                              35,
                                              3
                                            )
                                          : "-"
                                        : "-"}
                                    </td>
                                    <td>
                                      <Select
                                        size="large"
                                        value={item?.user_order_status || null}
                                        options={userStatusOptions}
                                        placeholder="Select User Status"
                                        onChange={(e) => {
                                          handleUserStatusChange(e, item);
                                        }}
                                        style={{ width: "100%" }}
                                        dropdownStyle={{ width: "250px" }}
                                        className="fs-7"
                                      ></Select>
                                    </td>
                                    {/* <td className="fw-semibold fs-6">
                                      {formatNumber(
                                        String(item?.remaining_amount)
                                      ) || "-"}
                                    </td> */}
                                    <td>
                                      <div className="d-flex flex-shrink-0 justify-content-end">
                                        <div>
                                          {item?.user_details.phone &&
                                          item?.edition?.id ? (
                                            <Popover
                                              content={
                                                <>
                                                  <div>
                                                    <Button
                                                      type="link"
                                                      className="text-dark fw-bold"
                                                      onClick={() => {
                                                        setWhatsAppTemplateModal(
                                                          true
                                                        );
                                                        setSelectedPaymentModalData(
                                                          item
                                                        );
                                                      }}
                                                    >
                                                      WhatsApp Template
                                                    </Button>
                                                    <br />
                                                    <Button
                                                      type="link"
                                                      className="text-dark fw-bold"
                                                      href={`https://wa.me/${item?.user_details?.phone}`}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      Open in WhatsApp
                                                    </Button>
                                                  </div>
                                                </>
                                              }
                                              trigger="hover"
                                            >
                                              <div className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3">
                                                <i
                                                  className="bi bi-whatsapp fs-4 text-success"
                                                  style={{ cursor: "pointer" }}
                                                />
                                              </div>
                                            </Popover>
                                          ) : (
                                            <div
                                              className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                              disabled
                                              style={{
                                                cursor: "not-allowed",
                                                opacity: "0.5",
                                              }}
                                            >
                                              <i className="bi bi-whatsapp fs-2 text-success" />
                                            </div>
                                          )}
                                        </div>

                                        {item?.user_details.email &&
                                        item?.edition?.id ? (
                                          <div
                                            className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                            onClick={() => {
                                              setEmailTemplateModal(true);
                                              setSelectedPaymentModalData(item);
                                            }}
                                          >
                                            <Tooltip title="Send Email">
                                              <i
                                                className="bi bi-envelope fs-2 text-danger"
                                                style={{ cursor: "pointer" }}
                                              />
                                            </Tooltip>
                                          </div>
                                        ) : (
                                          <Tooltip title="Please select edition to send mail">
                                            <div
                                              className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                              disabled
                                              style={{
                                                cursor: "not-allowed",
                                                opacity: "0.5",
                                              }}
                                            >
                                              <i className="bi bi-envelope fs-2 text-danger" />
                                            </div>
                                          </Tooltip>
                                        )}

                                        <div>
                                          <div
                                            className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                            onClick={() => {
                                              setSelectedDrawerData({
                                                edition: item?.edition.id,
                                                user: item?.user_details,
                                                // ...item,
                                              });
                                              setPaymentDrawer(!paymentDrawer);
                                            }}
                                          >
                                            <Tooltip title="View Payment Details">
                                              <i
                                                className="bi bi-wallet2 fs-4 text-primary"
                                                style={{ cursor: "pointer" }}
                                              />
                                            </Tooltip>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <div className="row g-5 mb-0 gx-md-10">
                                <div className="col-md-12 text-center">
                                  <div className="mt-0">
                                    <img
                                      src="/media/no-data-found.png"
                                      className="mw-25"
                                      alt="No Data Found"
                                    />
                                  </div>
                                  <h1 className="mt-4">No Data Found</h1>
                                </div>
                              </div>
                            )}
                          </tbody>
                        </table>
                      </div>

                      <div className="separator separator-dashed border-gray-200" />
                      <Pagination
                        setLimit={(e) => {
                          setLimit((prev) => ({
                            ...prev,
                            paymentData: e,
                          }));
                        }}
                        limit={limit?.paymentData}
                        data={paymentData}
                        activePage={activePage?.paymentData}
                        totalRecords={totalRecords?.paymentData}
                        onChange={(pageNumber) => {
                          setPaymentDataLoading(true);
                          setActivePage((prev) => ({
                            ...prev,
                            paymentData: pageNumber,
                          }));
                        }}
                      />
                    </>
                  ) : (
                    <div className="row g-5 mb-0 gx-md-10">
                      <div className="col-md-12 text-center">
                        <div className="mt-0">
                          <img
                            src="/media/no-data-found.png"
                            className="mw-25"
                            alt="No Data Found"
                          />
                        </div>
                        <h1 className="mt-4">No Data Found</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/*end::Pagination*/}
            </div>
          </div>
        </div>

        {/*Single Room Add-ons */}
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8 ">
            <div className="card-header ">
              <h3 className="card-title">Single Room Add-ons</h3>

              <div className="card-toolbar gap-2"></div>
            </div>

            <div className="card-body py-2 pb-7">
              {/*begin::Table container*/}
              {singleRoomAddonsLoading ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div>
                  {singleRoomAddonsData?.length > 0 ? (
                    <>
                      <div className="table-responsive">
                        <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                          <thead>
                            <tr className="fw-bolder text-dark bg-white">
                              <th className="min-w-75px text-center">#</th>
                              <th className="min-w-100px">Name</th>
                              <th className="min-w-100px">Date</th>
                              <th className="min-w-100px"> Amount</th>
                              <th className="min-w-100px">Edition</th>
                            </tr>
                          </thead>

                          <tbody>
                            {singleRoomAddonsData?.length > 0 ? (
                              singleRoomAddonsData.map((item, i) => {
                                return (
                                  <tr className="bg-light" key={i}>
                                    <td className="bdr-left-user text-center">
                                      {(activePage.singleRoomAddons - 1) *
                                        limit?.singleRoomAddons +
                                        1 +
                                        i}
                                    </td>
                                    <td>
                                      {item?.customer_details &&
                                      Object.entries(item?.customer_details)
                                        .length > 0 ? (
                                        <>
                                          <div className="d-flex align-items-center">
                                            <div className="symbol symbol-circle symbol-45px overflow-hidden me-3">
                                              {item?.customer_details?.photo ? (
                                                <img
                                                  src={
                                                    item?.customer_details
                                                      ?.photo
                                                  }
                                                  alt={`${item?.customer_details?.first_name}`}
                                                  className="rounded-circle"
                                                />
                                              ) : (
                                                <div className="symbol symbol-circle symbol-45px mr-3">
                                                  <span
                                                    className="symbol-label fs-2 text-primary bg-light-primary"
                                                    style={{
                                                      border:
                                                        "1px dashed #7843e6",
                                                      borderRadius: "50%",
                                                    }}
                                                  >
                                                    {item?.customer_details?.first_name
                                                      ?.charAt(0)
                                                      .toUpperCase()}
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                            <div className="d-flex flex-column">
                                              <a
                                                href="#"
                                                className="text-primary mb-1 fw-bolder gotham_black"
                                              >
                                                {`${
                                                  item?.customer_details
                                                    ?.first_name || "-"
                                                } ${
                                                  item?.customer_details
                                                    ?.last_name
                                                }`}
                                              </a>
                                              <span className="text-gray-600 fw-semibold">
                                                {item?.email || "-"}
                                              </span>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>
                                      {item?.updated_at
                                        ? dayjs(item?.updated_at).format(
                                            "DD-MM-YYYY"
                                          )
                                        : "-"}
                                    </td>
                                    <td className="fw-semibold fs-6">
                                      {formatNumber(
                                        item?.edition_details?.single_room_cost
                                      ) || 0}
                                    </td>

                                    <td>
                                      {item?.edition_details?.name || "-"}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <div className="row g-5 mb-0 gx-md-10">
                                <div className="col-md-12 text-center">
                                  <div className="mt-0">
                                    <img
                                      src="/media/no-data-found.png"
                                      className="mw-25"
                                      alt="No Data Found"
                                    />
                                  </div>
                                  <h1 className="mt-4">No Data Found</h1>
                                </div>
                              </div>
                            )}
                          </tbody>
                        </table>
                      </div>

                      <div className="separator separator-dashed border-gray-200" />
                      <Pagination
                        setLimit={(e) => {
                          setLimit((prev) => ({
                            ...prev,
                            singleRoomAddons: e,
                          }));
                        }}
                        limit={limit?.singleRoomAddons}
                        data={singleRoomAddonsData}
                        activePage={activePage?.singleRoomAddons}
                        totalRecords={totalRecords?.singleRoomAddons}
                        onChange={(pageNumber) => {
                          setSingleRoomAddonsLoading(true);
                          setActivePage((prev) => ({
                            ...prev,
                            paymentData: pageNumber,
                          }));
                        }}
                      />
                    </>
                  ) : (
                    <div className="row g-5 mb-0 gx-md-10">
                      <div className="col-md-12 text-center">
                        <div className="mt-0">
                          <img
                            src="/media/no-data-found.png"
                            className="mw-25"
                            alt="No Data Found"
                          />
                        </div>
                        <h1 className="mt-4">No Data Found</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/*end::Pagination*/}
            </div>
          </div>
        </div>

        {/* Booking Cancellation */}
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8 ">
            <div className="card-header ">
              <h3 className="card-title">Booking Cancellation</h3>

              <div className="card-toolbar gap-2"></div>
            </div>

            <div className="card-body py-2 pb-7">
              {/*begin::Table container*/}
              {editionCancellationLoading ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div>
                  {editionCancellationData?.length > 0 ? (
                    <>
                      <div className="table-responsive">
                        <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                          <thead>
                            <tr className="fw-bolder text-dark bg-white">
                              <th className="min-w-75px text-center">#</th>
                              <th className="min-w-100px">User Detail</th>
                              <th className="min-w-100px">Edition Name</th>
                              <th className="min-w-100px"> Status</th>
                              <th className="min-w-100px">Resource Manager</th>
                              <th className="min-w-100px">Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {editionCancellationData?.length > 0 ? (
                              editionCancellationData.map((item, i) => {
                                return (
                                  <tr className="bg-light" key={i}>
                                    <td className="bdr-left-user text-center">
                                      {(activePage.editionCancellationData -
                                        1) *
                                        limit?.editionCancellationData +
                                        1 +
                                        i}
                                    </td>
                                    <td>
                                      {item?.user &&
                                      Object.entries(item?.user).length > 0 ? (
                                        <>
                                          <div className="d-flex align-items-center">
                                            <div className="symbol symbol-circle symbol-45px overflow-hidden me-3">
                                              {item?.user?.photo ? (
                                                <img
                                                  src={item?.user?.photo}
                                                  alt={`${item?.user?.first_name}`}
                                                  className="rounded-circle"
                                                />
                                              ) : (
                                                <div className="symbol symbol-circle symbol-45px mr-3">
                                                  <span
                                                    className="symbol-label fs-2 text-primary bg-light-primary"
                                                    style={{
                                                      border:
                                                        "1px dashed #7843e6",
                                                      borderRadius: "50%",
                                                    }}
                                                  >
                                                    {item?.user?.first_name
                                                      ?.charAt(0)
                                                      .toUpperCase() || "-"}
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                            <div className="d-flex flex-column">
                                              <a
                                                href="#"
                                                className="text-primary mb-1 fw-bolder gotham_black"
                                              >
                                                {`${item?.user?.first_name} ${item?.user?.last_name}`}
                                              </a>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>{item?.edition_name || "-"}</td>
                                    <td className="fw-semibold fs-6">
                                      <span
                                        className={`badge py-3 px-4 fs-7 badge-light-${
                                          item?.cancellation_booking_status == 3
                                            ? "success"
                                            : item?.cancellation_booking_status ==
                                              2
                                            ? "warning"
                                            : item?.cancellation_booking_status ==
                                              1
                                            ? "warning"
                                            : "danger"
                                        }`}
                                      >
                                        {item.cancellation_booking_status_label}
                                      </span>
                                    </td>

                                    <td>{item?.cancelled_by_name || "-"}</td>
                                    <td>
                                      <div className="d-flex flex-shrink-0">
                                        <div
                                          className={`btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3 ${
                                            [3, 4].includes(
                                              item?.cancellation_booking_status
                                            )
                                              ? "disabled"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            if (
                                              ![3, 4].includes(
                                                item?.cancellation_booking_status
                                              )
                                            ) {
                                              setStatusModal(!statusModal);
                                              setStatusModalData(item);
                                            }
                                          }}
                                          style={{
                                            pointerEvents: [3, 4].includes(
                                              item?.cancellation_booking_status
                                            )
                                              ? "none"
                                              : "auto",
                                          }}
                                        >
                                          <i className="ki-outline ki-pencil fs-2 text-dark" />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <div className="row g-5 mb-0 gx-md-10">
                                <div className="col-md-12 text-center">
                                  <div className="mt-0">
                                    <img
                                      src="/media/no-data-found.png"
                                      className="mw-25"
                                      alt="No Data Found"
                                    />
                                  </div>
                                  <h1 className="mt-4">No Data Found</h1>
                                </div>
                              </div>
                            )}
                          </tbody>
                        </table>
                      </div>

                      <div className="separator separator-dashed border-gray-200" />
                      <Pagination
                        setLimit={(e) => {
                          setLimit((prev) => ({
                            ...prev,
                            editionCancellationData: e,
                          }));
                        }}
                        limit={limit?.editionCancellationData}
                        data={editionCancellationData}
                        activePage={activePage?.editionCancellationData}
                        totalRecords={totalRecords?.editionCancellationData}
                        onChange={(pageNumber) => {
                          setEditionCancellationLoading(true);
                          setActivePage((prev) => ({
                            ...prev,
                            editionCancellationData: pageNumber,
                          }));
                        }}
                      />
                    </>
                  ) : (
                    <div className="row g-5 mb-0 gx-md-10">
                      <div className="col-md-12 text-center">
                        <div className="mt-0">
                          <img
                            src="/media/no-data-found.png"
                            className="mw-25"
                            alt="No Data Found"
                          />
                        </div>
                        <h1 className="mt-4">No Data Found</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/*end::Pagination*/}
            </div>
          </div>
        </div>

        {/* Reschedule Edition*/}
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8 ">
            <div className="card-header ">
              <h3 className="card-title">Reschedule Edition</h3>

              <div className="card-toolbar gap-2"></div>
            </div>

            <div className="card-body py-2 pb-7">
              {/*begin::Table container*/}
              {rescheduleEditionLoading ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div>
                  {rescheduleEditionData?.length > 0 ? (
                    <>
                      <div className="table-responsive">
                        <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                          <thead>
                            <tr className="fw-bolder text-dark bg-white">
                              <th className="min-w-75px text-center">#</th>
                              <th className="min-w-100px">User Detail</th>
                              <th className="min-w-100px">Old Edition Name</th>
                              <th className="min-w-100px"> New Edition Name</th>
                              <th className="min-w-100px">Paid On</th>
                              <th className="min-w-100px">Resource Manager</th>
                            </tr>
                          </thead>

                          <tbody>
                            {rescheduleEditionData?.length > 0 ? (
                              rescheduleEditionData.map((item, i) => {
                                return (
                                  <tr className="bg-light" key={i}>
                                    <td className="bdr-left-user text-center">
                                      {(activePage.rescheduleEdition - 1) *
                                        limit?.rescheduleEdition +
                                        1 +
                                        i}
                                    </td>
                                    <td>
                                      {item?.user &&
                                      Object.entries(item?.user).length > 0 ? (
                                        <>
                                          <div className="d-flex align-items-center">
                                            <div className="symbol symbol-circle symbol-45px overflow-hidden me-3">
                                              {item?.user?.photo ? (
                                                <img
                                                  src={item?.user?.photo}
                                                  alt={`${item?.user?.first_name}`}
                                                  className="rounded-circle"
                                                />
                                              ) : (
                                                <div className="symbol symbol-circle symbol-45px mr-3">
                                                  <span
                                                    className="symbol-label fs-2 text-primary bg-light-primary"
                                                    style={{
                                                      border:
                                                        "1px dashed #7843e6",
                                                      borderRadius: "50%",
                                                    }}
                                                  >
                                                    {item?.user?.first_name
                                                      ?.charAt(0)
                                                      .toUpperCase() || "-"}
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                            <div className="d-flex flex-column">
                                              <a
                                                href="#"
                                                className="text-primary mb-1 fw-bolder gotham_black"
                                              >
                                                {`${item?.user?.first_name} ${item?.user?.last_name}`}
                                              </a>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>
                                      {item?.old_edition?.["name"] || "-"}
                                    </td>

                                    <td>
                                      {item?.new_edition?.["name"] || "-"}
                                    </td>
                                    <td>
                                      {dayjs(item?.paid_on).format(
                                        "DD-MM-YYYY"
                                      ) || "-"}
                                    </td>
                                    <td>{item?.moved_by || "-"}</td>
                                  </tr>
                                );
                              })
                            ) : (
                              <div className="row g-5 mb-0 gx-md-10">
                                <div className="col-md-12 text-center">
                                  <div className="mt-0">
                                    <img
                                      src="/media/no-data-found.png"
                                      className="mw-25"
                                      alt="No Data Found"
                                    />
                                  </div>
                                  <h1 className="mt-4">No Data Found</h1>
                                </div>
                              </div>
                            )}
                          </tbody>
                        </table>
                      </div>

                      <div className="separator separator-dashed border-gray-200" />
                      <Pagination
                        setLimit={(e) => {
                          setLimit((prev) => ({
                            ...prev,
                            rescheduleEdition: e,
                          }));
                        }}
                        limit={limit?.rescheduleEdition}
                        data={rescheduleEditionData}
                        activePage={activePage?.rescheduleEdition}
                        totalRecords={totalRecords?.rescheduleEdition}
                        onChange={(pageNumber) => {
                          setRescheduleEditionLoading(true);
                          setActivePage((prev) => ({
                            ...prev,
                            rescheduleEdition: pageNumber,
                          }));
                        }}
                      />
                    </>
                  ) : (
                    <div className="row g-5 mb-0 gx-md-10">
                      <div className="col-md-12 text-center">
                        <div className="mt-0">
                          <img
                            src="/media/no-data-found.png"
                            className="mw-25"
                            alt="No Data Found"
                          />
                        </div>
                        <h1 className="mt-4">No Data Found</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/*end::Pagination*/}
            </div>
          </div>
        </div>

        {/* table 2 */}
        {/* <div className="col-xxl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8 ">
            <div className="card-header ">
              <h3 className="card-title">Refund Requests</h3>
              <div className="card-toolbar gap-2"></div>
            </div>

            <div className="card-body py-2">
              {false ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div className="main-box clearfix">
                  <div className="table-responsive">
                    <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                      <thead>
                        <tr className="fw-bolder text-dark bg-white">
                          <th className="min-w-75px text-center">#</th>
                          <th className="min-w-180px">Name</th>
                          <th className="min-w-120px">Reason</th>
                          <th className="min-w-125px">Amount</th>
                          <th className="min-w-125px">Email/Phone</th>
                          <th className="min-w-100px">Date</th>
                        </tr>
                      </thead>

                      <tbody>
                        {refundTableData?.length > 0
                          ? refundTableData?.map((item, i) => {
                              return (
                                <tr className="bg-light" key={i}>
                                  <td className="bdr-left-user text-center">
                                    {i + 1}
                                  </td>
                                  <td>{item.name}</td>
                                  <td>{item.reason}</td>
                                  <td className="fw-semibold fs-6">
                                    {item.amount}
                                  </td>
                                  <td>{item.emailPhone}</td>
                                  <td>{item.date}</td>
                                </tr>
                              );
                            })
                          : ""}
                      </tbody>
                    </table>
                  </div>

                  <div className="separator separator-dashed border-gray-200" />
                  <Pagination
                    setLimit={10}
                    data={refundTableData}
                    activePage={1}
                    totalRecords={22}
                    // onChange={(pageNumber) => {
                    //   setLoading(true);
                    //   setActivePage(pageNumber);
                    // }}
                  />
                </div>
              )}
            </div>
          </div>
        </div> */}

        {/* table 3 */}
        {/* <div className="col-xxl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8 ">
            <div className="card-header ">
              <h3 className="card-title">Sales Insights </h3>
              <div className="card-toolbar gap-2">
                <div></div>
              </div>
            </div>

            <div className="card-body py-2">
              {false ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div className="main-box clearfix">
                  <div className="table-responsive">
                    <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                      <thead>
                        <tr className="fw-bolder text-dark bg-white">
                          <th className="min-w-75px text-center">#</th>
                          <th className="min-w-180px">Name</th>
                          <th className="min-w-120px">App + Confirm</th>
                          <th className="min-w-125px">App + Contribution</th>
                          <th className="min-w-125px">Inside + Confirm</th>
                          <th className="min-w-100px">Emails Sent</th>
                          <th className="min-w-175px">Call Done + Booked</th>
                          <th className="min-w-90px">Edition</th>
                          <th className="min-w-90px">Payment Date</th>
                        </tr>
                      </thead>

                      <tbody>
                        {salesInsightData?.length > 0
                          ? salesInsightData?.map((item, i) => {
                              return (
                                <tr className="bg-light" key={i}>
                                  <td className="bdr-left-user text-center">
                                    {i + 1}
                                  </td>
                                  <td>{item.name}</td>
                                  <td>{item.appConfirm}</td>
                                  <td>{item.appContribution}</td>
                                  <td>{item.insideConfirm}</td>
                                  <td>{item.emailsSent}</td>
                                  <td>{item.callDonePlusBooked}</td>
                                  <td>{item.edition}</td>
                                  <td>{item.paymentDate}</td>
                                </tr>
                              );
                            })
                          : ""}
                      </tbody>
                    </table>
                  </div>

                  <div className="separator separator-dashed border-gray-200" />
                  <Pagination
                    setLimit={10}
                    data={salesInsightData}
                    activePage={1}
                    totalRecords={22}
                    // onChange={(pageNumber) => {
                    //   setLoading(true);
                    //   setActivePage(pageNumber);
                    // }}
                  />
                </div>
              )}
            </div>
          </div>
        </div> */}
      </div>

      {paymentDrawer && (
        <PaymentDrawer
          show={paymentDrawer}
          onHide={() => {
            setPaymentDrawer(false);
            // setLoading(true);
            // getPaymentData();
          }}
          data={selectedDrawerData}
        />
      )}

      {statusModal && (
        <CancelEditionStatusModal
          show={statusModal}
          onHide={() => {
            setStatusModal(false);
            setEditionCancellationLoading(true);
            getEditionCancellation();
          }}
          data={statusModalData}
        />
      )}

      {whatsappTemplateModal && (
        <WhatsappTemplateModal
          show={whatsappTemplateModal}
          onHide={() => {
            setWhatsAppTemplateModal(false);
          }}
          data={{
            user_data: selectedPaymentModalData?.user_details,
            edition: selectedPaymentModalData?.edition?.id,
          }}
        />
      )}

      {emailTemplateModal && (
        <EmailTemplateModal
          show={emailTemplateModal}
          onHide={() => {
            setEmailTemplateModal(false);
          }}
          data={{
            user_data: selectedPaymentModalData?.user_details,
            edition: selectedPaymentModalData?.edition?.id,
          }}
        />
      )}
    </div>
  );
}
